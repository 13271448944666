<template>
  <div>
    <div class="mb-4 text-muted">
      {{ $t("customerRenewalOfferText") }}
    </div>
    <b-form-group>
      <b-form-radio v-model="selectedOption" value="endcustomerSalesPrices">{{$t("rrp")}}</b-form-radio>
      <b-form-radio v-model="selectedOption" value="prices">{{ $t("purchasePrices") }}</b-form-radio>
    </b-form-group>
    <b-button v-on:click="generateRenewalOffer()" variant="primary">{{ $t("downloadRenewalOffer") }}</b-button>
    <div class="imageContainer d-none">
      <ImageBlob v-if="imagePath != ''" :data="{image: imagePath}" id="doc-logo" class="d-none"/>
      <img v-else src="/logo_pdf.png" alt="Logo" id="doc-logo" class="d-none" />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import settings from "../settings"
import ImageBlob from "../components/ImageBlob.vue"
import jsPDF from "jspdf";
import "../../node_modules/jspdf-autotable";
import { firestore } from "../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";
import { collection, orderBy, query, getDocs } from "firebase/firestore";
import { mapState } from "vuex";

export default {
  name: "CustomerRenewalOfferView",
  components: { 
    ImageBlob,
  },
  props: {
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    autoRenewalSubscriptions: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedOption: "endcustomerSalesPrices",
      imagePath: "",
      specialPriceSwitch: Array(),
    }
  },
  computed: {
    ...mapState(["settings"]),
  },
  methods: {    
    async getGeneralPriceSwitch(){ // Datum für Preiserhöhung (...-NEW)
      const colRef = doc(firestore, "generalSettings", "newPriceStartDate");
      const snap = await getDoc(colRef);
      // console.log(snap.data().date.toDate())  
      this.generalPriceSwitch = snap.data().date.toDate() || false;
      return
    },
    async getSpecialPriceSwitch(){
      const colRef = query(collection(firestore, "specialPriceSwitch"));
      const querySnapshot = await getDocs(colRef);
      const data = []
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          data.push(doc.data())
        });
      }
      this.specialPriceSwitch = data;
      return
    },
    async getResellerDataFromCollection() {
      const docRef = doc(firestore, "resellers", this.$store.state.resellerData.customernumber);
      const docSnapshot = await getDoc(docRef);
      this.imagePath = docSnapshot.data()?.logo?.path || false;
    },
    getTaxRate(country) {
      if (this.$store.state.taxRates[country] !== undefined) {
        return this.$store.state.taxRates[country];
      } else {
        return 0.19;
      }
    },
    determineVolumeLevel(volumeLVL) {
      const lvl = volumeLVL[0]
      if (isNaN(Number(volumeLVL[1]))){ return volumeLVL }
      if (lvl == "1"){ return volumeLVL }
      if(lvl == "0"){
        //Lizenzen
        if (this.autoRenewalSubscriptions <= 9) {
          return `${lvl}1`;
        } else if (this.autoRenewalSubscriptions <= 49) {
          return `${lvl}2`;
        } else if (this.autoRenewalSubscriptions <= 99) {
          return `${lvl}3`;
        } else {
          return `${lvl}4`;
        }
      } else {
          if (this.autoRenewalSubscriptions <= 999) {
            return `${lvl}1`;
          } else if (this.autoRenewalSubscriptions <= 2499) {
            return `${lvl}2`;
          } else if (this.autoRenewalSubscriptions <= 4999) {
            return `${lvl}3`;
          } else if (this.autoRenewalSubscriptions <= 14999) {
            return `${lvl}4`;
          } else if (this.autoRenewalSubscriptions <= 49999) {
            return `${lvl}5`;
          } else if (this.autoRenewalSubscriptions <= 99999) {
            return `${lvl}6`;
          } else {
            return `${lvl}7`;
        }
      }
    },
    extractVolumeLevelFromOfferId(offerId) {
      // console.log(offerId)
      return offerId.split("CA")[1].split("A")[0];

      //CA = Commercial
      //CB = EDU
      //CC = Goverment
    },
    useSpecialPrice(item, threeYCStartDate, threeYCEndDate, cotermDate, threeYCStatus) {
      const itemIncludesSpecialPrices = this.includesSpecialPrices(item) // Preise in diesem "Artikel" mit Postfixen
      const relevantPostfixes = this.searchRelevantPostfixes(threeYCStartDate, threeYCEndDate, cotermDate, threeYCStatus) // Postfixe die für diesen Kunden relevant sind. 
      const matichingPostfixes = relevantPostfixes.filter(each => itemIncludesSpecialPrices.includes(each?.postfix)) // Postfixe die für diesen Kunden relevant sind und in dem Artikel vorkommen
      let newItem = []

      if(matichingPostfixes.length > 0){
        newItem = item.filter(each=> matichingPostfixes.some( item => item.postfix == each.itemNumberSections.specialPriceFunction || item == each.itemNumberSections.specialPriceFunction)) // show Special Price
      } else {
       newItem = item.filter(each => !each.itemNumberSections.specialPriceFunction) // show Special Price
      }

      console.log(newItem)
      return newItem
    },
    searchRelevantPostfixes(threeYCStartDate, threeYCEndDate, cotermDate, threeYCStatus) {

      // specialPriceSwitch enhält Postfix und Datum für 3YC Kunden die ihre Preise zum start fixiert haben. 
      // generalPriceSwitch enhält das Datum für den Spezifischen Postfix "NEW"

      let relevantPostfixes = []

      if( moment(this.generalPriceSwitch).isAfter(moment(threeYCEndDate)) // Preisänderung ist nach 3CY ende &&
        && moment(cotermDate).isAfter(moment(this.generalPriceSwitch))// 3YC läuft vor der Preiserhöhing aus aber das Cotermdate ist nach der Preiserhöhung. (Nur bei Tranferierten Kunden möglich)
        || threeYCStatus != "COMMITTED" && moment(cotermDate).isAfter(moment(this.generalPriceSwitch)) // 3YC ist NICHT Aktiv und das cotermdate ist nach der Preiserhöhung.
        || moment(cotermDate).isAfter(moment(threeYCEndDate)) && moment(cotermDate).isAfter(moment(this.generalPriceSwitch))) { // Renewal ist nach der Preiserhöhung und nach dem Ende von 3YC
          // nimm den "NEW" Preis
          if(moment(new Date()).isSameOrBefore(this.generalPriceSwitch)){
            relevantPostfixes.push({postfix: settings.newPricePostfix})
          }
        return relevantPostfixes
      }

      console.log(this.specialPriceSwitch);

      // immer den POSTFIX zurückgeben der am ältesten ist und vor dem 3YC Startdatum ist.
      this.specialPriceSwitch.forEach((each) => {
        if(moment(each.date.toDate()).isAfter(moment(threeYCStartDate))) { 
          relevantPostfixes.push(each)
        }
      })

      // nach datum sortieren
      relevantPostfixes.sort(function(a,b){
        return a.date.toDate() - b.date.toDate();
      });

      /* // nur den ersten aus dem Array zurückgeben
      const relevantPostfixesToReturn = relevantPostfixes.length > 0 && relevantPostfixes[0] ? [relevantPostfixes[0]] : []

      console.log(relevantPostfixesToReturn); */

      return relevantPostfixes
    },
    includesSpecialPrices(item){
      // console.log('item', item)

      let includesSpecialPrices = [];

      item.forEach(price =>{

        price.itemNumberSections = this.clArtNoObject(price.itemNumber)

        if (price.itemNumberSections?.specialPriceFunction != false) {
          includesSpecialPrices.push(price.itemNumberSections?.specialPriceFunction);
          includesSpecialPrices = [...new Set(includesSpecialPrices)];
        }
      })
      return includesSpecialPrices
    },
    async generateRenewalOffer() {
      if (this.autoRenewalSubscriptions === null || this.autoRenewalSubscriptions === 0) {
        this.$store.dispatch("alertError", {
          message: "Sie haben keine Auto-Renewal Subscriptions.",
          show: true,
          noFooter: false,
        });
        return;
      }

      const productsColRef = query(collection(firestore, "products"), orderBy("productName"));
      const productsPromise = getDocs(productsColRef);

      const pricesColRef = collection(firestore, "resellers", this.$store.state.resellerData.firestoreId, "prices");
      const pricesPromise = getDocs(pricesColRef);

      const results = await Promise.all([productsPromise, pricesPromise]).catch((error) => {
        console.error(error);
      });

      if (results[0].empty || results[1].empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });
        return;
      }

      const prices = [];
      results[1].forEach((price) => {
        prices.push(price.data());
      });

      const products = [];
      results[0].forEach((product) => {
        const data = product.data();
        const matchedPrices = prices.filter((price) => price.itemNumber === data.itemNumber);
        
        if (matchedPrices.length > 0) {
          data.price = matchedPrices[0].price;
        }

        products.push(data);
      });

      const subscriptions = [];
      let sum = 0;

      // const volumeLevel = this.determineVolumeLevel();

      let volumeLevel = "";
      let consumableLevel = "";
      const threeYCStartDate = this.customer?.benefits[0]?.commitment?.startDate || 'false';
      // const threeYCEndDate = this.customer?.benefits[0]?.commitment?.endDate || 'false';
     const threeYCEndDate = this.customer?.benefits[0]?.commitment?.endDate || 'false';
      const threeYCStatus = this.customer?.benefits[0]?.commitment?.status || 'INACTIVE';
      const cotermDate = this.customer?.cotermDate || 'false';

      if (this.customer.discounts !== undefined) {
        this.customer.discounts.forEach((discount) => {
          if (discount.offerType === "LICENSE") {
            volumeLevel = this.determineVolumeLevel(discount.level);
          }

          if (discount.offerType === "CONSUMABLES") {
            consumableLevel = this.determineVolumeLevel(discount.level);
          }
        });
      }

      this.subscriptions.forEach((subscription) => {
        if (subscription.autoRenewal.enabled && subscription.autoRenewal.renewalQuantity > 0 && subscription.offerId !== "" && !subscription.offerId.match(/^\d{8}(CAT)\d{1}\w*$/)) {

          let matchedProduct = products.filter((product) => {
            if (subscription.offerId.match(/^\d{8}\w{2}\d{2}$/)) {
              subscription.offerId += "A12";
            }
            
            const itemNumberSections = this.clArtNoObject(product.itemNumber);
            product.itemNumberSections = itemNumberSections
            
            const extractetVolumeLevel = this.extractVolumeLevelFromOfferId(subscription.offerId);

            if (extractetVolumeLevel !== volumeLevel) {
              subscription.offerId = subscription.offerId.replace("CA" + extractetVolumeLevel, "CA" + volumeLevel);
            }
    
            if (product.manufacturerItemNumber === subscription.offerId && itemNumberSections.product !== "1300") { // Darf kein New Order Promo Artikel sein
              return true;
            } else {
              return false;
            }
          });
            
          if (matchedProduct.length > 1) { 
            matchedProduct = this.useSpecialPrice(matchedProduct, threeYCStartDate, threeYCEndDate, cotermDate, threeYCStatus)
          }


          if (matchedProduct.length === 1) {
            let price = matchedProduct[0].endcustomerSalesPrice;

            if (this.selectedOption === "prices") {
              price = matchedProduct[0].price;
            }

            const lineSum = price * subscription.autoRenewal.renewalQuantity;

            subscriptions.push({
              price: this.$options.filters.currency(price),
              productName: this.$options.filters.productNameWithoutLevel(matchedProduct[0].productName),
              quantity: subscription.autoRenewal.renewalQuantity.toString(),
              itemNumber: matchedProduct[0].itemNumber,
              manufacturerItemNumber: matchedProduct[0].manufacturerItemNumber,
              sum: this.$options.filters.currency(lineSum),
            });
            sum += lineSum;
          } 
        }
      });

      if (subscriptions.length > 0) {
        const printData = {
          customer: {
            companyName: this.customer.companyProfile.companyName,
            addressLine1: this.customer.companyProfile.address.addressLine1,
            addressLine2: this.customer.companyProfile.address.addressLine2,
            postalCode: this.customer.companyProfile.address.postalCode,
            city: this.customer.companyProfile.address.city,
          },
          reseller: {
            companyName: this.$store.state.resellerData.companyProfile.companyName,
            addressLine1: this.$store.state.resellerData.companyProfile.address.addressLine1,
            addressLine2: this.$store.state.resellerData.companyProfile.address.addressLine2,
            postalCode: this.$store.state.resellerData.companyProfile.address.postalCode,
            city: this.$store.state.resellerData.companyProfile.address.city,
          },
          cotermDate: this.customer.cotermDate,
          customerId: this.customer.customerId,
          date: moment()
            .tz("Europe/Berlin")
            .format(),
          volumeLevel: volumeLevel,
          consumableLevel: consumableLevel,
          subscriptions: subscriptions,
          sum: sum,
        };

        this.generatePdf(printData);
      } else {
        this.$store.dispatch("alertError", {
          message: "Sie haben keine Auto-Renewal Subscriptions oder einigen Subscriptions konnte kein Produkt zugeordnet werden.",
          show: true,
          noFooter: false,
        });
      }
    },
    generateTable(printData) {
      // const priceChange = this.settings.noPriceRenewalOfferProducts;

      const taxRate = this.getTaxRate(this.$store.state.resellerData.companyProfile.address.country);

      let result = {
        head: [
          [
            {
              content: this.$t("posNo").toUpperCase(),
              styles: { valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("product").toUpperCase(),
              styles: { valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("price").toUpperCase(),
              styles: { halign: "right", valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("quantity").toUpperCase(),
              styles: { halign: "right", valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("totalPrice").toUpperCase(),
              styles: { halign: "right", valign: "middle", fillColor: "#cccccc" },
            },
          ],
        ],
        foot: [
          [
            "",
            "",
            "",
            this.$t("totalPrice").toUpperCase(),
            {
              content: this.$options.filters.currency(printData.sum),
              styles: { halign: "right" },
            },
          ],
          [
            "",
            "",
            "",
            {
              content: this.$t("vat", {taxRate: (taxRate*100)}).toUpperCase(),
              styles: { fontSize: 9, textColor: "#9b9b9b" },
            },
            {
              content: this.$options.filters.currency(printData.sum * taxRate),
              styles: { fontSize: 9, halign: "right", textColor: "#9b9b9b" },
            },
          ],
          [
            "",
            "",
            "",
            {
              content: this.$t("grossPrice").toUpperCase(),
              styles: { fontSize: 9, textColor: "#9b9b9b" },
            },
            {
              content: this.$options.filters.currency(printData.sum * taxRate + printData.sum),
              styles: { fontSize: 9, halign: "right", textColor: "#9b9b9b" },
            },
          ],
        ],
        body: [],
        startY: 127,
        theme: "plain",
        showHead: "firstPage",
        showFoot: "lastPage",
        styles: {
          font: "helvetica",
          fontSize: 12,
          cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
        },
      };

      if (this.$i18n.locale === "en") {
        delete result.foot.splice(1, 1);
        delete result.foot.splice(1, 1);
      }

      let inNewPriceTime = false


      printData.subscriptions.forEach((subscription, index) => {
        //wenn Artikelnummer in priceChange Array ist, dann keine Preise anzeigen
        //wenn cotermDate am oder nach 2.11.2023 ist, dann keine preise anzeigen
        // console.log(printData.cotermDate, moment(printData.cotermDate).isSameOrAfter("2023-11-02"));
        // if(moment(printData.cotermDate).isSameOrAfter("2023-11-02") || priceChange.includes(subscription.itemNumber)) {
        //   inNewPriceTime = true
        //   result.foot && delete result.foot
        // } else {
          inNewPriceTime = false
        // }

        const row = [
          {
            content: index + 1,
            styles: { valign: "middle" },
          },
          `${subscription.manufacturerItemNumber}\n${subscription.productName}`,
            // `${subscription.itemNumber}\n${subscription.productName}`,
          {
            content: inNewPriceTime ? 'No Price' : subscription.price,
            styles: { halign: "right", valign: "middle", textColor: inNewPriceTime && 'red' },
          },
          {
            content: subscription.quantity,
            styles: { halign: "right", valign: "middle" },
          },
          {
            content: inNewPriceTime ? 'Contact our Team' : subscription.sum,
            styles: { halign: "right", valign: "middle", textColor: inNewPriceTime && 'red' },
          },
        ];
        result.body.push(row);
      });
      return result;
    },
    generatePdf(printData) {
      const doc = new jsPDF();
      
      const docLogo = document.querySelectorAll('.imageContainer img')[0];
      const imageSize = {
        width: 78,
        height: 78
      }
      
      doc.addImage(docLogo.src, "png", 120, 5, imageSize.width, imageSize.height);
                                    // Links, Oben, Breite, Höhe


      doc.setFont("Helvetica", "", "normal");

      doc.setFontSize(9);
      doc.setTextColor("#9b9b9b");
      const resellerAdress = `${printData.reseller.companyName}\n${printData.reseller.addressLine1}\n${printData.reseller.postalCode} ${printData.reseller.city}`
      doc.text(doc.splitTextToSize(resellerAdress, 90), 14, 35);

      doc.setFontSize(12);
      doc.setTextColor("#000000");
      const customerAddress = `${printData.customer.companyName}\n${printData.customer.addressLine1}\n${printData.customer.addressLine2}\n${printData.customer.postalCode} ${printData.customer.city}`
      doc.text(doc.splitTextToSize(customerAddress, 90), 14, 50);

      doc.setFontSize(15);
      doc.setFont("Helvetica", "", "bold");
      doc.text(`${this.$t("offerFrom")} ${this.$options.filters.date(printData.date)}`, 14, 97);

      doc.setFontSize(9);
      doc.setFont("Helvetica", "", "normal");
      doc.setTextColor("#9b9b9b");
      doc.text(this.$t("customerId").toUpperCase(), 14, 109);
      doc.text(this.$t("anniversaryDate").toUpperCase(), 54, 109);
      doc.text(this.$t("volumeLevel").toUpperCase(), 89, 109);
      doc.text(this.$t("consumableLevel").toUpperCase(), 139, 109);

      doc.setFontSize(12);
      doc.setTextColor("#000000");
      doc.text(printData.customerId, 14, 115);
      doc.text(this.$options.filters.date(printData.cotermDate), 54, 115);
      doc.text(printData.volumeLevel.toString(), 89, 115);
      doc.text(printData.consumableLevel.toString(), 139, 115);

      doc.autoTable(this.generateTable(printData));

      doc.setFontSize(9);
      const footerText = doc.splitTextToSize(this.$t("renewalOfferFooterText"), 182);
      doc.text(footerText, 14, doc.autoTable.previous.finalY + 12);

      let fileNameSuffix = "";

      if (this.selectedOption === "prices") {
        fileNameSuffix = "-reseller-prices";
      }

      doc.save(`${this.$t("renewalOfferFileName")}${printData.customerId.toLowerCase()}${fileNameSuffix}.pdf`);
    },
    clArtNoObject(itemNumber){
      const itemNumberChunks = itemNumber.split("-");
      const artNrSplittet = itemNumberChunks[3].split("");
      return {
        product: artNrSplittet[0] + artNrSplittet[1] + artNrSplittet[2] + artNrSplittet[3],
        volumeLevel: parseInt(artNrSplittet[4]) + 1,
        language: artNrSplittet[5],
        productType: itemNumberChunks[2],
        specialPriceFunction: itemNumberChunks?.[4] || false,
      };
    }
  },
  mounted() {
    this.getSpecialPriceSwitch()
    this.getGeneralPriceSwitch()
    this.getResellerDataFromCollection()
  }
};
</script>
