<template>
  <div style="height: auto;">
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-container class="my-1">
        <b-navbar-brand class="d-flex align-items-center py-0" :to="{ name: 'HomepageView' }">
          <logo-component :fill="'#ffffff'" :height="'32px'" :width="'32px'" class="mr-2" :lineBreake="false">Adobe Cloud Store</logo-component>
        </b-navbar-brand>
        <b-button v-on:click="signOutUser" variant="primary" class="ml-auto">
          <fa-icon :icon="['fa-regular', 'fa-arrow-right-from-bracket']" fixed-width class="mr-1"></fa-icon>{{ $t("signout") }}
        </b-button>
      </b-container>
    </b-navbar>
    <b-container class="my-4">
      <label class="d-block">Wähle einen Reseller</label>
      <b-form-select v-model="selected" :options="resellers" class="w-25"></b-form-select>
    </b-container>
  </div>
</template>

<script>
import { firestore, functions, auth } from "../plugins/firebase";
import { collection, query, getDocs, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { signInWithCustomToken, signOut } from "firebase/auth";
import LogoComponent from "../components/LogoComponent.vue";
import { orderBy } from 'lodash-es';

export default {
  name: "AdminHomepageView",
  components: {
    LogoComponent,
  },
  data() {
    return {
      resellers: [
        {
          value: null,
          text: "Bitte wählen",
        },
      ],
      selected: null,
    };
  },
  async mounted() {
    let token = this.$route.params?.customerToken
    if(token){
      this.directSignInWithToken(token);
    } else {
      let tokens = await auth.currentUser.getIdTokenResult() || false
      let isAdmin = tokens ? tokens.claims?.admin : false

      if (!isAdmin) {
        this.signOutUser()
      }

      const colRef = query(collection(firestore, "resellers"), where("status", "!=", "1008"));
      const docs = await getDocs(colRef);

      const resellers = [];

      docs.forEach((doc) => {
        resellers.push({
          value: doc.data().customernumber,
          text: `${doc.data().companyProfile.companyName} (${doc.data().customernumber})`,
        });
      });

      this.resellers = orderBy(
          resellers,
          "text",
          "asc"
        );
    }
  },
  watch: {
    selected(value) {
      const signInAdminAsReseller = httpsCallable(functions, "signInAdminAsReseller");
      signInAdminAsReseller({
        uid: value,
      })
        .then((data) => {
          signInWithCustomToken(auth, data.data)
            .then(() => {
              this.$router.replace({ name: "HomepageView" })
            })
            .catch((error) => {
              this.$store.dispatch("alertError", {
                message: error.message,
                show: true,
                noFooter: false,
              });
            });
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
        });
    },
  },
  methods: {
    signOutUser: function() {
      signOut(auth)
        .then(() => this.$router.replace("/"))
        .catch((error) => console.error(error.message));
    },
    directSignInWithToken(token){
      signInWithCustomToken(auth, token)
        .then(() => {
          this.$router.replace({ name: "HomepageView" })
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
        });
    }
  }
};
</script>
