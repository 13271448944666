<template>
  <div v-if="order">
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <div>
          <div class="d-flex align-items-center">
            <b-button class="mr-3" variant="dark" :to="{ name: 'CreateOrderSelectProductsView', params: { orderId: this.$route.params.orderId, } }">
              <fa-icon :icon="['far','angle-left']"></fa-icon>
            </b-button>
            <!-- <div class="d-flex flex-column">
              <small class="text-muted">{{ $t("newOrderFor") }}</small> -->
              <h3 class="font-weight-bold mb-0">
                {{ order.customer.companyProfile.companyName }}
              </h3>
            <!-- </div> -->
          </div>
        </div>
      </b-container>
    </div>
    <div class="bg-light pb-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <div class="d-flex flex-column mr-4">
            <small class="text-muted">{{ $t("creationTime") }}</small>
            <div>
              <fa-icon :icon="['fa-regular','fa-clock']" class="mr-1"></fa-icon>{{ order.creationDate | date }}
            </div>
          </div>

          <div class="d-flex flex-column">
            <small class="text-muted">{{ $t("anniversaryDate") }}</small>
            <div>
              <fa-icon :icon="['fa-regular','fa-calendar-days']" class="mr-1"></fa-icon>{{ cotermDate }}
            </div>
          </div>
        </div>
      </b-container>
    </div>

    <b-container class="pt-4" v-if="order && subscriptions !== null">
      <h4 class="mb-4">{{ $t("summary") }}</h4>
      <h4 class="mb-4 d-none d-print-block">{{ $t("offer") }}</h4>
      <b-card class="mb-4">
        <p class="font-weight-bold">{{ $t("customer") }}</p>
        {{ order.customer.companyProfile.companyName }}<br />
        {{ order.customer.companyProfile.address.addressLine1 }}<br />
        {{ order.customer.companyProfile.address.addressLine2 }}<br />
        {{ order.customer.companyProfile.address.postalCode }} {{ order.customer.companyProfile.address.city }}<br />
        {{ order.customer.companyProfile.address.country }}
      </b-card>

      <div class="mb-4 d-flex alert alert-info align-items-center" v-if="isCalculating">
        <b-spinner label="Spinning" variant="primary" small class="mr-1"></b-spinner>
        {{ $t("bestPriceInfo") }}
      </div>
      <div class="mb-4 alert alert-success" v-else>
        <fa-icon :icon="['fa-regular', 'fa-circle-check']"></fa-icon> {{ $t("bestPriceDeterminedInfo") }}
      </div>

      <b-card no-body class="mb-4">
        <table class="table mb-0">
          <thead>
            <tr>
              <th>{{ $t("product") }}</th>
              <th class="text-right">{{ $t("price") }}</th>
              <th class="text-right">{{ $t("quantity") }}</th>
              <th class="text-right">{{ $t("totalPrice") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in products" :key="`row-${index}`">
              <td>
                <div class="d-flex flex-column align-items-start">
                  <span class="badge badge-secondary mb-1" v-if="item.previewPrice?.manufacturerItemNumber && calcUnusedQuantity(item.previewPrice.manufacturerItemNumber) > 0 && !isCalculating">
                    {{ $t("messageUnusedLicenses", {quantity: calcUnusedQuantity(item.previewPrice.manufacturerItemNumber)} )}}
                  </span>
                  <span class="badge badge-secondary mb-1" v-if="item.previewPrice?.manufacturerItemNumber && calcUnusedQuantity(item.previewPrice.manufacturerItemNumber) < 0 && !isCalculating">
                    {{$t("customerRequestedAPurchase")}}
                  </span>
                  <span class="badge badge-dark mb-1" v-if="!isCalculating && !item.productType.match(/^[678]0/) && item.previewPrice.itemNumberSections.productType[2] !== '3'"><fa-icon :icon="['fa-regular','fa-layer-group']" class="mr-1"></fa-icon>{{ $t("volumeLevel") }} 0{{ item.previewPrice.itemNumberSections.volumeLevel }}</span>
                  <span class="badge badge-dark mb-1" v-if="!isCalculating && !item.productType.match(/^[678]0/) && item.previewPrice.itemNumberSections.productType[2] == '3'"><fa-icon :icon="['fa-regular','fa-layer-group']" class="mr-1"></fa-icon>{{ $t("volumeLevel") }} 1{{ item.previewPrice.itemNumberSections.volumeLevel }}</span>
                  <span class="badge badge-dark mb-1" v-if="!isCalculating && item.productType.match(/^[6]0/) && item.previewPrice.itemNumberSections.productType[2] !== '3'"><fa-icon :icon="['fa-regular','fa-layer-group']" class="mr-1"></fa-icon>{{ $t("consumableLevel") }} T{{ item.previewPrice.itemNumberSections.volumeLevel }}</span>
                  <span class="badge badge-dark mb-1" v-if="!isCalculating && item.productType.match(/^[78]0/) && item.previewPrice.itemNumberSections.productType[2] !== '3'"><fa-icon :icon="['fa-regular','fa-layer-group']" class="mr-1"></fa-icon>{{ $t("volumeLevel") }} 0{{ item.previewPrice.itemNumberSections.volumeLevel }}</span>
                  <span class="badge badge-dark mb-1" v-if="!isCalculating && item.productType.match(/^[6]0/) && item.previewPrice.itemNumberSections.productType[2] == '3'"><fa-icon :icon="['fa-regular','fa-layer-group']" class="mr-1"></fa-icon>{{ $t("consumableLevel") }} T{{ threeYCLVL[item.previewPrice.itemNumberSections.volumeLevel] }}</span>
                  <span class="badge badge-dark mb-1" v-if="!isCalculating && item.productType.match(/^[78]0/) && item.previewPrice.itemNumberSections.productType[2] == '3'"><fa-icon :icon="['fa-regular','fa-layer-group']" class="mr-1"></fa-icon>{{ $t("consumableLevel") }} 1{{item.previewPrice.itemNumberSections.volumeLevel}}</span>
                  <div>{{ item.productName | productNameWithoutLevel }}</div>
                  <small class="text-muted" v-b-tooltip.hover :title="$t('manufacturerNumber')" v-if="!isCalculating">{{ item.previewPrice.manufacturerItemNumber }}</small>
                </div>
              </td>
              <td class="text-right" v-if="!item.productType.match(/^[678]0/)">
                <div v-if="!isCalculating">
                  <div>
                    {{ calcPriceUntilCotermDate(item.previewPrice.price) | currency }}
                    <fa-icon
                      :icon="['fa-regular', 'fa-circle-info']"
                      class="ml-2 text-muted"
                      v-b-tooltip.hover="`${formatCurrency(item.previewPrice.price)} (${$t('pricePerYear')}) / ${daysInYear} (${$t('daysPerYear')}) * ${daysUntilCotermDate} (${$t('daysUntilAnniversaryDate')}) = ${formatCurrency(calcPriceUntilCotermDate(item.previewPrice.price), 4)}`"
                    ></fa-icon>
                  </div>
                  <small class="text-muted" v-if="!isCalculating">{{ $t("rrp") }} {{ calcPriceUntilCotermDate(item.previewPrice.endcustomerSalesPrice) | currency }}</small>
                </div>
                <b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
                <div class="d-none d-print-block text-muted" v-if="!isCalculating">{{ $t("rrp") }} {{ calcPriceUntilCotermDate(item.previewPrice.endcustomerSalesPrice) | currency }}</div>
              </td>
              <td class="text-right" v-else>
                <div v-if="!isCalculating">
                  <div>
                    {{ item.previewPrice.price | currency }}
                  </div>
                  <small class="text-muted">{{ $t("rrp") }} {{ item.previewPrice.endcustomerSalesPrice | currency }}</small>
                </div>
                <b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
                <div class="d-none d-print-block text-muted" v-if="!isCalculating">{{ $t("rrp") }} {{ item.previewPrice.endcustomerSalesPrice | currency }}</div>
              </td>
              <td class="text-right">
                {{ item.quantity }}
              </td>
              <td class="text-right" v-if="!item.productType.match(/^[678]0/)">
                <div v-if="!isCalculating">
                  <div>
                    {{ calcPositionSum(calcPriceUntilCotermDate(item.previewPrice.price), item.quantity) | currency }}
                    <fa-icon
                      :icon="['fa-regular', 'fa-circle-info']"
                      class="ml-2 text-muted"
                      v-b-tooltip.hover="`${formatCurrency(item.previewPrice.price)} (${$t('pricePerYear')}) / ${daysInYear} (${$t('daysPerYear')}) * ${daysUntilCotermDate} (${$t('daysUntilAnniversaryDate')}) x ${item.quantity} (${$t('pieces')}) = ${formatCurrency(calcPositionSum(calcPriceUntilCotermDate(item.previewPrice.price), item.quantity), 4)}`"
                    ></fa-icon>
                  </div>
                  <small class="text-muted">{{ $t("rrp") }} {{ calcPositionSum(calcPriceUntilCotermDate(item.previewPrice.endcustomerSalesPrice), item.quantity) | currency }}</small>
                </div>
                <b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
                <div class="d-none d-print-block text-muted" v-if="!isCalculating">{{ $t("rrp") }} {{ calcPositionSum(calcPriceUntilCotermDate(item.previewPrice.endcustomerSalesPrice), item.quantity) | currency }}</div>
              </td>
              <td class="text-right" v-else>
                <div v-if="!isCalculating">
                  <div>
                    {{ calcPositionSum(item.previewPrice.price, item.quantity) | currency }}
                  </div>
                  <small class="text-muted">{{ $t("rrp") }} {{ calcPositionSum(item.previewPrice.endcustomerSalesPrice, item.quantity) | currency }}</small>
                </div>
                <b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
                <div class="d-none d-print-block text-muted" v-if="!isCalculating">{{ $t("rrp") }} {{ calcPositionSum(item.previewPrice.endcustomerSalesPrice, item.quantity) | currency }}</div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-right p-3 border-top font-weight-bold" v-if="!isCalculating">
          <small class="text-muted" v-if="settings?.showExclTax">{{ $t("exclTax") }}</small><br>
          <span class="mr-3">{{ $t("totalPrice") }}</span><span>{{ calcShoppingCartSum(products) | currency }}</span><br/>
          <small class="mr-3 text-muted">{{ $t("totalPriceRrp") }}</small><small class="text-muted">{{ calcShoppingCartRrpSum(products) | currency }}</small><br/>
          <small class="mr-3 text-muted">{{ $t("margin") }}
          <fa-icon
            :icon="['fa-regular', 'fa-circle-info']"
            class="ml-2 text-muted"
            v-b-tooltip.hover="`${calcShoppingCartRrpSum(products).toFixed(2)} (${$t('totalPriceRrp')}) - ${calcShoppingCartSum(products).toFixed(2)} (${$t('totalPrice')}) = ${(calcShoppingCartRrpSum(products) - calcShoppingCartSum(products)).toFixed(2)} (${$t('margin')})`"
          ></fa-icon>
          </small>
          <small class="text-muted">{{(calcShoppingCartRrpSum(products) - calcShoppingCartSum(products)) | currency}}</small>
        </div>
        <div class="text-right border-top p-3" v-else>
          <b-spinner label="Spinning" variant="secondary" small></b-spinner>
        </div>
        <div class="text-right p-3 border-top font-weight-bold d-none d-print-block">
          <span class="mr-3">{{ $t("totalPrice") }}</span><span v-if="!isCalculating">{{ calcShoppingCartRrpSum(products) | currency }}</span><b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
        </div>
      </b-card>

      <div class="mb-4 alert alert-warning" v-if="!allowedToOrder">
        {{ $t("userIsNotAllowedToOrder") }}
      </div>

      <b-card footer-class="border-0 bg-light">
        <b-row>
          <b-col class="d-flex flex-column">
            <label class="mb-0">{{ $t("orderNumber") }}</label>
            <small class="text-muted">{{ $t("optional") }}</small>
          </b-col>
          <b-col>
            <b-input v-model="reference"></b-input>
          </b-col>
        </b-row>
        
        <template #footer>
          <div class="d-flex justify-content-end align-items-center">
            <generate-offer-component :order="order" :products="products" :isCalculating="isCalculating"></generate-offer-component>
            
            <submit-button-component
              v-on:click="createOrder()"
              :state="submitButtonState"
              :label="$t('orderNow')"
              :block="false"
              class="my-2 ml-3"
              v-if="allowedToOrder"
            ></submit-button-component>
          </div>
        </template>
      </b-card>
      
    </b-container>
  </div>
  
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import GenerateOfferComponent from '../components/GenerateOfferComponent.vue';
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, updateDoc, collection, getDocs, query, where } from "firebase/firestore";

export default {
  components: { SubmitButtonComponent, GenerateOfferComponent },
  name: "CreateOrderCheckoutView",
  computed: {
    ...mapState(["settings"]),
    daysUntilCotermDate() {
      if (this.order.customer.cotermDate === "") {
        let daysInYear = 365;
        if (moment(moment()).isLeapYear() && moment().format('M') <= 2 ) {
          daysInYear = 366;
        }
        return daysInYear;
      } else {
        return moment(this.order.customer.cotermDate).tz("Europe/Berlin").diff(moment().tz("Europe/Berlin").format("YYYY-MM-DD"), "days");
      }
    },
    daysInYear() {
      let daysInYear = 365;
      if (moment(this.order.customer.cotermDate).isLeapYear() && moment(this.order.customer.cotermDate).format('M') > 2 || moment(moment()).isLeapYear() && moment().format('M') <= 2 ) {
        daysInYear = 366;
      }
      return daysInYear;
    },
    daysInYearAdobeVersion() {
      //Laut Adobe richtig... 
      let daysInYear = 365;
      if ((moment(moment().add(1, "years")).isLeapYear() || moment().isLeapYear()) && moment().format('M') > 2) {
        daysInYear = 366;
      }
      return daysInYear;
    },
    submitButtonState() {
      if (this.isWorking) {
        return "working";
      } else if (this.isCalculating) {
        return "missingFields";
      } else if (this.isBlocked) {
        return "blocked"
      }

      return "";
    },
    cotermDate() {
      if (this.order.customer.cotermDate === "") {
        return this.$t("anniversaryDateNotSet");
      }
      return this.$options.filters.date(this.order.customer.cotermDate);
    },
    allowedToOrder() {
      return this.$store.state.resellerSubUserData === null || this.$store.state.resellerSubUserData.allowedToOrder === undefined || this.$store.state.resellerSubUserData.allowedToOrder;
    }
  },
  data() {
    return {
      reference: "",
      isCalculating: true,
      isWorking: false,
      isBlocked: false,
      sum: 0,
      order: null,
      docId: "",
      products: [],
      customer: "",
      fields: [
        {
          key: "productName",
          label: "Produkt",
          sortable: true,
        },
        {
          key: "price",
          label: "Tagespreis",
          sortable: true,
        },
        {
          key: "cotermDatePrice",
          label: "Preis",
          sortable: true,
        },
      ],
      threeYCLVL: {
        1: "A",
        2: "B",
        3: "C",
        4: "D",
        5: "E",
        6: "F",
        7: "G"
      },
      subscriptions: null,
    };
  },
  mounted() {
    this.getSpecialPriceSwitch();
    this.getOrder();
    this.checkBrowserTime();
  },
  methods: {
    async checkBrowserTime() {
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const checkTime = httpsCallable(functions, "serverTime");
      const checkTimeState = await checkTime({timeZone: clientTimezone, time: moment().tz("Europe/Berlin").format("YYYY-MM-DD") })
      if(!checkTimeState.data){
        this.isBlocked = true;
        this.$store.dispatch("alertError", {
          message: "Es ist ein Fehler aufgetreten. Sie können Ihre Bestellung nicht abschließen.",
          show: true,
          noFooter: false,
        })
      } 
    },
    async getOrder() {
      try {
        const docRef = doc(firestore, "orders", this.$route.params.orderId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          this.docId = docSnapshot.id;
          this.order = docSnapshot.data();
          this.getCustomerAccount(this.order.customer.customerId);
          this.getSubscriptions(this.order.customer.customerId);
          this.getOrderItems();
        } else {
          this.$store.dispatch("alertError", {
            message: "Der Auftrag konnte nicht abgerufen werden.",
            show: true,
            noFooter: false,
          })
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alertError", {
          message: "Der Auftrag konnte nicht abgerufen werden.",
          show: true,
          noFooter: false,
        })
      }
    },
    getCustomerAccount(customerId) {
      const getCustomerAccount = httpsCallable(functions, "customerAccount");
      return getCustomerAccount({
          customerId: customerId,
        })
        .then((customerAccount) => {
          this.customer = customerAccount.data;
          const cotermDateTimestamp = moment(customerAccount.data.cotermDate).tz("Europe/Berlin").unix();
          const todayTimestamp = moment().tz("Europe/Berlin").unix();
          
          if (cotermDateTimestamp < todayTimestamp) {
            const thisYear = moment().tz("Europe/Berlin").format("YYYY");
            const cotermDateDayAndMonth = moment(customerAccount.data.cotermDate).format("MM-DD");
            const cotermDateYear = moment(customerAccount.data.cotermDate).format("YYYY");

            if (cotermDateYear === thisYear) {
              const nextYear = moment().tz("Europe/Berlin").add(1, "years").format("YYYY");
              customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
            } else {
              const newCotermDateThisYear = `${thisYear}-${cotermDateDayAndMonth}`;
              const newCotermDateThisYearTimestamp = moment(newCotermDateThisYear).tz("Europe/Berlin").unix();

              if (newCotermDateThisYearTimestamp < todayTimestamp) {
                const nextYear = moment().tz("Europe/Berlin").add(1, "years").format("YYYY");
                customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
              } else {
                customerAccount.data.cotermDate = newCotermDateThisYear;
              }
            }
          }

          this.saveCustomerToOrder(customerAccount.data)

          this.order.customer = customerAccount.data;
        })
        .catch(() => {
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht abgerufen werden.",
            show: true,
            noFooter: false,
          })
          return;
        });
    },
    async saveCustomerToOrder(customer) {
      try {
        const colRef = query(collection(firestore, "customers"), where("customerId", "==", customer.customerId));
        const docs = await getDocs(colRef);
        console.log(docs.docs[0].data())
        customer.resellerReferenceId = docs.docs?.[0].data()?.resellerReferenceId || ""

        const docRef = doc(firestore, "orders", this.$route.params.orderId);
        updateDoc(docRef, {
          customer: customer,
        });
      } catch (error) {
        this.$store.dispatch("alertError", {
          message: "Die Kundendaten konnten nicht gespeichert werden.",
          show: true,
          noFooter: false,
        })
      }
    },
    async getOrderItems() {
      const distributorPricesColRef = collection(firestore, "distributorPrices");
      const distributorPricesPromise = getDocs(distributorPricesColRef);

      const pricesColRef = collection(firestore, "resellers", this.$store.state.resellerData.firestoreId, "prices");
      const pricesPromise = getDocs(pricesColRef);

      const orderProductsColRef = collection(firestore, "orders", this.$route.params.orderId, "products");
      const orderProductsPromise = getDocs(orderProductsColRef);

      const productsColRef = collection(firestore, "products");
      const productsPromise = getDocs(productsColRef);

      const results = await Promise.all([pricesPromise, distributorPricesPromise, orderProductsPromise, productsPromise])
        .catch((error) => {
          console.error(error);
        });

      if (results[0].empty || results[2].empty || results[3].empty) {
          this.$store.dispatch("alertError", {
            message: "Es wurden keine Produkte oder Preise gefunden.",
            show: true,
            noFooter: false,
          })
        return;
      }

      const resellerPrices = [];
      results[0].forEach((price) => {
        resellerPrices.push(price.data());
      });

      const distributorPrices = [];
      results[1]?.forEach((price) => {
        distributorPrices.push(price.data());
      });

      const products = [];
      results[3].forEach((product) => {
        products.push(product.data());
      });

      results[2].forEach((orderProduct) => {
        const orderProductData = orderProduct.data();

        orderProductData.prices.forEach((price) => {
          const matchingResellerPrice = resellerPrices.filter((resellerPrice) => resellerPrice.itemNumber === price.itemNumber);
          const matchingDistributorPrice = distributorPrices?.filter((distributorPrice) => distributorPrice.itemNumber === price.itemNumber) || false;
          const matchingProduct = products.filter((product) => product.itemNumber === price.itemNumber);

          price.price = matchingResellerPrice[0].price;
          price.endcustomerSalesPrice = matchingProduct[0]?.partnerData?.endcustomerSalesPrice ? matchingProduct[0]?.partnerData?.endcustomerSalesPrice: matchingProduct[0].endcustomerSalesPrice;
          price.imputedCost = matchingProduct[0]?.partnerData?.imputedCost ? matchingProduct[0]?.partnerData?.imputedCost : matchingProduct[0].imputedCost;
          price.distributor = {
            price: matchingDistributorPrice?.[0]?.price,
            endcustomerSalesPrice: matchingProduct[0].endcustomerSalesPrice,
            imputedCost: matchingProduct[0].imputedCost
          }
        })
        this.products.push(orderProductData);
      });

      this.previewOrder();
    },
    checkLimit(){
      this.isWorking = true;

      const checkCustomerLimit = httpsCallable(functions, "checkCustomerLimit");
      checkCustomerLimit()
        .then((res) => {
          const data = res.data
          if( !data.liefersperre && Number(this.sum) < (data.limit_offenepostenbrutto - data.offene_rechnungen)) {
            this.createOrder()
          } else if(Number(this.sum) < (data.limit_offenepostenbrutto - data.offene_rechnungen)) {
            this.isWorking = false;
            this.$store.dispatch("alertError", {
              message: "Ihr Limit ist nicht ausreichend.",
              show: true,
              noFooter: false,
            })
          } else {
            this.isWorking = false;
            this.$store.dispatch("alertError", {
              message: "Die Bestellung konnte nicht ausgeführt werden. Bitte wenden Sie sich an einen Mitarbeiter der Software BU.",
              show: true,
              noFooter: false,
            })
          }
        })
        .catch(error => {
          this.isWorking = false;
          this.$store.dispatch("alertError", {
            message: error,
            show: true,
            noFooter: false,
          })
        })
    },
    previewOrder() {
      const previewOrder = httpsCallable(functions, "getCustomerVolumeLevel");
      previewOrder({
        docId: this.docId,
        customerId: this.order.customer.customerId,
        order: {
          externalReferenceId: this.$route.params.orderId,
          currencyCode: "EUR",
          orderType : "PREVIEW",
          lineItems: this.generatePreviewLineItemsObject(this.products),
        },
      })
        .then((response) => {
          this.products.forEach((product, index) => {
            const foundInResponse = response.data.lineItems.filter((line)=> line.extLineItemNumber == (index+1) );
            const previewOfferId = foundInResponse[0].offerId;
            // FILTERN DES PREISES
            const itemIncludesSpecialPrices = this.includesSpecialPrices(product)

            if(itemIncludesSpecialPrices?.length > 0){
              const relevantPostfixes = this.searchRelevantPostfixes()

              if(relevantPostfixes.length > 0){
                const matchingPostfixes = relevantPostfixes.filter(each => itemIncludesSpecialPrices.includes(each.postfix))

                if(matchingPostfixes.length > 0){
                  product["previewPrice"] = product.prices.filter((price) => {
                    if(price.manufacturerItemNumber === previewOfferId && price?.itemNumberSections?.specialPriceFunction && price?.itemNumberSections?.specialPriceFunction == matchingPostfixes[0]?.postfix) {
                      return true
                    } else {
                      return false
                    }
                  })[0]; 
                } else {
                  product["previewPrice"] = product.prices.filter((price) => {
                    if(price.manufacturerItemNumber === previewOfferId && !price?.itemNumberSections?.specialPriceFunction) {
                      return true
                    } else {
                      return false
                    }
                  })[0]; 
                }
              } else {
                product["previewPrice"] = product.prices.filter((price) => price.manufacturerItemNumber === previewOfferId)[0];
              }
            } else {
              product["previewPrice"] = product.prices.filter((price) => price.manufacturerItemNumber === previewOfferId)[0];
            }

            console.log(product["previewPrice"])
          });

          this.isCalculating = false;
        })
        .catch(error => {
          this.isWorking = false;
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        })
    },
    async getSpecialPriceSwitch(){
      const colRef = query(collection(firestore, "specialPriceSwitch"));
      const querySnapshot = await getDocs(colRef);
      const data = []
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          data.push(doc.data())
        });
      }
      this.specialPriceSwitch = data;
      return
    },
    searchRelevantPostfixes() {
      if(this.customer.benefits?.[0]?.commitment?.startDate){
        let relevantPostfixes = []

        this.specialPriceSwitch.forEach(each => { 
          if(
            moment(each.date.toDate())
            .isAfter(
              moment(this.customer.benefits?.[0]?.commitment?.startDate)
            )
          ) {
            relevantPostfixes.push(each)
          }
        })

        // nach datum sortieren
        return relevantPostfixes.sort(function(a,b){
          return a.date.toDate() - b.date.toDate();
        });
      } else { 
        return false
      }
    },
    includesSpecialPrices(item){
      let includesSpecialPrices = [];
      item.prices.forEach(price =>{
        if (price.itemNumberSections?.specialPriceFunction != false) {
          includesSpecialPrices.push(price?.itemNumberSections?.specialPriceFunction);
          includesSpecialPrices = [...new Set(includesSpecialPrices)];
        }
      })
      return includesSpecialPrices
    },
    generatePreviewLineItemsObject(products) {
      const adobeLineItems = [];

      products.forEach((product, index) => {
        adobeLineItems.push({
          extLineItemNumber: index + 1,
          offerId: product.manufacturerItemNumber,
          quantity: product.quantity,
        });
      });

      return adobeLineItems;
    },
    partnerOrderLines(products, customerId){
      const orderLines = []
      products.forEach((product) => {
        const kalkKostenTotal = product.quantity * this.calcPriceUntilCotermDateAdobeVersion(product.previewPrice.distributor.price, product.productType);
        const unitCost = this.calcPriceUntilCotermDate(product.previewPrice.distributor.price, product.productType);
        orderLines.push({
          kalk_kosten_total: parseFloat(kalkKostenTotal.toFixed(2)) || false,
          vipmp_offer_id: product.previewPrice.manufacturerItemNumber,
          name: product.productName,
          unitCost: unitCost || false,
          reference: `Customer-ID: ${customerId} // Jahrespreis pro Lizenz: ${product.previewPrice.price} // Tage im Jahr: ${this.daysInYearAdobeVersion} // Anzahl Tage bis Anniversary Date: ${this.daysUntilCotermDate} // Anzahl der Lizenzen: ${product.quantity}`,
        })
      })
      return orderLines
    },
    generateAdobeLineItemsObject(products) {
      const adobeLineItems = [];
      products.forEach((product, index) => {
        adobeLineItems.push({
          extLineItemNumber: index + 1,
          offerId: product.previewPrice.manufacturerItemNumber,
          quantity: product.quantity,
        });
      });

      return adobeLineItems;
    },
    generateDeluxeLineItemsObject(products, customerId) {
      const deluxeLineItems = [];

      products.forEach((product) => {
        const kalkKostenTotal = product.quantity * this.calcPriceUntilCotermDateAdobeVersion(product.previewPrice.imputedCost, product.productType);
        const unitCost = this.calcPriceUntilCotermDate(product.previewPrice.price, product.productType);

        deluxeLineItems.push({
          sku: product.previewPrice.itemNumber,
          quantity: product.quantity,
          unit_cost: parseFloat(unitCost.toFixed(2)),
          vipmp_offer_id: product.previewPrice.manufacturerItemNumber,
          vipmp_subscription_id: "", // erhalte ich nach adobe order
          reference: `\n\nCustomer-ID: ${customerId}\nJahrespreis pro Lizenz: ${product.previewPrice.price}\n Tage im Jahr: ${this.daysInYearAdobeVersion} \nJahres-EVK pro Lizenz: ${product.previewPrice.endcustomerSalesPrice}\nAnzahl Tage bis Anniversary Date: ${this.daysUntilCotermDate}\nAnzahl der Lizenzen: ${product.quantity}\n\n`,
          kalk_kosten_total: parseFloat(kalkKostenTotal.toFixed(2)),
          kalk_kosten_flag: true,
          valid_cost_difference: -1,
        });
      });

      return deluxeLineItems;
    },
    createOrder() {
      this.isWorking = true;

      if (this.reference == "") {
        this.reference = this.order.customer.resellerReferenceId;
      }
      console.log(this.reference)
      const order = {
        sum: this.sum,
        docId: this.docId,
        customerId: this.order.customer.customerId,
        order: {
          externalReferenceId: this.$route.params.orderId,
          currencyCode: "EUR",
          orderType: "NEW",
          lineItems: this.generateAdobeLineItemsObject(this.products),
        },
        deluxeOrder: {
          order_reference: `New: ${this.reference}`,
          customercode: this.$store.state.resellerData.customernumber,
          ship_to_adress1: this.order.customer.companyProfile.companyName,
          ship_to_street: this.order.customer.companyProfile.address.addressLine1,
          ship_to_street_extension: "", // Huasnummer muss extra feld sein wenn der Kunde erstellt wird.
          ship_to_plz: this.order.customer.companyProfile.address.postalCode,
          ship_to_city: this.order.customer.companyProfile.address.city,
          ship_to_country_iso: this.order.customer.companyProfile.address.country,
          type: "vipmp_order",
          vipmp_customer_id: this.order.customer.customerId,
          vipmp_order_id: "", // erhalte ich nach adobe order
          vipmp_endcustomer_email: this.order.customer.companyProfile.contacts[0].email,
          vipmp_endcustomer_phonenumber: this.order.customer.companyProfile.contacts[0].phoneNumber,
          vipmp_endcustomer_renewal_date: this.order.customer.cotermDate,
          order_lines: this.generateDeluxeLineItemsObject(this.products, this.order.customer.customerId)
        },
        partnerData: {
          resellerCustomerNumber: this.$store.state.resellerData.customernumber,
          order_lines: this.partnerOrderLines(this.products, this.order.customer.customerId)
        }
      }

      const createOrder = httpsCallable(functions, "createOrder");
      createOrder(order)
        .then(() => {
          this.isWorking = false;
          this.$router.push({ name: "CreateOrderCompletedView" });
        })
        .catch((error) => {
          console.error(error);
          this.isWorking = false;
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
    },
    calcPriceUntilCotermDate(price, productType ) {
      if (this.order.customer.cotermDate === "" || (productType && productType.match(/^[678]0/))) {
        return price;
      }
      return price / this.daysInYear * this.daysUntilCotermDate;
    },
    calcPriceUntilCotermDateAdobeVersion(price, productType) {
      if (this.order.customer.cotermDate === "" || (productType && productType.match(/^[678]0/))) {
        return price;
      }
      return price / this.daysInYearAdobeVersion * this.daysUntilCotermDate;
    },
    calcShoppingCartSum(products) {
      let sum = 0;

      products.forEach((product) => {
        if (!product.productType.match(/^[678]0/)) {
          sum += this.calcPositionSum(this.calcPriceUntilCotermDate(product.previewPrice.price), product.quantity);
        } else {
          sum += this.calcPositionSum(product.previewPrice.price, product.quantity);
        }
      });

      this.sum = sum;

      return sum;
    },
    calcShoppingCartRrpSum(products) {
      let sum = 0;

      products.forEach((product) => {
        if (!product.productType.match(/^[678]0/)) {
          sum += this.calcPositionSum(this.calcPriceUntilCotermDate(product.previewPrice.endcustomerSalesPrice), product.quantity);
        } else {
          sum += this.calcPositionSum(product.previewPrice.endcustomerSalesPrice, product.quantity);
        }
      });

      this.sum = sum;

      return sum;
    },
    calcPositionSum(priceUntilCotermDate, quantity) {
      return priceUntilCotermDate * quantity
    },
    formatCurrency(val, maximumFractionDigits) {
      return this.$options.filters.currency(val, maximumFractionDigits)
    },
    async getSubscriptions(customerId) {
      const getCustomerSubscriptions = httpsCallable(functions, "getCustomerSubscriptions");
      const subscriptions = await getCustomerSubscriptions({
        customerId: customerId,
      });

      this.subscriptions = subscriptions.data.items;
    },
    removeVolumeLevelFromOfferId(offerId) {
      let rebuild = "";

      if (offerId !== undefined) {
        rebuild = offerId.split("CA")[0] + "CA00A" + offerId.split("CA")[1].split("A")[1]
      }

      return rebuild;
    },
    calcUnusedQuantity(product) {
      let unusedQuantity = 0;

      const matchingSubscription = this.subscriptions.filter((subscription) => this.removeVolumeLevelFromOfferId(subscription.offerId) === this.removeVolumeLevelFromOfferId(product));

      if (matchingSubscription.length > 0) {
        unusedQuantity = (matchingSubscription[0]?.currentQuantity - matchingSubscription[0]?.usedQuantity);
      }
      
      return unusedQuantity;
    }
  },
};
</script>
