import moment from "moment-timezone";
import i18n from "../i18n";

const accountStatus = (status) => {
  if (status === "1000") {
    return i18n.t("active");
  } else if (status === "1002") {
    return i18n.t("pending");
  } else if (status === "1004") {
    return i18n.t("inactive");
  } else if (status === "1010") {
    return i18n.t("inactiveInvalidAddress");
  } else if (status === "1012") {
    return i18n.t("inactiveAccountBlocked");
  } else if (status === "1014") {
    return i18n.t("inactiveCustomerAlreadyExists");
  }
};

const subscriptionStatus = (status) => {
  if (status === "1000") {
    return i18n.t("active");
  } else if (status === "1002") {
    return i18n.t("pending");
  } else if (status === "1004") {
    return i18n.t("inactive");
  } else if (status === "init") {
    return i18n.t("transferNotSubmitted");
  }
};

const orderStatus = (status) => {
  if (status === "1000") {
    return i18n.t("complete");
  } else if (status === "1002") {
    return i18n.t("open");
  } else if (status === "1004") {
    return i18n.t("failed");
  } else if (status === "1008") {
    return i18n.t("canceled");
  } else if (status === "1020") {
    return i18n.t("failedDistributorInactive");
  } else if (status === "1022") {
    return i18n.t("failedResellerInactive");
  } else if (status === "1024") {
    return i18n.t("failedCustomerInactive");
  } else if (status === "1026") {
    return i18n.t("failedCustomerIdInavild");
  }
};

const currency = (value, maximumFractionDigits) => {
  if (!maximumFractionDigits) {
    maximumFractionDigits = 2;
  }
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
};

const dateTime = (date) => {
  if (!date) {
    return date
  }
  return moment(date).format("DD.MM.YYYY HH:MM:ss");
};

const date = (date) => {
  if (!date) {
    return date
  }
  return moment(date).format("DD.MM.YYYY");
};

const productName = (name) => {
  return name.replace(/(\*\*)/g, "");
};

const productNameWithoutLevel = (name) => {
  const result = name.replace(/\s*\([^)/]*\)\s*(\**)/g, "");
  return result;
};

export {
  accountStatus,
  subscriptionStatus,
  orderStatus,
  currency,
  dateTime,
  date,
  productName,
  productNameWithoutLevel,
};
