import Vue from "vue";
import VueI18n from "vue-i18n";
import deContent from "../i18n/de_content.js";
import deUI from "./de_ui.js";
import enContent from "../i18n/en_content.js";
import enUI from "./en_ui.js";

Vue.use(VueI18n);

const messages = {
  de: {...deContent, ...deUI},
  en: {...enContent, ...enUI},
};

const i18n = new VueI18n({
  locale: "de",
  messages,
});

export default i18n;
