import { initializeApp } from 'firebase/app';
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyABiYnrIXWVfmEz9kvHxzhS3jExTokTfxU",
  authDomain: "clgmbh-shopadmin.firebaseapp.com",
  databaseURL: "https://clgmbh-shopadmin.firebaseio.com",
  projectId: "clgmbh-shopadmin",
  storageBucket: "clgmbh-shopadmin.appspot.com",
  messagingSenderId: "938564660150",
  appId: "1:938564660150:web:b8322da8c3e991e803af17"
};

const region = 'europe-west3';

location.hostname === 'localhost' && (self.FIREBASE_APPCHECK_DEBUG_TOKEN = true)

const firebase = initializeApp(firebaseConfig, "ShopAdmin");

const appCheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider('6LchQIkgAAAAAHVpajOluoZO-UCM0AexCTmsCXaH'),
  isTokenAutoRefreshEnabled: true
});

const db = getDatabase(firebase);

if (location.hostname === 'localhost') {
  connectDatabaseEmulator(db, 'localhost', 9000);
}

export {firebase, db, region, appCheck} 