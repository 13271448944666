<template>
  <div>
    <b-button variant="dark" v-b-modal="'shopping-cart-' + index">
      <fa-icon :icon="['fa-regular', 'fa-cart-shopping']" class="mr-2"></fa-icon>
      {{ shoppingCartQuantity }} {{ $t("pieces") }}
    </b-button>

    <b-modal
      :id="'shopping-cart-' + index"
      size="lg"
      :content-class="contentClass"
      :footer-class="footerClass"
      :header-class="headerClass"
      :body-class="bodyClass"
      :title="$t('shoppingCart')"
    >
      <table class="table mb-0" v-if="items.length > 0">
        <tbody>
          <tr v-for="(item, index) in items" :key="`row-${index}`">
            <td class="d-flex align-items-center">
              <div class="border rounded p-1 mr-3">
                <b-img-lazy :src="item.imageUrl" :alt="item.productName"></b-img-lazy>
              </div>
              <div>
                {{ item.productName | productNameWithoutLevel }}<br />
                <small class="text-muted" v-b-tooltip.hover :title="$t('manufacturerNumber')">
                  {{ item.manufacturerItemNumber }}
                </small>
              </div>
            </td>
            <td>
              <b-input
                type="number"
                v-model.number="item.quantity"
                min="1"
                style="width: 85px"
                v-on:input="changeQuantity(item)"
              ></b-input>
            </td>
            <td>
              <div class="d-flex justify-content-end">
                <b-button variant="danger" v-on:click="removeFromCart(item)">
                  <fa-icon :icon="['fa-regular', 'fa-trash']" class="mr-1"></fa-icon>{{ $t("delete") }}
                </b-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="font-italic" v-else>
        {{ $t("cartIsEmpty") }}
      </div>

      <template #modal-footer>
        <b-button variant="primary" :to="{ name: 'CreateOrderCheckoutView' }" :disabled="isSubmitDisabled">
          {{ $t("requestPrices") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { firestore } from "../plugins/firebase";
import { doc, collection, deleteDoc, updateDoc, onSnapshot } from "firebase/firestore";

export default {
  name: "ShoppingCartComponent",
  props: {
    orderId: String,
    cotermDate: String,
    index: Number,
  },
  computed: {
    formattedCotermDate() {
      if (this.cotermDate === "") {
        if (moment(moment()).isLeapYear()) {
          return 366;
        } else {
          return 365;
        }
      } else {
        return moment(this.cotermDate)
          .tz("Europe/Berlin")
          .diff(
            moment()
              .tz("Europe/Berlin")
              .format("YYYY-MM-DD"),
            "days"
          );
      }
    },
    isSubmitDisabled() {
      if (this.items.length === 0 || this.submitDisabled) return true;
      return false;
    },
  },
  data() {
    return {
      shoppingCartQuantity: 0,
      items: [],
      contentClass: ["border-0 shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      headerClass: ["border-0 bg-light p-4"],
      bodyClass: ["d-flex p-4"],
    };
  },
  mounted() {
    const colRef = collection(firestore, "orders", this.$route.params.orderId, "products");
    onSnapshot(colRef, (querySnapshot) => {
      this.shoppingCartQuantity = 0;
      this.items = [];
      querySnapshot.forEach((doc) => {
        const item = doc.data();
        item["firestoreId"] = doc.id;
        this.items.push(item);
        this.shoppingCartQuantity += item.quantity;
      });
    });
  },
  methods: {
    removeFromCart(item) {
      const docRef = doc(firestore, "orders", this.$route.params.orderId, "products", item.firestoreId);
      deleteDoc(docRef);
    },
    calcPriceUntilCotermDate(price) {
      if (this.cotermDate === "") {
        if (moment(moment()).isLeapYear()) {
          return price * 366;
        } else {
          return price * 365;
        }
      } else {
        return (
          price *
          moment(this.cotermDate)
            .tz("Europe/Berlin")
            .diff(
              moment()
                .tz("Europe/Berlin")
                .format("YYYY-MM-DD"),
              "days"
            )
        );
      }
    },
    calcPositionSum(priceUntilCotermDate, quantity) {
      return priceUntilCotermDate * quantity;
    },
    changeQuantity(item) {
      const docRef = doc(firestore, "orders", this.$route.params.orderId, "products", item.firestoreId);
      updateDoc(docRef, item);
    },
  },
};
</script>

<style>
.table img {
  max-height: 60px;
}
</style>
