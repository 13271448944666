import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA1faxsR_JUHc1yDpW1oMn0YsonPKZAh24",
  authDomain: "clgmbh-acs-target.firebaseapp.com",
  projectId: "clgmbh-acs-target",
  storageBucket: "clgmbh-acs-target.appspot.com",
  messagingSenderId: "269421055686",
  appId: "1:269421055686:web:4fb64e9c9e58120d1511c7"
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const firestore = getFirestore(firebase);
const functions = getFunctions(firebase, "europe-west3");
const storage = getStorage(firebase);

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}

export { auth, firestore, functions, storage };
