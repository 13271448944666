<template>
  <div>
    <!-- <b-button variant="outline-secondary" v-on:click="generateOffer()" :disabled="isCalculating">{{ $t("printOffer") }}</b-button> -->

    <b-dropdown
      split
      split-variant="outline-secondary"
      variant="secondary"
      :text="$t('printOffer')"
      class="m-2"
      v-on:click="generateOffer()"
    >
      <b-dropdown-item @click="salesPrice = true" :active="salesPrice">{{$t("rrp")}}</b-dropdown-item>
      <b-dropdown-item @click="salesPrice = false" :active="!salesPrice">{{$t("purchasePrices")}}</b-dropdown-item>
    </b-dropdown>

    <div class="imageContainer d-none">
      <ImageBlob v-if="imagePath != ''" :data="{image: imagePath}" id="doc-logo" class="d-none"/>
      <img v-else src="/logo_pdf.png" alt="Logo" id="doc-logo" class="d-none" />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import jsPDF from "jspdf";
import ImageBlob from "./ImageBlob.vue"
import { firestore } from "../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";
import "../../node_modules/jspdf-autotable";

export default {
  name: "GenerateOfferComponent",
  props: {
    order: Object,
    products: Array,
    isCalculating: Boolean,
  },
  components: { 
    ImageBlob,
  },
  data() {
    return {
      imagePath: "",
      salesPrice: true
    }
  },
  computed: {
    daysUntilCotermDate() {
      if (this.order.customer.cotermDate === "") {
        let daysInYear = 365;
        if (moment(moment()).isLeapYear()) {
          daysInYear = 366;
        }
        return daysInYear;
      } else {
        return moment(this.order.customer.cotermDate).tz("Europe/Berlin").diff(moment().tz("Europe/Berlin").format("YYYY-MM-DD"), "days");
      }
    },
    daysInYear() {
      let daysInYear = 365;
      if (moment(this.order.customer.cotermDate).isLeapYear() && moment(this.order.customer.cotermDate).format('M') > 2 || moment(moment()).isLeapYear() && moment().format('M') <= 2 ) {
        daysInYear = 366;
      }
      return daysInYear;
    },
    daysInYearAdobeVersion() {
      //Laut Adobe richtig... 
      let daysInYear = 365;
      if ((moment(moment().add(1, "years")).isLeapYear() || moment().isLeapYear()) && moment().format('M') > 2) {
        daysInYear = 366;
      }
      return daysInYear;
    },
  },
  methods: {
    async getResellerDataFromCollection() {
      const docRef = doc(firestore, "resellers", this.$store.state.resellerData.customernumber);
      const docSnapshot = await getDoc(docRef);
      this.imagePath = docSnapshot.data()?.logo?.path || false;
    },
    getTaxRate(country) {
      if (this.$store.state.taxRates[country] !== undefined) {
        return this.$store.state.taxRates[country];
      } else {
        return 0.19;
      }
    },
    calcPositionSum(priceUntilCotermDate, quantity) {
      return priceUntilCotermDate * quantity
    },
    calcPriceUntilCotermDate(price, productType) {
      if (this.order.customer.cotermDate === "" || (productType && productType.match(/^[678]0/))) {
        return price;
      }
      return price / this.daysInYear * this.daysUntilCotermDate;
    },
    calcPriceUntilCotermDateAdobeVersion(price, productType) {
      if (this.order.customer.cotermDate === "" || (productType && productType.match(/^[678]0/))) {
        return price;
      }
      return price / this.daysInYearAdobeVersion * this.daysUntilCotermDate;
    },
    async generateOffer() {
      const subscriptions = [];
      let sum = 0;

      this.products.forEach((product) => {
        console.log(product.previewPrice)
          const salesPrice =  this.salesPrice ? 'endcustomerSalesPrice' : 'price';

          const price = this.calcPriceUntilCotermDateAdobeVersion(product.previewPrice[salesPrice], product.productType);
          const lineSum = this.calcPositionSum(this.calcPriceUntilCotermDateAdobeVersion(product.previewPrice[salesPrice], product.productType), product.quantity);

          subscriptions.push({
            price: this.$options.filters.currency(price),
            productName: this.$options.filters.productNameWithoutLevel(product.productName),
            quantity: product.quantity.toString(),
            itemNumber: product.manufacturerItemNumber,
            sum: this.$options.filters.currency(lineSum),
          });
          sum += lineSum;
      });

      let volumeLevel = "";
      let consumableLevel = "";

      if (this.order.customer.discounts !== undefined) {
        this.order.customer.discounts.forEach((discount) => {
          if (discount.offerType === "LICENSE") {
            volumeLevel = discount.level;
          }

          if (discount.offerType === "CONSUMABLES") {
            consumableLevel = discount.level;
          }
        });
      }

      const printData = {
        customer: {
          companyName: this.order.customer.companyProfile.companyName,
          addressLine1: this.order.customer.companyProfile.address.addressLine1,
          addressLine2: this.order.customer.companyProfile.address.addressLine2,
          postalCode: this.order.customer.companyProfile.address.postalCode,
          city: this.order.customer.companyProfile.address.city,
        },
        reseller: {
          companyName: this.$store.state.resellerData.companyProfile.companyName,
          addressLine1: this.$store.state.resellerData.companyProfile.address.addressLine1,
          addressLine2: this.$store.state.resellerData.companyProfile.address.addressLine2,
          postalCode: this.$store.state.resellerData.companyProfile.address.postalCode,
          city: this.$store.state.resellerData.companyProfile.address.city,
        },
        cotermDate: this.order.customer.cotermDate,
        customerId: this.order.customer.customerId,
        date: moment()
          .tz("Europe/Berlin")
          .format(),
        volumeLevel: volumeLevel,
        consumableLevel: consumableLevel,
        subscriptions: subscriptions,
        sum: sum,
      };

      this.generatePdf(printData);
    },
    generateTable(printData) {
      const taxRate = this.getTaxRate(this.$store.state.resellerData.companyProfile.address.country);

      let result = {
        head: [
          [
            {
              content: this.$t("posNo").toUpperCase(),
              styles: { valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("product").toUpperCase(),
              styles: { valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("price").toUpperCase(),
              styles: { halign: "right", valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("quantity").toUpperCase(),
              styles: { halign: "right", valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("totalPrice").toUpperCase(),
              styles: { halign: "right", valign: "middle", fillColor: "#cccccc" },
            },
          ],
        ],
        foot: [
          [
            "",
            "",
            "",
            this.$t("totalPrice").toUpperCase(),
            {
              content: this.$options.filters.currency(printData.sum),
              styles: { halign: "right" },
            },
          ],
          [
            "",
            "",
            "",
            {
              content: this.$t("vat", {taxRate: (taxRate*100)}).toUpperCase(),
              styles: { fontSize: 9, textColor: "#9b9b9b" },
            },
            {
              content: this.$options.filters.currency(printData.sum * taxRate),
              styles: { fontSize: 9, halign: "right", textColor: "#9b9b9b" },
            },
          ],
          [
            "",
            "",
            "",
            {
              content: this.$t("grossPrice").toUpperCase(),
              styles: { fontSize: 9, textColor: "#9b9b9b" },
            },
            {
              content: this.$options.filters.currency(printData.sum * taxRate + printData.sum),
              styles: { fontSize: 9, halign: "right", textColor: "#9b9b9b" },
            },
          ],
        ],
        body: [],
        startY: 127,
        theme: "plain",
        showHead: "firstPage",
        showFoot: "lastPage",
        styles: {
          font: "helvetica",
          fontSize: 12,
          cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
        },
      };
      if (!this.salesPrice) {
        result.foot[1][3] = ""
        result.foot[1][4] = ""
        result.foot[2][3] = ""
        result.foot[2][4] = ""
      }

      if (this.$i18n.locale === "en") {
        delete result.foot.splice(1, 1);
        delete result.foot.splice(1, 1);
      }

      printData.subscriptions.forEach((subscription, index) => {
        const row = [
          {
            content: index + 1,
            styles: { valign: "middle" },
          },
          `${subscription.itemNumber}\n${subscription.productName}`,
          {
            content: subscription.price,
            styles: { halign: "right", valign: "middle" },
          },
          {
            content: subscription.quantity,
            styles: { halign: "right", valign: "middle" },
          },
          {
            content: subscription.sum,
            styles: { halign: "right", valign: "middle" },
          },
        ];
        result.body.push(row);
      });
      return result;
    },
    generatePdf(printData) {
      const doc = new jsPDF();

      const docLogo = document.querySelectorAll('.imageContainer img')[0];
      const imageSize = {
        width: 78,
        height: 78
      }
      
      doc.addImage(docLogo.src, "png", 120, 5, imageSize.width, imageSize.height);
                                    // Links, Oben, Breite, Höhe

      doc.setFont("Helvetica", "", "normal");

      doc.setFontSize(9);
      doc.setTextColor("#9b9b9b");
      const resellerAdress = `${printData.reseller.companyName}\n${printData.reseller.addressLine1}\n${printData.reseller.postalCode} ${printData.reseller.city}`
      doc.text(doc.splitTextToSize(resellerAdress, 90), 14, 35);

      doc.setFontSize(12);
      doc.setTextColor("#000000");
      const customerAddress = `${printData.customer.companyName}\n${printData.customer.addressLine1}\n${printData.customer.addressLine2}\n${printData.customer.postalCode} ${printData.customer.city}`
      doc.text(doc.splitTextToSize(customerAddress, 90), 14, 50);

      doc.setFontSize(15);
      doc.setFont("Helvetica", "", "bold");
      doc.text(`${this.$t("offerFrom")} ${this.$options.filters.date(printData.date)}`, 14, 97);

      doc.setFontSize(9);
      doc.setFont("Helvetica", "", "normal");
      doc.setTextColor("#9b9b9b");
      doc.text(this.$t("customerId").toUpperCase(), 14, 109);
      doc.text(this.$t("anniversaryDate").toUpperCase(), 54, 109);
      doc.text(this.$t("volumeLevel").toUpperCase(), 89, 109);
      doc.text(this.$t("consumableLevel").toUpperCase(), 139, 109);

      doc.setFontSize(12);
      doc.setTextColor("#000000");
      doc.text(printData.customerId, 14, 115);
      doc.text(this.$options.filters.date(printData.cotermDate), 54, 115);
      doc.text(printData.volumeLevel.toString(), 89, 115);
      doc.text(printData.consumableLevel.toString(), 139, 115);

      doc.autoTable(this.generateTable(printData));

      doc.setFontSize(9);
      const footerText = doc.splitTextToSize(this.$t("renewalOfferFooterText"), 182);
      doc.text(footerText, 14, doc.autoTable.previous.finalY + 12);

      doc.save(`${this.$t("offerFileName")}${printData.customerId.toLowerCase()+(!this.salesPrice ? "-"+this.$t("purchasePrices").toLowerCase() : "")}.pdf`);
    },
  },
  mounted() {
    this.getResellerDataFromCollection()
  }
}
</script>
