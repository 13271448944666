<template>
  <div class="py-4">
    <b-container class="h-100">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col xl="8">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreake="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>
            <b-form v-on:submit.prevent="createAccount">
              <h3>{{ $t("createAccount") }}</h3>
              <p class="text-muted mb-5">
                {{ $t("createAccountText") }}
              </p>
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("emailLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input type="email" v-model="email" readonly />
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("passwordLabel") }}</label>
                </b-col>
                <b-col>
                  <b-popover target="input-password" triggers="hover" placement="top">
                    <template #title>{{ $t("requirements") }}</template>
                    <span v-html="$t('requirementsText')"></span>
                  </b-popover>
                  <b-form-input
                    id="input-password"
                    type="password"
                    v-model="password"
                    :state="states.password"
                    aria-describedby="input-password-feedback"
                    required
                    trim
                  />
                  <b-form-invalid-feedback id="input-password-feedback">
                    {{ $t("passwordInsecure") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row class="mb-5">
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("confirmPassword") }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-password-confirm"
                    type="password"
                    v-model="confirmPassword"
                    :state="states.confirmPassword"
                    aria-describedby="input-password-confirm-feedback"
                    trim
                    required
                  />
                  <b-form-invalid-feedback id="input-password-confirm-feedback">
                    {{ $t("passwordsNotIdentical") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-button variant="primary" type="submit" block :disabled="isSubmitDisabled">
                {{ $t("createAccount") }}
              </b-button>
            </b-form>
          </b-card>
          <div class="d-flex flex-column align-items-center mt-4">
            {{ $t("youAlreadyHaveAnAccount") }}
            <router-link :to="{ name: 'LogInView' }">{{ $t("loginText") }}</router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import isStrongPassword from "validator/es/lib/isStrongPassword";
import equals from "validator/es/lib/equals";
import { auth, firestore, functions } from "../plugins/firebase"
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions";

export default {
  name: "ResellerCreateAccountView",
  components: {
    LogoComponent,
  },
  data() {
    return {
      isWorking: false,
      companyName: "",
      customernumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      states: {
        email: null,
        password: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.isWorking || this.password === "" || this.confirmPassword === "" || !this.states.password || this.states.confirmPassword === false;
    },
  },
  mounted() {
    this.getRegistration();
  },
  methods: {
    async getRegistration() {
      try {
        const docRef = doc(firestore, "registrations", this.$route.params.registrationId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const registrationData = docSnapshot.data();
          this.email = registrationData.account.email;
          this.customernumber = registrationData.account.customernumber;
          this.companyName = registrationData.companyProfile.companyName;
        } else {
          this.$store.dispatch("alertError", {
            message: "Es existiert kein Datensatz, der dieser Kennung entspricht.",
            show: true,
            noFooter: false,
          })
        }
      } catch (error) {
        console.error(error);

        this.$store.dispatch("alertError", {
          message: `
            Es ist ein Fehler aufgetreten.<br />
            Bitte versuchen Sie es später erneut.
          `,
          show: true,
          noFooter: false,
        })
      }
    },
    async createAccount() {
      this.isWorking = true;

      const firebaseAccount = {
        email: this.email,
        password: this.password,
        uid: this.customernumber,
        displayName: this.companyName,
      }

      const createFirebaseAccount = httpsCallable(functions, "createFirebaseAccount");
      const userCredential = await createFirebaseAccount(firebaseAccount)
        .catch((error) => {
          console.error(error);
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });

      const account = {
        registrationId: this.$route.params.registrationId,
        uid: userCredential.data.uid,
      };

      const createMarketplaceAccount = httpsCallable(functions, "createMarketplaceAccount");
      await createMarketplaceAccount(account).catch((error) => {
        this.$store.dispatch("alertError", {
          message: error.message,
          show: true,
          noFooter: false,
        })
      });

      await signInWithEmailAndPassword(auth, this.email, this.password)
        .catch((error) => console.error(error));

      this.isWorking = false;

      this.$router.replace({ name: "HomepageView" })
    },
  },
  watch: {
    password(password) {
      if (password === "") {
        this.states.password = null;
      } else {
        this.states.password = isStrongPassword(password, {
          minLength: 8,
          minLowercase: 2,
          minUppercase: 2,
          minNumbers: 2,
          minSymbols: 0,
        });
      }
    },
    confirmPassword(password) {
      if (password === "") {
        this.states.confirmPassword = null;
      } else {
        this.states.confirmPassword = equals(password, this.password);
      }
    },
  },
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
