<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">{{ $t("transferVipSubscriptions") }}</h3>
      </b-container>
    </div>
    <b-container class="pt-4">
      <div class="text-muted mb-4" v-html="$t('transfersText3', {membershipID: membershipId})"></div>
      <b-form v-on:submit.prevent="submit">
        <b-card no-body footer-class="border-0 bg-light">
          <b-table :items="transferItems" :fields="fields" class="mb-0" sort-icon-left>
            <template #cell(productName)="data">
              {{ getProductName(data.item) | productName }}
            </template>
            <template #cell(renewalDate)="data">
              <fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon>{{ data.item.renewalDate | date }}
            </template>
          </b-table>

          <template #footer>
            <div class="d-flex justify-content-between align-items-center">
              <b-form-checkbox v-model="checkBox">
                <span v-html="$t('transfersCheckBoxText')"></span>
              </b-form-checkbox>

              <submit-button-component
                :state="submitButtonState"
                :label="$t('transferEligibleSubscriptions')"
                :block="false"
                class="my-2"
              ></submit-button-component>
            </div>
          </template>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";

export default {
  name: "TransferSubscriptionsPrevieOffersView",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      isWorking: false,
      products: [],
      transferItems: [],
      checkBox: false,
      fields: [
        {
          key: "productName",
          label: this.$t("subscription"),
          sortable: false,
        },
        {
          key: "renewalDate",
          label: this.$t("anniversaryDate"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$t("quantity"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    async submit() {
      this.isWorking = true;
      if (this.checkBox === false) {
        this.$store.dispatch("alertError", {
          message: "Sie müssen bestätigen, dass sie berechtigt sind diesen Tranfer durchzuführen.",
          show: true,
          noFooter: false,
        });
      } else {
        const transferSubscriptions = httpsCallable(functions, "transferSubscriptions");
        await transferSubscriptions({
          membershipId: this.membershipId,
          resellerId: this.resellerId,
          docId: this.docId,
        })
          .then(() => {
            this.$store.dispatch("alertSuccess", {
              message:
                "Die Subscriptions wurden transferiert.<br /><br />Es kann einige Minuten dauern, bis sie aktiviert wurden. Nach erfolgreicher Aktivierung finden sie unter Kunden einen neuen Datensatz.",
              show: true,
              noFooter: false,
            });
            this.$router.push({ name: "TransfersView" });
          })
          .catch((error) => {
            console.error(error);
            this.$store.dispatch("alertError", {
              message: error.message,
              show: true,
              noFooter: false,
            });
          });
      }
      this.isWorking = false;
    },
    async getProducts() {
      const colRef = collection(firestore, "products");
      const products = await getDocs(colRef);

      if (products.empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });
        return;
      }

      products.forEach((product) => {
        this.products.push(product.data());
      });

      const docRef = doc(firestore, "transfers", this.docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        this.transferItems = docSnapshot.data().items;
      } else {
        this.$store.dispatch("alertError", {
          message: "Transfer wurde nicht gefunden.",
          show: true,
          noFooter: false,
        });
      }
    },
    getProductName(item) {
      const matchingProducts = this.products.filter((product) => item.offerId === product.manufacturerItemNumber);

      if (matchingProducts.length > 0) {
        return matchingProducts[0].productName;
      } else {
        return item.offerId;
      }
    },
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      if (this.checkBox === false) {
        return "missingFields";
      }

      return "";
    },
    membershipId() {
      return this.$route.params.membershipId;
    },
    docId() {
      return this.$route.params.docId;
    },
    resellerId() {
      return this.$store.state.resellerData.resellerId;
    },
  },
};
</script>
