<template>
  <div class="w-100 flex-grow-1">
    <b-container class="py-4">
      <b-row class="justify-content-center">
        <b-col md="8" lg="6" xl="5">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component :fill="'#212529'" :height="'40px'" :width="'40px'" class="mr-3" :lineBreake="true">
                Adobe Cloud Store
              </logo-component>
            </div>
            <div class="text-center">
              <p>Aufgrund von Wartungsarbeiten, ist der Adobe Cloud Store vorübergehend nicht verwendbar.</p>
              Bitte schauen Sie später noch einmal vorbei.
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "./components/LogoComponent.vue";

export default {
  name: "Maintenance",
  components: {
    LogoComponent,
  },
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
