export default {
  imprint: `
    <h3>Impressum</h3>
    <p>
      <strong>Target Distribution GmbH</strong><br />
      Straßenhäuser 59<br />
      A – 6842 Koblach<br />
    </p>
    <p>
      Telefon: +43 5523 54871<br />
      Fax: +43 5523 54873<br />
      E-Mail: info@target-distribution.com
    </p>
    <p>
      Unternehmenswebsite: http://www.target-distribution.com
    </p>
    <p>
      Onlineshop: shop.Target.at
      Email: info@target-distribution.com
    </p>
    <p>
      Sitz: Koblach, Österreich
    </p>
    <p>
      Unternehmensgegenstand:
      Authorisierter Apple Distributor
    </p>
    <p>
      Rechtsform: GmbH
      Geschäftsführer: Gert Furxer
    </p>
    <p>
      Firmenbuchnummer: FN 73 42 8h
    </p>
    <p>
      Firmenbuchgericht: Feldkirch
      UID-Nummer: ATU 365 60 609
    </p>
    <p>
      Kammerangehörigkeit: Wirtschaftskammer Vorarlberg
    </p>
    <p><strong>Haftungsausschluss</strong></p>
    <p><strong>1. Inhalt des Onlineangebotes</strong></p>
    <p>Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.<br>
      Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
    </p>
    <p><strong>2. Verweise und Links</strong></p>
    <p>
      Bei direkten oder indirekten Verweisen auf fremde Webseiten (Hyperlinks), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.</br>
      Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
    </p>
    <p><strong>3. Urheber- und Kennzeichenrecht</strong></p>
      <p>Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen.<br>
      Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!</br>
      Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.
    </p>
    <p><strong>4. Datenschutz</strong></p>
    <p>Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist – soweit technisch möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten.</p>

    <p><strong>5. Rechtswirksamkeit dieses Haftungsausschlusses</strong></p>
    <p>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.</p>

    <p><strong>6. Google Analytics</strong></p>
    <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link (http://tools.google.com/dlpage/gaoptout?hl=de) verfügbare Browser-Plugin herunterladen und installieren.</p>

    <p>Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter http://www.google.com/analytics/terms/de.html bzw. unter https://www.google.de/intl/de/policies/. Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code „anonymizeIp“ erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.</p>
      `,
  adobeTc: `
  <h3>ADOBE VALUE INCENTIVE PLAN – ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h3>
  <p><br> Diese Teilnahmevereinbarung für Unternehmen („Vertrag“) für den Value Incentive Plan („VIP“ oder „Programm“) legt die Bedingungen Ihrer Teilnahme am Programm dar. Der Vertrag gilt ab dem Datum der Online-Anmeldung. Dieser Vertrag wird zwischen Adobe und dem Kunden geschlossen, der in der Online-Anmeldung als Teilnehmer genannt ist. „Adobe“ steht für Adobe Inc., ein Unternehmen nach dem Recht von Delaware mit Geschäftssitz in 345 Park Avenue, San Jose, CA 95110-2704, USA, wenn der Vertrag mit einer Organisation eines Teilnehmers abgeschlossen wird, die in den Vereinigten Staaten, Kanada oder Mexiko ansässig ist, für ADOBE SYSTEMS SOFTWARE IRELAND LIMITED, ein Unternehmen in Irland mit Geschäftssitz in 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Irland, als Bevollmächtigten von Adobe Systems Pty Ltd (ABN 72 054 247 835), wenn der Vertrag mit einer Organisation eines Teilnehmers abgeschlossen wird, die in Australien ansässig ist, oder anderenfalls für ADOBE SYSTEMS SOFTWARE IRELAND LIMITED, ein Unternehmen in Irland mit Geschäftssitz in 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Irland. Der Teilnehmer erwirbt die Lizenz für sämtliche Software, Dienste und Angebote von Adobe U.S., die im Rahmen des Programms (im Folgenden „Produkte“) verfügbar sind und innerhalb der USA (einschließlich US-Territorien und Militärstützpunkte weltweit), Kanada oder Mexiko installiert und verwendet werden. Der Teilnehmer erwirbt die Lizenz für alle Produkte, die in Australien installiert und verwendet werden, von Adobe Ireland, als Bevollmächtigten von Adobe Systems Pty Ltd (ABN 72 054 247 835). Der Teilnehmer erwirbt die Lizenz für sämtliche Produkte außerhalb der USA von Adobe Ireland.</p>
  <h4><br> 1.&nbsp;Beschreibung des Programms.</h4>
  <p><br> <b>1.1&nbsp;<i>Allgemeines und Programmlaufzeit</i></b><i>.</i> Das VIP-Programm ist ein flexibles Lizenzprogramm, das darauf ausgelegt ist, qualifizierten und berechtigten Kunden die Verwaltung und die Bereitstellung von über das Programm erworbenen Produktlizenzen zu ermöglichen. Die Teilnahme am VIP-Programm ist ein Abonnement mit Lizenz-Abonnementlaufzeiten, wie in Abschnitt&nbsp;3.4 dieses Vertrags beschrieben. Hat die Organisation über die Benutzeroberfläche der VIP Administration die Teilnahme beantragt und hat Adobe dieser zugestimmt, ist die Organisation Teilnehmer des Programms („Teilnehmer“) bis (a) zur Kündigung des Programms durch Adobe oder (b) zum Auslaufen dieses Vertrags, wobei das jeweils früher eintretende Ereignis ausschlaggebend ist. Die Teilnahme am Programm unterliegt des Weiteren den im Leitfaden zum Programm enthaltenen Bedingungen, die von Zeit zu Zeit aktualisiert werden können und unter <a href="https://www.adobe.com/go/vip_program_guide_en" target="_blank">www.adobe.com/go/vip_program_guide_de</a> („Leitfaden zum Programm“) abrufbar sind. Der Leitfaden zum Programm wird hiermit durch Bezugnahme Bestandteil des Vertrags. Adobe kann die Programmbedingungen nach eigenem Ermessen ändern. Bei einer Änderung der Programmbedingungen kann es erforderlich sein, dass der Teilnehmer die Programmbedingungen in der Konsole erneut akzeptiert.</p>
  <p><br> <b>1.2&nbsp;<i>Kündigung.</i></b> Ungeachtet der vorstehenden Bestimmungen kann jede Vertragspartei den Vertrag unter Einhaltung einer Kündigungsfrist von dreißig (30) Tagen durch eine ordentliche oder außerordentliche Kündigung kündigen. Die Kündigung dieses Vertrags hat keine Auswirkung auf die Verpflichtungen des Teilnehmers bezüglich der vor dem Kündigungsdatum bestellten Produkte, einschließlich u. a. aller Abonnementbedingungen oder Zahlungsverpflichtungen. Des Weiteren kann Adobe diesen Vertrag bei einer wesentlichen Vertragsverletzung durch schriftliche Benachrichtigung mit sofortiger Wirkung kündigen (einschließlich, aber nicht beschränkt auf Veruntreuung oder Verletzung der Rechte am geistigen Eigentum von Adobe). Bei Kündigung dieses Vertrags muss die Organisation des Teilnehmers die Nutzung der Produkte umgehend einstellen, die Produkte von allen Computersystemen und von der gesamten IT-Ausrüstung, auf denen diese installiert sind, löschen und Datenträger, die die Produkte enthalten, sowie zugehörige Materialien an den Account-Manager des Teilnehmers zurückgeben. Der Account-Manager ist entweder (i) ein Fachhändler, wenn eine Transaktion über einen Fachhändler erfolgt, oder (Ii) ein Adobe-Vertreter, wenn eine Transaktion mit Adobe erfolgt.</p>
  <p>&nbsp;</p>
  <p><b>1.3&nbsp;<i>Nutzungsbedingungen.</i></b> Der Zugang zu den Produkten und deren Verwendung unterliegen den entsprechenden Nutzungsbedingungen von Adobe („Nutzungsbedingungen“), die Sie unter <a href="https://www.adobe.com/legal/terms.html">https://www.adobe.com/de/legal/terms.html</a> einsehen können. Die entsprechenden Nutzungsbedingungen werden hiermit durch Bezugnahme (einschließlich der Bedingungen hinsichtlich des geltenden Rechts und Gerichtsstands) Bestandteil dieses Vertrags. Im Fall eines Konflikts zwischen den Bedingungen dieses Vertrags und den Nutzungsbedingungen haben die Bedingungen dieses Vertrags Vorrang.</p>
  <p><br> <b>1.4&nbsp;<i>Programmprodukte.</i> </b>Eine vollständige Liste der über das Programm erhältlichen Produkte kann der Teilnehmer von seinem Account-Manager beziehen oder auf den Webseiten zum Programm auf Adobe.com unter <a href="http://www.adobe.com/howtobuy/buying-programs/vip.html">http://www.adobe.com/de/howtobuy/buying-programs/vip.html</a> abrufen. Alle Produkte, die durch das Programm erworben wurden, sind ausschließlich zur Verwendung in der Organisation des Teilnehmers bestimmt, und alle Weiterverkäufe und Unterlizenzierungen bzw. eine sonstige Weitergabe sind verboten, wenn nicht anderweitig in Abschnitt&nbsp;4.1 und/oder im Programmleitfaden angegeben. Bestimmte Angebote zum Erwerb einer Lizenz können durch den Kauf von Gutschriften wie im Programmleitfaden beschrieben verfügbar sein.</p>
  <p>&nbsp;</p>
  <p><b>1.5&nbsp;<i>Tochter- und Schwesterorganisationen.</i></b> Adobe bestätigt, dass Tochter- und Schwesterorganisationen des Teilnehmers die im Rahmen des Programms bereitgestellten Produkte bestellen können. Der Kunde bleibt aber letztendlich für etwaige Handlungen oder Unterlassungen einer derartigen Tochter- oder Schwesterorganisation haftbar. „Tochter- oder Schwesterorganisation“ bezeichnet im Zusammenhang mit einem Teilnehmer eine juristische Person, die den Teilnehmer kontrolliert, vom Teilnehmer kontrolliert wird oder mit dem Teilnehmer unter gemeinsamer Kontrolle steht. Im Sinne von Abschnitt 1.5 bezeichnet „Kontrolle“ die direkte oder indirekte Befugnis, die Angelegenheiten der anderen juristischen Person durch den Besitz von mindestens 50&nbsp;% der Aktien, Stimmrechte, Beteiligungen oder Anteile an dieser juristischen Person zu lenken.</p>
  <h4><br> 2.&nbsp;Teilnahme.</h4>
  <p><br> <b>2.1&nbsp;<i>Adobe&nbsp;ID und VIP-ID.</i></b> Zur Anmeldung beim Programm ist eine Adobe&nbsp;ID erforderlich. Jedem Teilnehmer wird eine VIP-ID zugewiesen, die in allen Bestellungen angegeben werden muss. Der Teilnehmer haftet für alle Handlungen und Versäumnisse von Personen, die Zugang zu Produkten erhalten, sowie für die Nutzung der Produkte durch den Teilnehmer.</p>
  <p><br> <b>2.2&nbsp;<i>Konsole.</i></b> Die Benutzeroberfläche für die Programmverwaltung ist die „Admin Console“. Nach Annahme dieses Vertrags wird der Person, die diesen Vertrag im Namen der Organisation angenommen hat, die Rolle des Vertragsverantwortlichen zugewiesen. Der Vertragsverantwortliche kann Systemadministratoren (jeweils ein „Administrator“) hinzufügen. Der Vertragsverantwortliche und jeder Administrator erhalten Zugang zur Admin Console, durch den sie Zugang zum Produkt haben, die Abonnements verwalten und Kontoinformationen anzeigen können. Die Admin Console ermöglicht es dem Administrator, zusätzliche Nutzer in seiner Organisation einzuladen, auf die Admin Console zuzugreifen. Der Teilnehmer erteilt jedem Administrator oder Vertragsverantwortlichen die Erlaubnis, im Namen des Teilnehmers zu handeln.</p>
  <p><br> <b>2.3&nbsp;<i>Vertraulichkeit</i>.</b> Der Teilnehmer muss die VIP-ID vertraulich behandeln und darf diese nicht weitergeben oder offenlegen.</p>
  <p><br> <b>2.4&nbsp;<i>Mitgliedschaft</i></b>. Die Bestimmungen von Anhang B gelten für die Teilnahme von Bildungseinrichtungen, Behörden und gemeinnützigen Organisationen. Der Teilnehmer muss einen gesonderten VIP-Mitgliedsvertrag für alle Produkte verwenden, die für die Nutzung in der Volksrepublik China verfügbar gemacht und bestellt werden.</p>
  <h4><br> 3.&nbsp;Bestellung, Preisinformationen und Ausführung.</h4>
  <p><br> <b>3.1<i>&nbsp;Bestellung und Preise</i>.</b> Der Teilnehmer gibt Produktbestellungen beim Account-Manager auf. Alle Gebühren werden vom Account-Manager des Teilnehmers festgesetzt. Angelegenheiten wie Preise, Lieferung und Zahlungsbedingungen müssen zwischen dem Teilnehmer und dem Account-Manager des Teilnehmers vereinbart werden. Adobe kann keinen bestimmten Preisnachlass garantieren, es sei denn, Adobe ist der Account-Manager des Teilnehmers.</p>
  <p><br> <b>3.2&nbsp;<i>Zugriff, Admin Console-Bereitstellung und Erfüllung.</i></b><i></i> Nach Erwerb der Mitgliedschaft erhält der Administrator des Teilnehmers über die Admin Console Zugang zu den verfügbaren Produkten. Der Teilnehmer kann beliebig viele Produkte aus der Admin Console hinzufügen und sofortigen Zugriff auf solche Produkte erhalten. Adobe muss innerhalb der Nachfrist eine Bestellung für solche Produkte erhalten.&nbsp;</p>
  <p><br> <b>3.3 <i>Nachfrist für Produkte, die in der Admin Console hinzugefügt wurden.</i></b><i></i> Die Nachfrist beträgt 14 Tage nach dem Hinzufügen solcher Produkte. Erhält Adobe innerhalb der Nachfrist keine Bestellung für diese Produkte, dann kann der Teilnehmer keine weiteren Produkte mehr hinzufügen, bis die Bezahlung aller hinzugefügten Produkte erfolgt ist. Der Teilnehmer kann die Anzahl der bereitgestellten Produkte in der Konsole verwalten.</p>
  <p><br> <b>3.4&nbsp;<i>Vertragsjahrestag, Abonnementzeitraum und Verlängerungen.</i></b></p>
  <p>&nbsp;</p>
  <p><b>3.4.1&nbsp;<i>Jahrestag</i>.</b>&nbsp;Sofern von Adobe nicht anders angegeben, ist der Jahrestag des Teilnehmers der Tag zwölf Monate, nachdem Adobe die Erstbestellung vom Teilnehmer angenommen hat („Jahrestag“).</p>
  <p>&nbsp;</p>
  <p><b>3.4.2&nbsp;<i>Abonnementlaufzeit.</i> </b>Die Abonnementlaufzeit bezeichnet den Zeitraum, in dem ein Teilnehmer die Produkte nutzen kann. Sie umfasst die Abonnement-Erstlaufzeit sowie alle etwaigen Verlängerungslaufzeiten. Die Abonnement-Erstlaufzeit bezeichnet den Zeitraum, der mit dem Datum der Erstbestellung des Teilnehmers beginnt und am Tag vor dem Jahrestag endet. Mit der Verlängerung des Produktabonnements beginnt am Jahrestag eine Abonnement-Verlängerungslaufzeit, die bis zum Tag vor dem nächsten Jahrestag läuft. Die Verwendung von Abonnementprodukten und verbundenen Diensten endet gemeinsam mit der Abonnementlaufzeit am Tag vor dem Jahrestag. Die meisten Gutschriften müssen innerhalb einer einzigen Abonnementlaufzeit verwendet werden, und alle nicht genutzten Gutschriften verfallen am letzten Tag der Abonnementlaufzeit. Weitere Informationen finden Sie im Programmleitfaden.</p>
  <p>&nbsp;</p>
  <p><b>3.4.3&nbsp;<i>Abonnementverlängerungen</i>.</b>&nbsp;Adobe bzw. der Account-Manager werden angemessene Anstrengungen unternehmen, um den Teilnehmer vor dem Ende der Abonnementlaufzeit zu benachrichtigen. Abonnements müssen vor dem Jahrestag verlängert werden, um eine kontinuierliche Verwendung der Produkte zu gewährleisten.&nbsp;</p>
  <p><br> <b>3.5&nbsp;<i>Upgrade-Schutz</i>.</b> Der Erwerb von Abonnementprodukten umfasst das Recht auf Upgrades, d.&nbsp;h. der Teilnehmer ist zum Empfang der neuesten allgemein erhältlichen Version eines im Rahmen des Programms erworbenen Abonnementprodukts berechtigt, solange das Produktabonnement zum Zeitpunkt der Markteinführung der neuen Produktversion durch Adobe bezahlt und aktiv ist.</p>
  <p><br> <b>3.6&nbsp;<i>Rückgabe</i>.</b> Unbeschadet eventueller Rechte eines Teilnehmers nach den Verbraucherschutzgesetzen des Landes, in dem der Teilnehmer ansässig ist, kann der Teilnehmer ein einmal installiertes Produkt oder ein Produkt, auf das bereits ein Zugriff erfolgt ist, nicht zurückgeben. Wenn ein Teilnehmer ein Produkt vor der Installation zurückgeben möchte, muss die gesamte Bestellung zurückgegeben werden. Der Teilnehmer muss die Rückgabe von im Rahmen des Vertrags erworbenen Produkten beim Account-Manager des Teilnehmers beantragen. Vorbehaltlich etwaiger Gewährleistungsansprüche müssen Anträge auf Rückgabe innerhalb von vierzehn (14) Tagen nach dem Datum der ursprünglichen Produktbestellung durch den Teilnehmer beim Account-Manager des Teilnehmers beantragt werden. Adobe muss jede beantragte Rückgabe wie im Programmleitfaden näher beschrieben genehmigen, bevor die Rückgabe zulässig ist.</p>
  <p><br> <b>3.7&nbsp;<i>Direktbestellungen bei Adobe</i>. </b>Wenn ein Teilnehmer eine Bestellung direkt bei Adobe aufgibt, gilt dieser Abschnitt. Teilnehmer können jederzeit Produkte über die Admin Console oder einen Vertreter von Adobe hinzufügen, solange eine Bestellung für alle Produkte bei einem Vertreter von Adobe aufgegeben wird. Für Produkte gelten die zum Zeitpunkt der Bestellung aktuellen Preise, und zwar anteilig auf der Grundlage der noch verbleibenden Tage der Abonnementlaufzeit. Ungeachtet des Wortlauts in den Klauseln 3.2, 3.3 und 4.2, kann Adobe Ihnen beim Hinzufügen von Produkten über Admin Console diese Produkte zum dann aktuellen Preis direkt in Rechnung stellen. Für Ihre Bestellung kann eine Prüfung der Kreditwürdigkeit erforderlich sein. Dem Teilnehmer wird der Produktpreis zuzüglich anwendbarer Steuern in Rechnung gestellt. Sämtliche bestellten Produkte müssen vom Teilnehmer innerhalb von 30 Tagen ab Rechnungsdatum gemäß Rechnung in voller Höhe bezahlt werden. Für alle Beträge, die nicht bei Fälligkeit bezahlt werden, fallen ab dem Fälligkeitsdatum bis zum Zeitpunkt, zu dem der überfällige Betrag einschließlich der entsprechenden Zinsen in voller Höhe bezahlt ist, Zinsen zu einem Zinssatz von 1,0&nbsp;% pro Monat oder zum höchsten gesetzlich zulässigen Zinssatz an, je nachdem, welcher Zinssatz niedriger ist.</p>
  <p>&nbsp;</p>
  <h4>4. VIP Marketplace.</h4>
  <p>&nbsp;</p>
  <p><b>4.1</b>.&nbsp; Abschnitt&nbsp;4 „VIP Marketplace“ gilt für Produkte, die über VIP Marketplaces lizenziert wurden.&nbsp; Sofern von Adobe nicht anders angegeben, verlängern sich Abonnements, die über VIP Marketplaces angeboten werden, trotz des Wortlauts in Klausel&nbsp;3.4.3 oben automatisch am Jahrestag des Teilnehmers.&nbsp; Der Teilnehmer kann Produktabonnements gemeinsam mit dem Account-Manager anpassen.</p>
  <p>&nbsp;</p>
  <p><b>4.2 <i>Nachfrist für VIP Marketplace</i>.</b>&nbsp; Ungeachtet des Wortlauts in den Klauseln 3.2 und 3.3 beträgt die Nachfrist für VIP&nbsp;Marketplace-Produkte sieben Tage nach dem Hinzufügen von Produkten und nicht 14 Tage. &nbsp; Erhält Adobe innerhalb der Nachfrist keine Bestellung für Produkte, die über den VIP Marketplace hinzugefügt wurden, werden diese Produkte entfernt.&nbsp;&nbsp;</p>
  <h4>&nbsp;</h4>
  <h4>5.&nbsp;Verschiedenes.</h4>
  <p>&nbsp;</p>
  <p><b>5.1&nbsp;<i>Lizenzübertragung.</i></b> Nutzungsbedingungen von Produkten regeln keine Beschränkungen bezüglich Übertragung von Produkten, die im Rahmen oder in Verbindung mit diesem Vertrag lizenziert werden. Unter gewissen Umständen kann Adobe die Übertragung von Produktlizenzen im Rahmen dieses Vertrags nach eigenem und alleinigem Ermessen gestatten. Anträge auf Lizenzübertragung sind an den Kundendienst von Adobe zu richten, einschließlich einer Beschreibung des Grundes für die geplante Übertragung und der Kontaktinformationen des Übertragungsempfängers. Weitere Informationen finden Sie im Programmleitfaden. Zur Klarstellung wird darauf hingewiesen, dass alle Produkte lizenziert und nicht verkauft werden.</p>
  <p><br> <b>5.2&nbsp;<i>Lizenzüberprüfung.</i></b> Der Teilnehmer muss über Systeme und/oder Verfahren verfügen, die eine ordnungsgemäße Aufzeichnung der Anzahl der installierten und/oder bereitgestellten Produktkopien gewährleisten, und die Aufzeichnungen über die Installation und/oder die Bereitstellung der Produkte müssen bis zwei (2) Jahre nach Kündigung oder Ablauf des Vertrags aufbewahrt werden. Adobe und/oder seine Vertreter sind berechtigt, höchstens einmal im Jahr eine mindestens dreißig (30) Tage im Voraus schriftlich angekündigte Überprüfung der Produktinstallation/Produktbereitstellung beim Teilnehmer durchzuführen. Im Zuge einer derartigen Überprüfung muss der Teilnehmer einen nicht überarbeiteten korrekten Bericht über alle vom Teilnehmer installierten/bereitgestellten Produkte sowie Produkte, auf die der Teilnehmer zugreift, sowie alle gültigen Kaufbelege für die gesamten Produkte innerhalb von dreißig (30) Tagen nach einer entsprechenden Aufforderung vorlegen. Wenn die Überprüfung ergibt, dass die Bestimmungen für die Produktlizenzen nicht eingehalten werden, erwirbt der Teilnehmer die erforderlichen Lizenzen innerhalb von dreißig (30) Tagen nach einer entsprechenden Benachrichtigung. Adobe behält sich ungeachtet des Vorstehenden das Recht vor, die Lizenzinstallation und -bereitstellung durch den Teilnehmer nach schriftlicher Benachrichtigung zehn (10) Werktage im Voraus während der normalen Geschäftszeiten vor Ort zu überprüfen. Dieser Abschnitt 4.2 gilt über die Beendigung dieses Vertrags hinaus für einen Zeitraum von zwei (2) Jahren fort.</p>
  <p><br> <b>5.3&nbsp;<i>Verwendung von Informationen.</i></b> Adobe darf Informationen über den Teilnehmer oder die Tochter- oder Schwesterorganisation einschließlich Name und Kontaktdaten zur Erfüllung vertraglicher Verpflichtungen verwenden. Weitere Informationen finden Sie im Datenschutz-Center von Adobe (<a href="https://www.adobe.com/privacy.html">adobe.com/de/privacy</a>).</p>
  <p><br> <b>5.4&nbsp;<i>Klagebefugnis.</i></b> Die Vertragsparteien vereinbaren, dass das Übereinkommen der Vereinten Nationen über Verträge über den internationalen Warenkauf auf diesen Vertrag keine Anwendung findet. Wenn ein Teilnehmer ein Organ der Bundesregierung der Vereinigten Staaten von Amerika ist, ist der Teilnehmer damit einverstanden, dass Adobe die Klagebefugnis und das Recht hat, jeden Vertragsbruch im Zusammenhang mit diesem Vertrag unter dem Contracts Disputes Act [Gesetz zu Vertragsstreitigkeiten] von 1978 („Gesetz zu Vertragsstreitigkeiten“) geltend zu machen und sich darauf zu berufen.</p>
  <p><br> <b>5.5&nbsp;<i>Allgemeines.</i> </b>Die Vertragsparteien handeln unabhängig voneinander, und dieser Vertrag kann nicht dahingehend ausgelegt werden, dass eine Vertragspartei der Vertreter oder Mitunternehmer der anderen Vertragspartei ist. Der Teilnehmer ist nicht berechtigt, diesen Vertrag (kraft Gesetzes oder auf andere Weise) ohne die vorherige schriftliche Zustimmung von Adobe zu übertragen, und jede unzulässige Übertragung ist null und nichtig. Adobe kann diesen Vertrag nach eigenem Ermessen ohne eine vorherige schriftliche Genehmigung des Teilnehmers übertragen. Dieser Vertrag ist für zulässige Nachfolger bzw. Rechtsnachfolger bindend und kommt diesen zugute. Änderungen bedürfen der Schriftform. Dieser Vertrag (einschließlich des Programmleitfadens, der anwendbaren Nutzungsbedingungen und Anhänge oder ggf. Online-Anmeldeinformationen) ist der vollständige Vertrag zwischen den Vertragsparteien bezüglich des Vertragsgegenstands. Sollte sich eine Bestimmung dieses Vertrags als unwirksam erweisen, bleibt der Rest des Vertrags vollumfänglich in Kraft und wirksam.</p>
  <p><br> Dieser Vertrag wird nur in englischer Sprache aufgesetzt und ausgefertigt. Die englischsprachige Version ist unter allen Umständen rechtskräftig und jede andere Version dieses Vertrags in einer anderen Sprache ist nicht verbindlich und nicht wirksam. Außerdem stimmt jede der Vertragsparteien zu, dass durch eine Unterschrift von einem Teilnehmer oder von Adobe unter eine nicht-englischsprachige Version die Rechtsgültigkeit und Verbindlichkeit der englischsprachigen Version von den Vertragsparteien anerkannt wird, selbst wenn die eigentliche englischsprachige Version nicht unterzeichnet wurde. Ohne Einschränkung der vorherigen Ausführungen hat die englischsprachige Version und ihre Auslegung Rechtsgültigkeit, falls zwischen der englischsprachigen Version dieses Vertrags und jeglicher übersetzten Version dieses Vertrags Widersprüche oder Unvereinbarkeiten bestehen. Alle Ankündigungen oder Benachrichtigungen in Zusammenhang mit diesem Vertrag sowie alle aus diesem Vertrag entstehenden Rechtsstreitigkeiten werden in englischer Sprache ausgeführt oder beigelegt.</p>
  <p><br> <b>5.6&nbsp;<i>Dienste.</i></b> Das Produkt kann mit einer Reihe von Diensten integriert sein, die von Adobe oder Dritten betrieben werden und von Anwendern erstellte Inhalte enthalten können, die (a) für Minderjährige ungeeignet, (b) in einigen Ländern verboten oder (c) zum Betrachten am Arbeitsplatz unpassend sein können. Eine vollständige Liste der integrierten Dienste finden Sie unter <a href="https://www.adobe.com/go/integratedservices">www.adobe.com/go/integratedservices_de</a>. Wenn der Teilnehmer verhindern möchte, dass Dienste mit von Anwendern erstellten Inhalten angezeigt oder aufgerufen werden, kann er (i) den Zugriff auf den Dienst im Creative Cloud Packager deaktivieren, wo diese Funktion verfügbar ist, oder (ii) den Zugang zu den Diensten über seine Netzwerk-Firewall sperren. Dienste sind für Anwender unter dreizehn (13) Jahren unter keinen Umständen verfügbar. Adobe übernimmt keinerlei Haftung für von Anwendern erstellte Inhalte, die über die Dienste verfügbar sind. Der Teilnehmer muss sicherstellen, dass die Verwendung der Dienste den gesetzlichen Vorschriften in der Rechtsordnung des Teilnehmers entspricht. Der Teilnehmer hat keinen Anspruch auf Rückerstattung, und Adobe übernimmt keinerlei Haftung, (i) wenn der Zugang zu den Diensten durch Maßnahmen von Behörden oder des Dienstanbieters verlangsamt oder gesperrt wird oder (ii) wenn Adobe angemessenerweise der Ansicht ist, dass eine derartige Sperre des Zugangs zu einigen oder allen Diensten erforderlich ist.</p>
  <p>&nbsp;</p>
  <h3>ANLAGE B<br> TEILNAHME VON BILDUNGSEINRICHTUNGEN, BEHÖRDEN UND GEMEINNÜTZIGEN ORGANISATIONEN.</h3>
  <p>&nbsp;</p>
  <h4><b>A. TEILNAHME VON BILDUNGSEINRICHTUNGEN.</b></h4>
  <p><br> Für einen Teilnehmer, der eine Bildungseinrichtung ist (siehe Definition unten), gelten die folgenden Zusatzbedingungen. Adobe behält sich das Recht vor, die Teilnahme von Bildungseinrichtungen zu kündigen, wenn der Teilnehmer keine Bildungseinrichtung ist.</p>
  <h4><br>1. Definitionen für Bildungseinrichtungen.</h4>
  <p><br> <b>1.1&nbsp;<i>Bildungseinrichtung.</i></b> Nachstehend folgt eine nicht vollständige Auflistung qualifizierter Bildungseinrichtungen: (a) (von offiziellen Zulassungsstellen) zugelassene öffentliche oder private Grund- oder weiterführende Schulen, die Vollzeitunterricht bieten; (b) zugelassene öffentliche oder private Universitäten oder Hochschulen (einschließlich akademischer Einrichtungen oder Fachhochschulen), die Titel verleihen, für deren Erlangung eine Studienzeit erforderlich ist, die mindestens zwei Jahren Vollzeitstudium entspricht; (c) von Adobe genehmigte, namentlich benannte Bildungseinrichtungen, wenn die benannten Einrichtungen von Adobe schriftlich genehmigt wurden; (d) Krankenhäuser, die sich im Alleineigentum einer anderweitig qualifizierten Bildungseinrichtung befinden und von dieser betrieben werden, wobei „im Alleineigentum befindlich und betrieben“ bedeutet, dass die Bildungseinrichtung der Alleineigentümer des Krankenhauses und die einzige Einrichtung ist, die über den täglichen Betrieb Kontrolle ausübt; und (e) akademische Forschungslabors, die eine öffentliche Einrichtung sind und von einer Bildungsbehörde des Bundes oder eines Bundeslandes anerkannt werden.</p>
  <p><br> Nachstehend folgt eine nicht vollständige Auflistung von Einrichtungen, die keine qualifizierten Bildungseinrichtungen sind: (a) nicht zugelassene Schulen; (b) Museen oder Bibliotheken; (c) Krankenhäuser, die sich nicht im Alleineigentum einer anderweitig qualifizierten Bildungseinrichtung befinden oder nicht von einer solchen betrieben werden; (d) Kirchen oder religiöse Einrichtungen, bei denen es sich nicht um zugelassene Schulen handelt; (e) Einrichtungen der Berufs- und Weiterbildung oder Berufsschulen, die Zeugnisse für Kurse wie Computersoftwareschulung oder berufliche Ausbildung vergeben und keine zugelassenen Schulen sind oder die Titel verleihen, für deren Erlangung eine Studienzeit erforderlich ist, die weniger als zwei Jahren Vollzeitstudium entspricht; (f) Militärschulen, die keine akademischen Titel verleihen, und (g) Forschungslabors, die von keiner Bildungsbehörde des Bundes oder eines Bundeslandes anerkannt werden. Einrichtungen, die von anderen Regierungsinstanzen anerkannt werden, sind beispielsweise nicht teilnahmeberechtigt.</p>
  <p><br> Die obigen Listen gelten nicht für die im unten stehenden Abschnitt 1.2 (Regionenspezifische Definition) aufgeführten Länder.</p>
  <p><br> <b>1.2&nbsp;<i>Regionenspezifische Definition für Bildungseinrichtungen.</i></b></p>
  <p>(a)&nbsp;<i></i>Länder in der Asien-/Pazifikregion mit Ausnahme von südostasiatischen Ländern wie im unten stehenden Absatz (b) definiert. Wenn die Bildungseinrichtung in Australien, Neuseeland, Indien, Sri Lanka, Festlandchina, der Sonderverwaltungszone Hongkong der Volksrepublik China, der Region Taiwan, der Republik Korea, der Volksrepublik Bangladesch, der Demokratischen Bundesrepublik Nepal, der Republik der Union von Myanmar, Pakistan, der Mongolei oder einem anderen von Adobe von Zeit zu Zeit bestimmten Land ansässig ist, hat „Bildungseinrichtung“ die von Adobe für „qualifizierte Bildungseinrichtung“ (mit Ausnahme der Abschnitte „Vollzeit- und Teilzeitlehrkörper und -personal“ sowie „Schüler“) unter <a href="http://www.adobe.com/ap/education/purchasing/qualify.html">http://www.adobe.com/ap/education/purchasing/qualify.html</a> (oder auf der Nachfolge-Website) festgelegte Bedeutung in der von Zeit zu Zeit von Adobe aktualisierten Fassung.</p>
  <p><br> (b)&nbsp;<i>Südostasiatische Länder.</i> Wenn die Bildungseinrichtung in Indonesien, Malaysia, auf den Philippinen, Singapur, Thailand oder Vietnam ansässig ist, hat „Bildungseinrichtung(en)“ die jeweilige von Adobe auf <a disablelinktracking="false" href="https://www.adobe.com/go/edu_entity_sea" target="_blank">https://www.adobe.com/go/edu_entity_sea_de</a>&nbsp;(oder auf der Nachfolge-Website) festgelegte Bedeutung in der von Adobe gelegentlich aktualisierten Fassung.</p>
  <p><br> (c)&nbsp;<i>Japan</i>. Wenn die Bildungseinrichtung in Japan ansässig ist, hat „Bildungseinrichtung“ oder „Bildungsinstitution“ die jeweilige von Adobe unter <a href="https://helpx.adobe.com/jp/x-productkb/policy-pricing/cq081918191.html">https://helpx.adobe.com/jp/x-productkb/policy-pricing/cq081918191.html</a> (oder auf der Nachfolge-Website) festgelegte Bedeutung in der von Zeit zu Zeit von Adobe aktualisierten Fassung.</p>
  <p>&nbsp;</p>
  <p><b>1.3&nbsp;<i>Grund- und weiterführende Schulen.</i></b> Grund- und weiterführende Schulen sind im Programmleitfaden definiert. Adobe kann Teilnehmern, bei denen es sich um Grund- und weiterführende Schulen handelt, Angebote zur Verfügung stellen. Adobe behält sich das Recht vor, Lizenzen und Teilnahmen von Grund- und weiterführenden Schulen zu kündigen, falls der Teilnehmer keine Grund- oder weiterführende Schule ist, wie im Programmleitfaden festgelegt. Weitere Details sind dem Leitfaden zum VIP-Programm für Bildungseinrichtungen zu entnehmen.</p>
  <h4><br> <b>B.&nbsp;TEILNAHME VON BEHÖRDEN.</b></h4>
  <p><br> Für den Teilnehmer, der eine Regierungsinstanz (siehe unten stehende Definition) darstellt, gelten die folgenden zusätzlichen Bedingungen. Adobe behält sich das Recht vor, die Teilnahme von Regierungseinrichtungen zu kündigen, wenn der Teilnehmer keine Regierungseinrichtung ist.</p>
  <h4><br> 1.&nbsp;Für teilnehmende Behörden geltende Definitionen.</h4>
  <p><b><i>Regierungseinrichtung</i>.</b>&nbsp;Eine Teilnahme ist nur dann möglich, wenn der Teilnehmer (und jede Tochter- oder Schwesterorganisation) eine „Behörde“ ist. Unter einer Behörde ist Folgendes zu verstehen: (a) Ämter, Abteilungen, Kommissionen, Dienststellen, Einrichtungen, Räte oder Behörden der Legislative, Exekutive oder Judikative auf Bundes- bzw. nationaler Ebene; (b) Ämter, Abteilungen, Kommissionen, Dienststellen, Einrichtungen, Räte, Organisationen oder Behörden der Legislative, Exekutive oder Judikative auf regionaler und kommunaler Ebene, die aufgrund der Verfassung oder Gesetze des jeweiligen Staates bestehen, und zwar einschließlich der Verwaltungsstellen von Bezirken, Regionen und Bundesländern oder (c) von bundesstaatlichen, einzelstaatlichen oder lokalen Regierungen errichtete und/oder finanzierte staatliche Stellen oder Einrichtungen, die berechtigt sind, Regierungsgewalt über Bürger, Unternehmen oder andere Behörden auszuüben oder diese zu unterstützen. Zur Klarstellung wird darauf hingewiesen, dass „gewinnorientierte“ privatwirtschaftliche Unternehmen, gemeinnützige Organisationen, Berufs- und Wirtschaftsverbände sowie Gewerkschaften nicht teilnahmeberechtigt sind. Des Weiteren ausgeschlossen sind private Organisationen, die im Auftrag von oder gemeinsam mit Behörden Aufträge ausführen. Der Teilnehmer erklärt gegenüber Adobe, dass es sich beim Teilnehmer und seinen Tochter- und Schwesterorganisationen um Regierungsinstanzen handelt. Eine Liste qualifizierter „Behörden“ für Japan ist abrufbar unter:&nbsp;<a href="http://www.adobe.com/jp/aboutadobe/volumelicensing/pdfs/cl5_government_license_table.pdf" target="_blank">http://www.adobe.com/jp/aboutadobe/volumelicensing/pdfs/cl5_government_license_table.pdf</a>.</p>
  <p><br> <b>Für Frankreich:</b> Eine Behörde ist entweder ein Amt, ein Minister, eine Kommission, ein Vorstand, eine Dienststelle oder ein Rat (auf nationaler, regionaler oder lokaler Ebene); eine Stadt; eine Region oder eine Stelle, die dem öffentlichen Recht von Frankreich und der Verwaltung einer Regierungsstelle unterliegt.</p>
  <h4><br> 2.&nbsp;Für teilnehmende Behörden geltende Bedingungen.</h4>
  <p><br> <b>2.1&nbsp;<i>Zusätzliche Einschränkungen</i>.</b> Für die US-Bundesregierung als Teilnehmer gilt, dass alle Bestellungen FAR&nbsp;52.232-18 (Verfügbarkeit von Mitteln) und FAR&nbsp;52.232-19 (Verfügbarkeit von Mitteln für das nachfolgende Fiskaljahr) unterliegen und die US-Bundesregierung als Teilnehmer kein Produkt bereitstellen wird, sofern nicht die entsprechenden Mittel zur Verfügung stehen. Soweit staatliche oder lokale Behörden ähnlichen Anforderungen unterliegen, dürfen diese Einrichtungen keine Produkte bereitstellen, wenn sie nicht über die entsprechenden Mittel verfügen.</p>
  <p><br> <b>2.2&nbsp;<i>Kündigung</i>.</b>Dieser Vertrag kann von einem Teilnehmer, der der US-Bundesregierung angehört, gemäß FAR 52.249-1 (Ordentliche Kündigung durch die Regierungsbehörde) gekündigt werden. Adobe kann die Vertragsbedingungen nach eigenem Ermessen ändern.</p>
  <p><br> <b>2.3&nbsp;<i>US-Bundesbehörden als Mitglieder</i>.</b> Hinweis für US-Bundesbehörden als Endanwender (Handelsartikel): Die vertragsgemäß bereitgestellten Produkte sind „Handelsware(n)“ im Sinne von 48 C.F.R. § 2.101, bestehend aus „kommerzieller Computer-Software“ und „Begleitmaterial für kommerzielle Computer-Software“ und damit verbundenen Diensten im Sinne von 48 C.F.R. § 12.212 bzw. 48 C.F.R. § 227.7202, wie jeweils anwendbar. In Übereinstimmung mit 48 C.F.R. § 12.212 oder 48 C.F.R. §§ 227.7202-1 bis 227.7202-4, soweit anwendbar, wird US-amerikanischen Bundesbehörden als Endbenutzer eine Lizenz über handelsübliche Computersoftware und die Dokumentation zu handelsüblicher Computer-Software zur Verfügung gestellt, und zwar (a) ausschließlich als Handelsartikel und (b) ausschließlich mit solchen Rechten versehen zur Verfügung gestellt, die alle anderen Endbenutzer gemäß den hier aufgeführten Vertragsbestimmungen und den Nutzungsbedingungen erhalten. Unveröffentlichte Rechte bleiben gemäß den Gesetzen der Vereinigten Staaten von Amerika Adobe Inc., 345 Park Avenue, San Jose, CA 95110-2704, USA, vorbehalten.</p>
  <h4><br> C. GEMEINNÜTZIGE ORGANISATIONEN.</h4>
  <p><br> Weitere Bedingungen, die für gemeinnützige Teilnehmer gelten, sind im Programmleitfaden enthalten. Adobe behält sich das Recht vor, Mitgliedschaften zu kündigen, falls der Teilnehmer keine teilnahmeberechtigte gemeinnützige Organisation ist, wie in <a href="https://helpx.adobe.com/buying-programs/non-profit.html">https://helpx.adobe.com/de/buying-programs/non-profit.html</a> beschrieben.<br>
  </p>
  `,
  comlineTc:`
  <ol class="agb">
    <li>
      <strong>Allgemeines</strong>
      <ol>
        <li>
          Die nachfolgenden Allgemeinen Geschäftsbedingungen sind Bestandteil jedes von uns abgeschlossenen Vertrages.
        </li>
        <li>
          Etwaige Einkaufsbedingungen des Käufers gelten nicht, es sei denn, sie werden von uns ausdrücklich schriftlich anerkannt.
        </li>
        <li>
          Für die gesamten Rechtsbeziehungen mit dem Käufer gilt ausschließlich deutsches Recht. Die Bestimmungen des UN–Kaufrechts (CISG) gelten im Verhältnis zwischen uns und dem Käufer nicht.
        </li>
        <li>
          Diese Bedingungen gelten für den Rechtsverkehr mit Unternehmern. Wir beliefern ausschließlich Kunden, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbstständigen beruflichen Tätigkeit handeln sowie juristische Personen des öffentlichen Rechts und öffentlich–rechtliche Sondervermögen (beidseitige Handelsgeschäfte).
        </li>
        <li>
          Als Erfüllungsort für Lieferung und Zahlung sowie als Gerichtsstand wird Flensburg vereinbart, mit der Maßgabe, dass wir berechtigt sind, auch am Ort des Sitzes oder einer Niederlassung des Käufers zu klagen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Angebote, Vertragsabschluß, Preise</strong>
      <ol>
        <li>
          Unsere Angebote sind nach Menge, Preis und Lieferzeit frei bleibend. Mit seiner Bestellung erklärt der Käufer verbindlich, die von ihm bestellte Ware erwerben zu wollen. Die zum Vertragsabschluß führende Annahme kann durch die Auslieferung der Ware oder durch die Erbringung der Dienstleistung oder dadurch erklärt werden, dass wir dem Käufer in sonstiger Weise die Annahme seiner Beststellung bestätigen. Mit der Annahme durch uns ist der Vertrag zustande gekommen.
        </li>
        <li>
          Ein Widerrufsrecht steht Unternehmern und folglich dem Käufer nicht zu.
        </li>
        <li>
          Technische und gestalterische Abweichungen von Beschreibungen und Angaben in Unterlagen, sowie Modell–, Konstruktions– und Materialänderungen im Zuge des technischen Fortschritts bleiben vorbehalten, ohne dass hieraus Rechte gegen uns hergeleitet werden können.
        </li>
        <li>
          Unsere Preise sind Nettopreise. Sofern sich aus unserer Auftragsbestätigung nichts anderes ergibt, gelten sie  ab Lager Flensburg oder bei Direktversand ab deutscher Grenze bzw. deutschem Einfuhrhafen zuzüglich der am Liefertage geltenden Mehrwertsteuer, ausschließlich Verpackung, Versicherung, Fracht und ggf. Mindermengenzuschlag. Diese Positionen werden gesondert in Rechnung gestellt. Die Entsorgung der Verpackung übernimmt der Kunde auf eigene Kosten.
        </li>
      </ol>
    </li>
    <li>
      <strong>Versand</strong>
      <ol>
        <li>
          Die  Abholung  des  Liefergegenstandes hat grundsätzlich durch  den  Käufer und unverzüglich  nach  Anzeige der Bereitstellung im Werk oder Lager zu erfolgen.
        </li>
        <li>
          Soweit die Lieferung durch uns gewünscht wird, erfolgt sie grundsätzlich ab Lager und – soweit nicht im Einzelfall anders vereinbart – auf Kosten des Käufers. Wird der Liefergegenstand auf Wunsch des Käufers an diesen versandt, so geht die Gefahr des zufälligen Unterganges und der zufälligen Verschlechterung des Liefergegenstandes mit der Übergabe des Liefergegenstandes an den Spediteur, Frachtführer, Versandbeauftragten oder Abholer auf den Käufer über. Das gilt auch dann, wenn die Frachtkosten aufgrund individueller Vereinbarung von uns getragen werden oder wir den Versand durch eine Transportperson selbst durchführen.
        </li>
        <li>
          Kommt  der  Käufer  in  Annahmeverzug,  so sind wir berechtigt,  Ersatz  der uns entstehenden Aufwendungen   zu   verlangen. Mit   Eintritt   des   Annahmeverzuges   geht   die   Gefahr   der   zufälligen Verschlechterung und des zufälligen Untergangs auf den Käufer über. 
        </li> 
        <li> 
          Verzögert sich der Versand aus Gründen, die wir nicht zu vertreten haben, geht die Gefahr mit der Anzeige der Versandbereitschaft auf den Käufer über.
        </li>
      </ol>
    </li>
    <li>
      <strong>Lieferung, Rücktritt</strong>
      <ol>
        <li>
          Angaben zum Liefertermin sind unsererseits unverbindlich und stellen lediglich eine unverbindliche Schätzung dar. Die Vereinbarung fester Liefertermine bedarf der Schriftform.
        </li>
        <li>
          Der Vertragsabschluß erfolgt unter dem Vorbehalt richtiger und rechtzeitiger Selbstbelieferung. Wir sind daher zum Rücktritt berechtigt, wenn unser Lieferant den mit uns vor Abschluss des jeweiligen Verkaufsvertrages geschlossenen Einkaufsvertrag aus von uns nicht vertretenden Gründen nicht erfüllt.
        </li>
        <li>
          Darüber hinaus sind wir berechtigt, von geschlossenen Verträgen zurückzutreten, wenn sich infolge von Katastrophen, Kriegsereignissen oder ähnlichen Umständen die Warenbeschaffung gegenüber dem Zeitpunkt des Vertragsabschlusses wesentliche erschwert. Als eine wesentliche Erschwerung gilt es in jedem Falle, wenn der Marktpreis des Kaufgegenstandes zwischen dem Abschluss des jeweiligen Verkaufsvertrages und dem vorgesehenen Liefertermin um 25% oder mehr gestiegen ist.
        </li>
        <li>
          Bei von uns nicht zu vertretenden Störungen in unserem Betrieb oder Lager sowie bei behindernden behördlichen Maßnahmen wird die Lieferfrist um die Dauer der Störung verlängert. Darüber hinaus sind wir berechtigt, von abgeschlossenen Verträgen zurückzutreten, wenn die Störung ohne unser Verschulden über eine Zeitraum von mehr als vier Wochen andauert. Störung im vorgenannten Sinne schließt auch solche Betriebsunterbrechungen oder –einschränkungen ein, die durch Personalausfall größeren Umfangs infolge von Krankheiten, Arbeitskampfmaßnahmen oder ähnlichem verursacht werden.
        </li>
      </ol>
    </li>
    <li>
      <strong>Abnahme und Übernahme, Untersuchung, Mängelrüge</strong>
      <ol>
        <li>
          Der Käufer ist verpflichtet, auch Teillieferungen entgegenzunehmen. Teillieferungen können sofort in Rechnung gestellt werden.
        </li>
        <li>
          Bei Lieferungen auf Abruf stellt der Abruf innerhalb der vereinbarten Frist eine Hauptpflicht dar.
        </li>
        <li>
          Liefergegenstände   sind,   auch   wenn   sie   unwesentliche   Mängel   aufweisen,   vom   Kunden unbeschadet des Bestehens etwaiger Gewährleistungsansprüche entgegenzunehmen. 
        </li>
        <li>
          Der Käufer ist verpflichtet, den Liefergegenstand bei Übergabe auf etwaige Mängel zu untersuchen und uns solche  unverzüglich  schriftlich  anzuzeigen.  Die  einschlägigen  Regelungen  und Rechtsfolgen des HGB gelten entsprechend.  
        </li>
      </ol>
    </li>
    <li>
      <strong>Eigentumsvorbehalt</strong>
      <ol>
        <li>
          Bis zur vollständigen Bezahlung unserer Kaufpreisforderung sowie aller anderen uns gegen den Käufer zustehenden Forderungen bleibt die gelieferte Ware unser Eigentum. Der Eigentumsvorbehalt bleibt auch dann bestehen, wenn einzelne Forderungen in eine laufende Rechnung aufgenommen werden und der Saldo gezogen und anerkannt ist.
        </li>
        <li>
          Wird die von uns gelieferte Vorbehaltsware von dem Käufer be– oder verarbeitet, so erfolgt die Be– und Verarbeitung für uns als "Hersteller" im Sinne des § 950 BGB.
        </li>
        <li>
          Wird unsere Vorbehaltsware mit eigener Ware des Käufers oder mit fremder Vorbehaltsware verbunden, vermischt oder zusammen mit solcher Ware verarbeitet, so erwerben wir das Miteigentum ab der neuen Sache oder an dem vermischten Bestand im Verhältnis des Wertes unserer Vorbehaltsware zu der anderen Ware zu der Zeit der Verbindung, Vermischung oder Verarbeitung. Auf die durch Verbindung, Vermischung oder Verarbeitung herbeigeführte Wertsteigerung erheben wir keinen Anspruch.
        </li>
        <li>
          <ol>
            <li>
              Der Käufer tritt seine Forderung mit allen Nebenrechten aus dem Weiterverkauf unserer Vorbehaltsware sowie gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehenden Ware zur Sicherheit für alle uns im Zeitpunkt der Weiterveräußerung gegen den Käufer zustehenden Ansprüche bereits jetzt an uns ab.
            </li>
            <li>
              Im Falle der Weiterveräußerung der Ware, die gemäß Ziffer 6.3 in unserem Miteigentum steht, gilt als abgetreten jedoch nur der Teil der Forderung, der dem Wert unseres Miteigentumsanteils entspricht.
            </li>
            <li>
              Hat der Käufer die Forderung aus dem Weiterverkauf im Rahmen des echten Factoring verkauft, so tritt er die an ihre Stelle tretende Forderung gegen den Factor an uns ab.
            </li>
            <li>
              Die vorstehenden Abtretungen nehmen wir hiermit ausdrücklich an.
            </li>
            <li>
              Übersteigt der Wert der uns zur Sicherheit abgetretenen Forderungen unsere Ansprüche gegen den Käufer um mehr als 20%, so sind wir auf Verlangen des Käufers verpflichtet, darüber hinaus bestehende Sicherheiten freizugeben.
            </li>
          </ol>
        </li>
        <li>
          Der Käufer ist zur Weiterveräußerung unserer Vorbehaltsware sowie der gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehende Waren nur im Rahmen seines gewöhnlichen Geschäftsverkehrs und nur unter der Voraussetzung berechtigt, dass die Kaufpreisforderung aus dem Weiterverkauf gemäß Ziffer 6.4 auf uns übergeht.
        </li>
        <li>
          Der Käufer ist verpflichtet, unsere Vorbehaltsware sowie die gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehende Ware gegen Verlust und Beschädigung aufgrund Feuer, Diebstahl, Wasser und ähnlicher Gefahren ausreichend zu versichern und uns auf Verlangen den Versicherungsschutz nachzuweisen. Der Käufer tritt hiermit seine Entschädigungsansprüche, die ihm gegen Versicherungsgesellschaften oder sonstige Ersatzverpflichtete zustehen – gegebenenfalls anteilig – an uns ab. Auch die vorstehende Abtretung wird hiermit angenommen.
        </li>
        <li>
          Irgendeine Beeinträchtigung unserer Vorbehaltsware sowie der gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehende Ware ist uns ebenso unverzüglich bekanntzugeben, wie Zugriffe dritter darauf.
        </li>
        <li>
          Nehmen wir aufgrund des Eigentumsvorbehalts den Kaufgegenstand zurück, so gilt das nicht als Rücktritt vom Vertrag. Wir können uns aus der zurückgenommenen Vorbehaltsware durch freihändigen Verkauf befriedigen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Zahlung</strong>
      <ol>
        <li>
          Die Forderungen aus unseren Rechnungen sind nach unserer Wahl, sofern nichts anderes vereinbart ist, per Vorauskasse, per Bargeldnachnahme oder per SEPA–Firmenlastschrift zahlbar. Unbeschadet einer einzelvertraglichen Ware sofort fällig bei Abholung bzw. Lieferung.
        </li>
        <li>
          Wir sind berechtigt, trotz anderslautender Zahlungsbestimmung des Käufers, Zahlungen zunächst auf dessen älteste Schuld anzurechnen. Sind bereits Kosten und Zinsen entstanden, so sind wir berechtigt, die Zahlung zunächst auf die Kosten, dann auf die Zinsen und zuletzt auf die Hauptforderung anzurechnen.
        </li>
        <li> 
          Der Käufer kommt auch ohne Mahnung in Verzug, wenn er nach Ablauf von 30 Tagen nach Zugang der Rechnung nicht gezahlt hat. Mit Zugang einer Mahnung tritt Verzug gegebenenfalls auch vor Ablauf von 30 Tagen nach Zugang der Rechnung ein. Verzugszinsen werden in Höhe von 8 Prozentpunkten über dem jeweiligen Basiszinssatz berechnet. Die Geltendmachung eines höheren Zinsschadens bleibt vorbehalten.
        </li>
        <li>
          Kommt der Käufer seinen Zahlungsverpflichtungen nicht vertragsgemäß nach, oder stellt er seine Zahlungen ein oder werden uns andere Umstände bekannt, die die Kreditwürdigkeit des Käufers in Frage stellen, so sind wir berechtigt, die gesamte Restschuld fällig zu stellen. § 321 BGB findet mit der Maßgabe Anwendung, dass uns die dort vorgesehene Einrede auch dann zusteht, wenn die Vermögenslage des Käufers bereits bei Vertragsschluß schlecht war, dies uns jedoch nicht bekannt gewesen ist.
        </li>
        <li>
          Der Käufer kann Zurückbehaltungsrechte gegenüber unseren Forderungen nicht geltend machen. Ferner ist die Aufrechnung gegenüber unseren Forderungen ausgeschlossen, es sei denn, die Gegenforderung ist unbestritten oder rechtskräftig festgestellt. 
        </li>
      </ol>
    </li>
    <li>
      <strong>Gewährleistung</strong>
      <ol>
        <li>
          Angaben zu unseren Waren sind generell reine Beschaffenheitsangaben, es sei denn, sie werden ausdrücklich als zugesicherte Eigenschaften bezeichnet.
        </li>
        <li>
          Übernommene oder gelieferte Waren sind unverzüglich vom Käufer auf vorhandene Mängel zu überprüfen. Sind solche vorhanden, sind sie unverzüglich schriftlich uns gegenüber anzuzeigen. Im Übrigen gelten die § 377 ff. HGB. Daneben sind Gewährleistungsansprüche generell ausgeschlossen, wenn infolge von Weiterversand oder Be– bzw. Verarbeitung der von uns gelieferten Ware oder anderer Umstände unsererseits nicht mehr einwandfrei geprüft und festgestellt werden kann, ob ein Mangel der Ware tatsächlich vorliegt.
        </li>
        <li>
          Gewährleistungsansprüche verjähren binnen 12 Monaten, beginnend mit Übergabe der Sache.
        </li>
        <li>
          Die Gewährleistung beim Verkauf gebrauchter Gegenstände ist generell ausgeschlossen.
        </li>
        <li>
          Nimmt uns der Käufer auf Gewährleistung in Anspruch, und stellt sich heraus, dass ein Gewährleistungsanspruch nicht besteht (zum Beispiel Anwenderfehler, unsachgemäße Behandlung des Kaufgegenstandes, Nichtbestehen eines Mangels), so hat uns der Käufer alle im Zusammenhang mit der Überprüfung des Kaufgegenstandes entstehenden Kosten zu ersetzen.
        </li>
        <li>
          Soweit der Hersteller für die gelieferte Ware eine freiwillige Garantie gegenüber dem Käufer gewährt, richten sich Art und Umfang der Garantieleistungen ausschließlich nach dem Inhalt der Herstellergarantie. Aus dieser Garantie kann – soweit diese Garantie über die gesetzlichen Gewährleistungsrechte hinaus geht – ausschließlich der Hersteller in Anspruch genommen werden.
        </li>
      </ol>
    </li>
    <li>
      <strong>Haftung</strong>
      <ol>
        <li>
          <ol>
            <li>
              In allen Fällen, in denen wir im Geschäftsverkehr aufgrund vertraglicher oder gesetzlicher Anspruchsgrundlagen zum Schadens– oder Aufwendungsersatz verpflichtet sind, haften wir nur, soweit uns, unseren Organen, gesetzlichen Vertretern oder Erfüllungsgehilfen Vorsatz oder grobe Fahrlässigkeit zur Last fällt. 
            </li>
            <li>
              Unberührt hiervon bleibt die Haftung für die schuldhafte Verletzung wesentlicher Vertragspflichten. Wesentliche Vertragspflichten sind solche, die den Vertragsparteien die Rechte zubilligen, die der Vertrag nach seinem Inhalt und Zweck gerade zu  gewähren  hat,  insbesondere  die  Pflichten,  deren  Erfüllung  die  ordnungsgemäße  Durchführung  des Vertrags überhaupt erst ermöglichen und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und vertrauen darf. 
            </li>
            <li>
              Soweit eine zurechenbare Pflichtverletzung auf einfacher Fahrlässigkeit beruht und eine wesentliche Vertragspflicht schuldhaft verletzt ist, ist unsere  Schadensersatzhaftung jedoch auf den vorhersehbaren Schaden beschränkt, der typischerweise in vergleichbaren Fällen eintritt. 
            </li>
            <li>
              Die vorstehenden Haftungsausschlüsse gelten nicht für   den   Fall   der   Tötung,   der   Verletzung   der   Gesundheit   oder   des   Körpers,   für garantierte Beschaffenheitsmerkmale und   im   Fall   einer etwaigen   Haftung   nach   dem   Produkthaftungsgesetz. In diesen Fällen haften wir nach den gesetzlichen Bestimmungen. 
            </li>
          </ol>
        </li>
        <li>
          Im Übrigen ist jegliche Haftung unsererseits ausgeschlossen.
        </li>
        <li>
          Die vorstehenden Haftungsausschlüsse und –beschränkungen gelten in gleichem Umfang zugunsten unserer Organe, gesetzlichen Vertreter, Angestellten und sonstigen Erfüllungsgehilfen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Schutz– oder Urheberrechte</strong>
      <ol>
        <li>
          Der Käufer wird uns unverzüglich und schriftlich unterrichten, falls er auf die Verletzung von Schutz– oder Urheberrechten durch ein von uns geliefertes Produkt hingewiesen wird. Wir sind allein berechtigt und verpflichtet, den Käufer gegen die Ansprüche des Inhabers derartiger Rechte zu verteidigen und diese Ansprüche auf eigene Kosten zu regeln, soweit sie auf die unmittelbare Verletzung durch ein von uns geliefertes Produkt gestützt sind. Grundsätzlich werden wir uns bemühen, dem Käufer das Recht zur Benutzung zu verschaffen. Falls uns dies zu wirtschaftlich angemessenen Bedingungen nicht möglich ist, werden wir nach eigener Wahl dieses Produkt derart abändern oder ersetzen, dass das Schutzrecht nicht verletzt wird oder das Produkt zurücknehmen und den Kaufpreis abzüglich eines etwaigen Betrages für die gewährte Nutzungsmöglichkeit erstatten.
        </li>
        <li>
          Umgekehrt wird der Käufer uns gegenüber allen Ansprüchen des Inhabers derartiger Rechte verteidigen bzw. freistellen, welche gegen uns dadurch entstehen, dass wir Instruktionen des Käufers befolgt haben oder der Käufer das Produkt ändert oder in das System integriert.
        </li>
        <li>
          Von uns zur Verfügung gestellte Programme und dazugehörige Dokumentationen sind nur für den eigenen Gebrauch des Käufers im Rahmen einer einfachen, nicht übertragbaren Lizenz bestimmt, und zwar ausschließlich auf von uns gelieferten Produkten. Der Käufer darf diese Programme und Dokumentationen ohne unsere schriftliche Einwilligung Dritten nicht zugänglich machen, auch nicht bei Weiterveräußerung unserer Hardware. Kopien dürfen – ohne Übernahme von Kosten und Haftung durch uns – lediglich für Archivzwecke, als Ersatz oder zur Fehlersuche angefertigt werden. Sofern Originale einen auf Urheberschutz hinweisenden Vermerk tragen, ist dieser vom Kunden auch auf Kopien anzubringen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Export</strong>
      <ol>
        <li>
          Der Export unserer Waren in Nicht–EU–Länder bedarf unserer schriftlichen Einwilligung, unabhängig davon, dass der Käufer für das Einholen jeglicher behördlicher Ein– und Ausfuhrgenehmigungen selbst zu sorgen hat.
        </li>
      </ol>
    </li>
  </ol>
  `,
  privacyProtection: `
  <p><b>Verantwortlicher</b> für die Datenverarbeitung ist</p>
<p><b>Target Distribution GmbH (FN 73428 h – LG Feldkirch)<br></b>z.H. Datenschutz<br>Straßenhäuser 59 | 6842
	Koblach<br>Telefon +43 5523 54871-0 | Telefax: +43 5523 54873</p>
<p>E-Mail: <a href="mailto:datenschutz@target-distribution.com"><span
				class="s2">datenschutz@target-distribution.com</a> | Web: <a
			href="http://www.target-distribution.com">www.target-distribution.com</a></p>
<p>Wir haben <b>keinen Datenschutzbeauftragten</b> bestellt, da dieser gesetzlich für uns nicht
	erforderlich ist.</p>
<p><b>I. Verarbeitung personenbezogener Daten<br></b>Wir verarbeiten Ihre personenbezogenen Daten, die unter folgende
	Datenkategorien fallen:</p>
<p>Kontaktdaten, Vertragsdaten, Verrechnungsdaten, Bonitätsdaten, Bestelldaten, Zahlungsverkehrsdaten
	(Bankdaten, Kreditkartendaten), Versand- und Lieferdaten</p>
<p><b>I.1 Unsere Rechtsgrundlagen sind</b></p>
<ul>
	<li>Geschäftspartner: Vertragserfüllung, rechtliche Verpflichtung, berechtigtes Interesse</li>
	<li>Kundendaten: Vertragserfüllung, rechtliche Verpflichtung, berechtigtes Interesse</li>
	<li>Interessenten: Vertragserfüllung, berechtigtes Interesse, Einwilligung</li>
	<li>Videoüberwachung: Vertragserfüllung, berechtigtes Interesse (Schutz)</li>
</ul>
<p><b>I.2 Einwilligung<br></b>Sie haben uns Daten über sich freiwillig zur Verfügung
	gestellt und wir verarbeiten diese Daten auf Grundlage Ihrer Einwilligung zu folgenden Zwecken:</p>
<p>Information über unsere Produkte, Werbung, Newsletter, Veranstaltungen, Firmenbeiträge auf Social Media
</p>
<p>Sie können diese Einwilligung jederzeit widerrufen. Ein Widerruf hat zur Folge, dass wir Ihre Daten ab
	diesem Zeitpunkt zu oben genannten Zwecken nicht mehr verarbeiten.</p>
<p><b>I.3 Vertrag<br></b>Die von Ihnen bereitgestellten Daten sind zur Vertragserfüllung bzw. Zur Durchführung
	vorvertraglicher Maßnahmen erforderlich. Das sind:</p>
<p>Die Erstellung von Offerten, die Abwicklung von Aufträgen und Bestellungen, die Zustellung der Waren,
	die Bearbeitung von Reklamationen, die Abwicklung von Gewährleistung oder Garantie, die Schadenabwicklung, die An-
	und Abmeldung von KFZ, die Hinterlegung von Kennzeichen, die Wahrung Ihrer rechtlichen Interessen gegenüber Dritten,
	die Abwicklung von Zahlungen mittels Bank oder Kreditkarten</p>
<p>Ohne diese Daten können den Vertrag mit Ihnen nicht erfüllen</p>
<p><b>I.4 Gesetzliche Verpflichtung<br></b>Wir müssen Ihre Daten, die wir von Ihnen erhalten haben, aufgrund einer
	gesetzlichen Verpflichtung verarbeiten.</p>
<p>Das sind steuer- und abgabenrechtliche Vorschriften, arbeits- und sozialrechtliche Vorschriften,
	Zollvorschriften (U34), etc., um die gesetzlich erforderlichen Nachweise zu erbringen.</p>
<p><b>I.5 Berechtige Interessen<br></b>Wir verarbeiten Daten über Sie aufgrund unserer berechtigten Interessen oder
	denen eines Dritten. Diese Interessen bestehen in:</p>
<p>Der Anbahnung von Geschäftsabschlüssen, der Dokumentation der Geschäftsfälle, der Information über von
	uns angebotene Produkte und Dienstleistungen, Veranstaltungen, Aktionen, Newsletter. Zu diesem Zweck können auch
	Daten an Dritte übermittelt werden, falls dies für die Durchführung der erwähnten oder anderer Marketingmaßnahmen,
	statistische Auswertungen etc. erforderlich ist bzw. für die interne Verwaltung in der Unternehmensgruppe</p>
<p><b>II. Speicherdauer / Löschfrist<br></b>Wir speichern Ihre Daten für die Dauer der Geschäftsbeziehung und darüber
	hinaus im Rahmen der jeweils zur Anwendung gelangenden gesetzlichen Aufbewahrungs- und Dokumentationspflichten.</p>
<p><b>III. Auftragsverarbeiter<br></b>Zur Erfüllung unserer Verpflichtungen arbeiten wir auch mit Auftragsverarbeitern
	zusammen. Mit diesen wurden Auftragsverarbeitungsverträge gemäß Art. 28 DSGVO abgeschlossen. Nähere Informationen zu
	den von uns beauftragten Auftragsverarbeitern können Sie unter der E-Mail-Adresse <a
		href="mailto:datenschutz@target-distribution.com">datenschutz@target-distribution.com</a>
	anfragen. Im Wesentlichen geben wir Ihre Daten an folgende Empfänger weiter:</p>
<p>Steuerberater und Wirtschaftsprüfer, Banken, Lieferanten und Hersteller, Unternehmensgruppe und
	Versicherungen</p>
<p><b>IV. Datenübermittlung<br></b>Wir geben Ihre Daten an folgende Empfänger bzw. Empfängerkategorien weiter</p>
<ul>
	<li>Auftragsverarbeiter wie Steuerberater und Wirtschaftsprüfer, Provider und Hoster,
		Logistikdienstleister, Beratungsunternehmen, Agenturen</li>
	<li>Sonstige Empfänger wie Bank-, Versicherungs- und Factoringinstitute, Auskunftsbüros,
		Geschäftspartner, Gesellschafter, Steuer- und Finanzbehörden, Hersteller und Lieferanten</li>
</ul>
<p>Unsere Daten werden üblicherweise nicht an Drittländer übermittelt.</p>
<p><b>V. Rechtsbehelfsbelehrung<br></b>Da wir die Daten in unseren berechtigten Interessen verarbeiten, haben Sie
	grundsätzlich ein Widerspruchsrecht, wenn bei Ihnen Gründe vorliegen, die sich aus Ihrer besonderen Situation
	ergeben, die gegen diese Verarbeitung sprechen.</p>
<p>Da wir die Daten (auch) für Direktwerbung verarbeiten, können Sie gegen diese Verarbeitung für Zwecke
	der Direktwerbung jederzeit Widerspruch erheben</p>
<p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung,
	Datenübertragbarkeit und Widerspruch zu. Dafür wenden Sie sich an unseren Verantwortlichen.</p>
<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
	datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der
	Aufsichtsbehörde beschweren. In Österreich ist die <b>Datenschutzbehörde</b> zuständig, die Sie wie folgt
	erreichen können</p>
<p><b>Österreichische Datenschutzbehörde<br></b>Barichgasse 40-42 | 1030 Wien<br>Telefon: +43 1 52 152<br><span
		>E-Mail: <a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a> | Web: <a
			href="http://www.dsb.gv.at/" target="_blank" rel="noopener"><span
				class="s2">http://www.dsb.gv.at/</a></p>
<p><b>Kontakt<br></b>Sie erreichen uns unter folgenden Kontaktdaten<br><b>Target Distribution GmbH (FN 73428 h – LG
		Feldkirch)<br></b>z.H. Datenschutz<br>Straßenhäuser 59 | 6842 Koblach<br>Telefon +43 5523 54871-0 | Telefax: +43
	5523 54873<br>E-Mail: <a href="%22mailto:datenschutz@target-distributi"><span
				class="s2">datenschutz@target-distribution.com</a> | Web: <a
			href="http://www.target-distribution.com">www.target-distribution.com</a> </p>
  `,
  faqQuestions: [
    {
      question: "Können alle Lizenzen direkt transferiert werden oder nur sobald sie fällig sind?",
      answer: `Eine Migration kann jederzeit durchgeführt werden.<br />
      Befindet sich der Kunde bereits im Verlängerungsfenster und hat die Lizenzen noch nicht oder nur teilweise verlängert, ist eine Migration nicht möglich. In diesem Fall muss erst die vollständige Verlängerung auf dem klassischen Wege durchgeführt werden.<br />
      Bestehen für den Kunden noch offene Kaufautorisierungen müssen diese ebenfalls erst auf dem klassischen Wege bestellt werden.<br />
      Innerhalb von 14 Tagen nach einer Bestellung (ob Verlängerung oder AddOn) kann kein Transfer durchgeführt werden.`,
    },
    {
      question:
        "Kann ich Kunden mit einem 3YC oder Enterprise Lizenzen, bzw. Government oder Education Kunden in den Adobe Cloud Store transferieren?",
      answer: `Nein, der Adobe Cloud Store gibt in diesem Fall einen Fehler aus, dass der Transfer des angegebenen VIP Accounts nicht möglich ist.<br />
      Diese Lizenzmodelle und -segmente werden in den kommenden Monaten von Adobe implementiert.`,
    },
    {
      question: "Sieht der Kunde in seiner Adminkonsole die Customer ID?",
      answer:
        "Nein, der Kunde behält im Falle eines Transfers seine bekannte VIP-Nummer. Bei Neukunden wir eine neue VIP-Nummer generiert.",
    },
    {
      question: "Gibt es die Möglichkeit Lizenzen monats- bzw. tageweise zu buchen?",
      answer:
        "Nein, noch steht diese Funktion nicht bereit. Adobe wird aber in mittelfristiger Zukunft die Möglichkeit der monatsweisen Buchung einführen.",
    },
    {
      question: "Bleibt der Stichtag des Kunden bestehen, nachdem ich ihn in den Adobe Cloud Store transferiert habe?",
      answer: "Ja, der Stichtag („Anniversary-Date“) wird übernommen und bleibt bestehen.",
    },
    {
      question: "Ist das Auto-Renewal bei transferierten Kunden bereits aktiviert?",
      answer:
        "Ja, das Auto-Renewal ist für Neukunden sowie transferierte Kunden standardmäßig aktiviert und kann auf Wunsch deaktiviert werden.",
    },
    {
      question: "Verschwindet der Kunde nach dem Transfer aus der Reseller Konsole?",
      answer: "Ja, nach dem Transfer ist der Kunde nicht mehr in der Reseller Konsole abrufbar.",
    },
    {
      question: "Wie kann ich Preise für Neukunden ermitteln, die noch nicht im Cloud Store angelegt wurden?",
      answer:
        "Aktuell besteht die Möglichkeit Angebote für Bestandskunden über den Warenkorb zu erstellen. Eine allgemeine Angebotsfunktion für den Cloud Store ist geplant.",
    },
    {
      question: "Wie können Kunden von anderen Resellern übernommen werden („Change of Reseller“)?",
      answer:
        `Möchten Sie einen bestehenden VIP Marketplace Kunden übernehmen, senden Sie bitte eine E-Mail mit der VIP-Nummer oder der Customer ID des Kunden an acs@target-distribution.com und wir kümmern uns um den Wechsel.<br />
        Ist der Kunde noch nicht im VIP Marketplace, können Sie Ihn nach dessen Genehmigung direkt in den Adobe Cloud Store transferieren. Ein vorheriger, „klassischer“ Change of Reseller in der Reseller Konsole ist nicht notwendig.`,
    },
    {
      question: "Lassen sich Aufträge stornieren, nachdem sie im Cloud Store platziert wurden?",
      answer:
        "Ja, Aufträge können innerhalb von 14 Tagen direkt im Cloud Store in der Auftragshistorie storniert werden.",
    },
    {
      question: "Können Verlängerungen vorzeitig oder nachträglich gebucht werden?",
      answer:
        `Nein, die Verlängerung wird automatisch am Stichtag durchgeführt. War das Auto-Renewal nicht aktiviert, können Sie über die „Renewal vergessen?“-Funktion in der Kundenübersicht einen nachträglichen Auftrag veranlassen. Hierbei handelt es sich nicht um ein klassisches Renewal, sondern technisch um eine Neubuchung der Lizenzen, welche anschließend in der Admin Konsole neu zugewiesen werden müssen.<br />
        Stellen Sie nach Möglichkeit also sicher, dass das Auto-Renewal am Tag vor dem Stichtag aktiv ist.`,
    },
    {
      question:
        "Wird es für Kunden im Cloud Store, die zur Verlängerung anstehen rechtzeitig Erinnerungsmails geben, um vorab Angebote und Rechnungen zu erstellen?",
      answer:
      "Ja, Sie als Reseller erhalten vor jedem Renewal insgesamt vier Erinnerungsmails (45, 30, 15 und einen Tag vor dem Stichtag), um Sie pünktlich über anstehende Verlängerungen zu informieren.",
    },
    {
      question:
        "Bleibt der bisherige Prozess für das Lizenzprogramm VIP bestehen oder ändert sich hier im Zuge der neuen Plattform etwas?",
      answer: "Für das „klassische“ VIP gibt es keine Änderungen, hier bleibt vorerst alles beim Alten.",
    },
    {
      question:
        "Was passiert, wenn mein Kunde eine neue Lizenz über seine Adminkonsole hinzufügt? Werde ich darüber benachrichtigt?",
      answer:
        "Ja, wenn ein Kunde über die Adminkonsole eine Kaufautorisierung erstellt, erhalten Sie wie im klassischen VIP eine Benachrichtigung per Mail. Diese Lizenz muss dann innerhalb von sieben (7) Tagen über den Adobe Cloud Store bestellt werden.",
    },
    {
      question:
        "Kann ich für meinen Kunden ein Angebot erstellen?",
      answer:
        "Ja, Sie haben die Möglichkeit über \"Auftrag erstellen\" vor dem Abschluss eines Auftrags ein Angebot zur erzeugen. Der Warenkorb wird beim Verlassen des Fensters gespeichert, sodass sie zu einem späteren Zeitpunkt wieder darauf zugreifen und die Lizenz(en) bestellen können. Zusätzlich haben Sie die Möglichkeit über die die Detailansicht eines \"Kunden\" ein \"Angebot für Renewal\" zu erzeugen.",
    },
  ],
  contactPersons: [
    {
      name: "Michelle Furxer",
      title: "Order Management Adobe",
      phoneNumber: "+43 5523 54871 117",
      email: "michelle.furxer@target-distribution.com",
      image: "michelle-furxer.jpg",
    },
    {
      name: "Susanne Hörburger",
      title: "Product Manager Adobe",
      phoneNumber: "+43 5523 54871 126",
      email: "susanne.hoerburger@target-distribution.com",
      image: "susanne-hoerburger.jpg",
    },
  ]
};
