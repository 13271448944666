<template>
  <div>
    <signed-out-navigation-component></signed-out-navigation-component>
    <b-container class="py-4">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col md="8" lg="6" xl="5">
          <b-card
            class="
              shadow-lg
              border-top
              border-bottom-0
              border-left-0
              border-right-0
              border-primary
            "
            body-class="p-5"
          >
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreake="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>

            <b-form v-on:submit.prevent="signInUser">
              <b-form-group>
                <label>{{ $t("emailLabel") }}</label>
                <b-form-input
                  id="input-email"
                  type="email"
                  v-model="email"
                  :state="states.email"
                  aria-describedby="input-email-feedback"
                  autocomplete="email"
                  trim
                />
                <b-form-invalid-feedback id="input-email-feedback">
                  {{ $t("emailInputErrorText") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="mb-4 form-group d-flex flex-column">
                <label>{{ $t("passwordLabel") }}</label>
                <b-form-input type="password" v-model="password" trim />
                <router-link
                  class="ml-auto"
                  :to="{ name: 'ForgotPasswordView' }"
                  ><small>{{ $t("forgotPasswordLink") }}</small></router-link
                >
              </div>
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="email === '' || password === ''"
              >
                {{ $t("signInButton") }}
              </b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import isEmail from "validator/es/lib/isEmail";
import SignedOutNavigationComponent from "../components/SignedOutNavigationComponent.vue";
import { auth } from "../plugins/firebase"
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";

export default {
  name: "LogInView",
  components: {
    LogoComponent,
    SignedOutNavigationComponent,
  },
  data() {
    return {
      email: "",
      password: "",
      states: {
        email: null,
      },
    };
  },
  methods: {
    signInUser() {
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(async () => {
          await auth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              if (idTokenResult.claims.role === "true") {
                this.$router.replace({ name: "AdminHomepageView" })
              } else {
                this.$router.replace({ name: "HomepageView" })
              }
            })
            .catch((error) => {
              console.error(error);
            });
          
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
        });
    },
  },
  mounted(){
    let token = this.$route.params.token || 0
    if(token){
      signInWithCustomToken(auth, token)
      .then(()=>{
        this.$router.replace({ name: "AdminHomepageView" })
      })
      .catch(err=>{console.error(err)})
    }
  },
  watch: {
    email(email) {
      if (email === "") {
        this.states.email = null;
      } else {
        this.states.email = isEmail(email);
      }
    },
  }
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
