<template>
  <div :class="`grid-container ${gridView}`">
    <div
      v-for="(item, index) in items"
      :key="`item-${index}`"
      v-show="
        checkFilter(item.filters) && checkSearch(item.productName) && item.showProduct && checkBlockedOfferId(item)
      "
    >
      <b-card
        :img-src="displayImage(item.imageUrl)"
        :img-alt="item.productName"
        :img-left="gridView === 'single-col'"
        :image-top="gridView === ''"
        body-class="d-flex"
      >
        <div>
          {{ item.productName | productNameWithoutLevel }}<br />
          <small class="text-muted" v-b-tooltip.hover :title="$t('manufacturerNumber')">
            {{ item.manufacturerItemNumber }}
          </small>
        </div>
        <div class="font-weight-bold">
          <span :id="`price-${index}`">{{ $t("priceInfo") }}</span>

          <b-popover
            :target="`price-${index}`"
            triggers="hover"
            placement="top"
            v-if="!item.productType.match(/^[678]0/)"
          >
            <template #title>{{ $t("priceInfo") }}</template>
            <b-form-group v-slot="{ ariaDescribedby }" class="w-100">
              <b-form-radio-group
                id="btn-radios-1"
                v-model="selected"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
                size="sm"
                class="w-100"
              ></b-form-radio-group>
            </b-form-group>
            <template v-if="selected == 'basic'">
              <div
                v-for="(price, priceIndex) in item.prices"
                :key="`price-${index}-${priceIndex}`"
                class="d-flex mb-2 align-items-start"
              >
                <template v-if="price.itemNumberSections.productType[2] != '3'">
                  <div class="d-flex mr-1 align-items-center text-nowrap">
                    <fa-icon :icon="['fa-regular', 'fa-layer-group']" class="mr-1"></fa-icon>{{ $t("volumeLevel") }} 0{{
                      price.itemNumberSections.volumeLevel
                    }}:
                  </div>

                  <div>
                    <div class="d-flex justify-content-end align-items-center text-nowrap">
                      {{ calcPriceUntilCotermDate(price.price) | currency }}
                      <fa-icon
                        :icon="['fa-regular', 'fa-circle-info']"
                        class="ml-1 text-muted"
                        v-b-tooltip.hover="
                          `${formatCurrency(price.price)} (${$t('pricePerYear')}) / ${daysInYear} (${$t(
                            'daysPerYear'
                          )}) * ${daysUntilCotermDate} (${$t('daysUntilAnniversaryDate')}) = ${formatCurrency(
                            calcPriceUntilCotermDate(price.price),
                            4
                          )}`
                        "
                      ></fa-icon>
                    </div>
                    <small class="text-muted text-nowrap">
                      ({{ $t("rrp") }} {{ calcPriceUntilCotermDate(price.endcustomerSalesPrice) | currency }})
                    </small>
                  </div>
                </template>
              </div>
            </template>

            <template v-else>
              <div
                v-for="(price, priceIndex) in item.prices"
                :key="`price-${index}-${priceIndex}`"
                class="d-flex mb-2 align-items-start"
              >
                <template v-if="price.itemNumberSections.productType[2] == '3' &&  useSpecialPrice(price, item)">

                  <div class="d-flex mr-1 align-items-center text-nowrap">
                    <fa-icon :icon="['fa-regular', 'fa-layer-group']" class="mr-1"></fa-icon>{{ $t("volumeLevel") }} 1{{
                      price.itemNumberSections.volumeLevel
                    }}:
                  </div>

                  <div>
                    <div class="d-flex justify-content-end align-items-center text-nowrap">
                      {{ calcPriceUntilCotermDate(price.price) | currency }}
                      <fa-icon
                        :icon="['fa-regular', 'fa-circle-info']"
                        class="ml-1 text-muted"
                        v-b-tooltip.hover=" 
                          `${formatCurrency(price.price)} (${$t('pricePerYear')}) / ${daysInYear} (${$t(
                            'daysPerYear'
                          )}) * ${daysUntilCotermDate} (${$t('daysUntilAnniversaryDate')}) = ${formatCurrency(
                            calcPriceUntilCotermDate(price.price),
                            4
                          )}`
                        "
                      ></fa-icon>
                    </div>
                    <small class="text-muted text-nowrap">
                      ({{ $t("rrp") }} {{ calcPriceUntilCotermDate(price.endcustomerSalesPrice) | currency }})
                    </small>
                  </div>
                </template>
              </div>
            </template>
          </b-popover>
          <b-popover :target="`price-${index}`" triggers="hover" placement="top" v-else>
            <template #title>{{ $t("priceInfo") }}</template>

            <b-form-group v-slot="{ ariaDescribedby }" class="w-100">
              <b-form-radio-group
                id="btn-radios-1"
                v-model="selected"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
                size="sm"
                class="w-100"
              ></b-form-radio-group>
            </b-form-group>

            <template v-if="selected == 'basic'">
              <div
                v-for="(price, priceIndex) in item.prices"
                :key="`price-${index}-${priceIndex}`"
                class="d-flex mb-2 align-items-start"
              >
                <template v-if="price.itemNumberSections.productType[2] != '3'">
                  <div class="d-flex mr-1 align-items-center text-nowrap">
                    <fa-icon :icon="['fa-regular', 'fa-layer-group']" class="mr-1"></fa-icon>
                    <template v-if="price.itemNumberSections.productType.match(/^[6]01/)"
                      >{{ $t("consumableLevel") }} T</template
                    >
                    <template v-else>{{ $t("volumeLevel") }} 0</template>{{ price.itemNumberSections.volumeLevel }}:
                  </div>
                  <div>
                    <div class="d-flex justify-content-end align-items-center text-nowrap">
                      {{ price.price | currency }}
                    </div>
                    <small class="text-muted text-nowrap">
                      ({{ $t("rrp") }} {{ price.endcustomerSalesPrice | currency }})
                    </small>
                  </div>
                </template>
              </div>
            </template>

            <template v-else>
              <div
                v-for="(price, priceIndex) in item.prices"
                :key="`price-${index}-${priceIndex}`"
                class="d-flex mb-2 align-items-start"
              >
                <template v-if="price.itemNumberSections.productType[2] == '3'">
                  <div class="d-flex mr-1 align-items-center text-nowrap">
                    <fa-icon :icon="['fa-regular', 'fa-layer-group']" class="mr-1"></fa-icon>
                    <template v-if="price.itemNumberSections.productType.match(/^[6]0/)"
                      >{{ $t("consumableLevel") }} T{{ threeYCLVL[price.itemNumberSections.volumeLevel] }}</template
                    >
                    <template v-else>{{ $t("volumeLevel") }} 1{{ price.itemNumberSections.volumeLevel }}</template
                    >:
                  </div>

                  <div>
                    <div class="d-flex justify-content-end align-items-center text-nowrap">
                      {{ price.price | currency }}
                    </div>
                    <small class="text-muted text-nowrap">
                      ({{ $t("rrp") }} {{ price.endcustomerSalesPrice | currency }})
                    </small>
                  </div>
                </template>
              </div>
            </template>
          </b-popover>
        </div>

        <b-button
          :variant="cartButtonColor(index)"
          v-on:click="addToCart(item, index)"
          :disabled="cartButtonState(index)"
        >
          <fa-icon :icon="['fa-regular', cartButtonIcon(index)]"></fa-icon>
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import settings from "../settings"
import moment from "moment-timezone";
import { orderBy } from "lodash-es";
import { firestore } from "../plugins/firebase";
import { collection, getDocs, where, updateDoc, doc, query, addDoc } from "firebase/firestore";

export default {
  name: "GridComponent",
  props: {
    items: Array,
    filters: Array,
    subscribedOfferIds: Array,
    cotermDate: String,
    gridView: String,
    search: String,
    threeYCCommited: Boolean,
    threeYCAccepted: Boolean,
    threeYCStartDate: String,
    specialPriceSwitch: Array
  },
  data() {
    return {
      activeIndex: null,
      selected: this.threeYCCommited ? "3yc" : "basic",
      threeYCLVL: {
        1: "A",
        2: "B",
        3: "C",
        4: "D",
        5: "E",
        6: "F",
        7: "G",
      },
    };
  },
  computed: {
    options() {
      if (this.threeYCAccepted || this.threeYCCommited) {
        return [
          { text: this.$t("basicOption"), value: "basic" },
          { text: this.$t("threeYCOption"), value: "3yc" },
        ];
      } else {
        return [{ text: this.$t("basicOption"), value: "basic" }];
      }
    },
    daysUntilCotermDate() {
      if (this.cotermDate === "") {
        let daysInYear = 365;
        if (moment(moment()).isLeapYear()) {
          daysInYear = 366;
        }
        return daysInYear;
      } else {
        return moment(this.cotermDate)
          .tz("Europe/Berlin")
          .diff(moment().tz("Europe/Berlin").format("YYYY-MM-DD"), "days");
      }
    },
    daysInYear() {
      let daysInYear = 365;
      if (
        (moment(this.cotermDate).isLeapYear() && moment(this.cotermDate).format("M") > 2) ||
        (moment(moment()).isLeapYear() && moment().format("M") <= 2)
      ) {
        daysInYear = 366;
      }
      return daysInYear;
    },
  },
  methods: {
    useSpecialPrice(price, item) {
      const itemIncludesSpecialPrices = this.includesSpecialPrices(item) // Preise in diesem "Artikel" mit Postfixen
      const relevantFostfixes = this.searchRelevantPostfixes() // Postfixe die für diesen Kunden relevant sind. 
      const matichingPostfixes = relevantFostfixes.filter(each => itemIncludesSpecialPrices.includes(each.postfix)) // Postfixe die für diesen Kunden relevant sind und in dem Artikel vorkommen

      if(itemIncludesSpecialPrices.length > 0 && relevantFostfixes.length > 0 && matichingPostfixes.length > 0){

        // TODO:den Richtigen SpecialPrice ermitteln( A, B, C, ..., ...)  <- Noch prüfen!!!
console.log(price.itemNumberSections.specialPriceFunction)
        if(price.itemNumberSections.specialPriceFunction != false && price.itemNumberSections.specialPriceFunction != settings.newPricePostfix){
          return true // show Special Price
        }
        return false // don´t show normal Price

      } else { 
        if(price.itemNumberSections.specialPriceFunction != false && price.itemNumberSections.specialPriceFunction != settings.newPricePostfix){
          return false // don´t show Special Price
        } else {
          return true // show all Prices exept special
        }
      }
    },
    searchRelevantPostfixes() {
      return this.specialPriceSwitch.filter(each => moment(each.date.toDate()).isAfter(moment(this.threeYCStartDate)))
    },
    includesSpecialPrices(item){
      let includesSpecialPrices = [];
      item.prices.forEach(price =>{
        if (price.itemNumberSections?.specialPriceFunction != false) {
          includesSpecialPrices.push(price.itemNumberSections?.specialPriceFunction);
          includesSpecialPrices = [...new Set(includesSpecialPrices)];
        }
      })
      // if(includesSpecialPrices.length > 0) { console.log(includesSpecialPrices)}
      return includesSpecialPrices
    },
    cartButtonState(index) {
      if (this.activeIndex === index) {
        return true;
      } else {
        return false;
      }
    },
    cartButtonColor(index) {
      if (this.activeIndex === index) {
        return "outline-primary";
      } else {
        return "primary";
      }
    },
    cartButtonIcon(index) {
      if (this.activeIndex === index) {
        return "fa-cart-circle-check";
      } else {
        return "fa-cart-circle-plus";
      }
    },
    orderPrices(prices) {
      return orderBy(
        prices,
        (price) => {
          return price.itemNumberSections.volumeLevel;
        },
        "asc"
      );
    },
    displayImage(imageUrl) {
      if (imageUrl === "") {
        return "/no-image.jpg";
      } else {
        return imageUrl;
      }
    },
    calcPriceUntilCotermDate(price) {
      if (this.cotermDate === "") {
        return price;
      }
      return (price / this.daysInYear) * this.daysUntilCotermDate;
    },
    async addToCart(item, index) {
      this.activeIndex = index;

      item["quantity"] = 1;

      const productsRef = collection(firestore, "orders", this.$route.params.orderId, "products");
      const colRef = query(productsRef, where("manufacturerItemNumber", "==", item.manufacturerItemNumber));
      const orderProducts = await getDocs(colRef);

      if (!orderProducts.empty) {
        const productDoc = {
          id: "",
          data: {},
        };
        orderProducts.forEach((orderProduct) => {
          productDoc.id = orderProduct.id;
          productDoc.data = orderProduct.data();
        });

        const productRef = doc(firestore, "orders", this.$route.params.orderId, "products", productDoc.id);
        updateDoc(productRef, {
          quantity: productDoc.data.quantity + 1,
        });
      } else {
        item["quantity"] = 1;

        const productsRef = collection(firestore, "orders", this.$route.params.orderId, "products");
        addDoc(productsRef, item);
      }

      setTimeout(() => {
        this.activeIndex = null;
      }, 300);
    },
    checkFilter: function (itemFilters) {
      if (this.filters.length > 0) {
        const uebereinstimmendeFilter = itemFilters.every((filter) => this.filters.includes(filter));
        if (uebereinstimmendeFilter && itemFilters.length === this.filters.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    checkSearch(productName) {
      if (productName.toLowerCase().includes(this.search.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    replaceInString(string, inject, find) {
      // ersetzt inject im sting an position find
      return (
        string.slice(0, find - 1) +
        string.slice(find - 1, find + 1).replace(string[find], inject) +
        string.slice(find + 1)
      );
    },
    checkBlockedOfferId(item) {
      // in blocked muss das lvl mit 0 ersetzt werden bsp: (A01 = A00)
      // xd-Lizenzen
      const blocked = [
        "65305118CA00A12",
        "65305121CA00A12",
        "65324779CA00A12",
        "65324782CA00A12",
        "65322595CA00A12",
        "65322599CA00A12",
        "65324833CA00A12",
        "65324835CA00A12",
        "65324779CA00012",
        "65324782CA00012",
      ];
      const intersection = this.subscribedOfferIds.filter((element) => blocked.includes(element));
      if (blocked.includes(this.replaceInString(item.manufacturerItemNumber, 0, 11)) && !intersection.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    formatCurrency(val, maximumFractionDigits) {
      return this.$options.filters.currency(val, maximumFractionDigits);
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.grid-container > div {
  width: 25%;
  padding: 5px;
  display: flex;
}
.grid-container > div .card {
  flex-grow: 1;
}
.grid-container.single-col > div {
  width: 100%;
}
.grid-container.single-col .card img {
  width: 107px;
}
.grid-container .card .card-body {
  flex-direction: column;
}
.grid-container .card .card-body > div:nth-child(2) {
  margin-bottom: 1rem;
  margin-top: auto;
}
.grid-container .card .card-body > div:first-child {
  margin-bottom: 1rem;
  margin-top: 0;
}
.grid-container .input-group {
  align-self: flex-end;
}
.grid-container.single-col .card .card-body {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.grid-container.single-col .card .card-body > div:nth-child(2) {
  margin-left: auto;
  margin-right: 1rem;
  margin-top: 0;
}
.grid-container.single-col .card .card-body > div:last-child {
  margin-right: 0;
}
.grid-container.single-col .card .card-body > div {
  margin-bottom: 0;
}
.grid-container.single-col .input-group {
  width: 130px;
}
.card img {
  max-height: 100% !important;
}
</style>
