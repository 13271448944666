<template>
  <div
    class="d-flex justify-content-center align-items-center bg-white"
    style="height: calc(100% - 56px)"
    v-if="isLoading"
  >
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
  <div v-else>
    <navigation-component></navigation-component>
    <router-view></router-view>
    <transition name="slide-fade">
      <news-panel-component v-if="$store.state.showNewsPanel"></news-panel-component>
    </transition>
  </div>
</template>

<script>
import NavigationComponent from "../components/NavigationComponent.vue";
import NewsPanelComponent from "../components/NewsPanelComponent.vue";
import { auth, firestore } from "../plugins/firebase";
import { signOut, updateProfile } from "firebase/auth";
import { collection, doc, onSnapshot, query, orderBy } from "firebase/firestore";

export default {
  name: "LoggedInView",
  components: { NavigationComponent, NewsPanelComponent },
  data() {
    return {
      isLoading: true,
      showNewsPanel: false,
    };
  },
  async mounted() {
    let uid = auth.currentUser.uid;

    /* if (uid !== "15001609") {
      signOut(auth)
        .then(() => this.$router.replace("/"))
        .catch((error) => console.error(error.message));
    } */

    let isAdmin = true;

    if (auth.currentUser.uid.includes("-")) {
      const uidSegments = auth.currentUser.uid.split("-");
      uid = uidSegments[0];
      isAdmin = false;
      this.getSubuserDataFromCollection(uid, uidSegments[1]);
    }

    await this.getResellerDataFromCollection(uid, isAdmin);

    this.getNotificationsFromCollection();
  },
  methods: {
    getResellerDataFromCollection(uid, isAdmin) {
      try {
        const docRef = doc(firestore, "resellers", uid);
        onSnapshot(docRef, (doc) => {
          let hiddenNotifications = [];
          if (doc.data()?.hiddenNotifications) {
            hiddenNotifications = doc.data()?.hiddenNotifications;
          }

          let hiddenCustomers = [];
          if (doc.data()?.hiddenCustomers) {
            hiddenCustomers = doc.data().hiddenCustomers;
          }

          const storeData = {
            displayName: auth.currentUser.displayName,
            email: auth.currentUser.email,
            uid: uid,
            resellerId: doc.data().resellerId,
            customernumber: doc.data().customernumber,
            companyProfile: doc.data().companyProfile,
            firestoreId: doc.id,
            hiddenNotifications: hiddenNotifications,
            isAdmin: isAdmin,
            hiddenCustomers: hiddenCustomers,
          };

          if (doc.data().companyProfile.companyName !== auth.currentUser.displayName) {
            updateProfile(auth.currentUser, {
              displayName: doc.data().companyProfile.companyName,
            })
              .then(() => {
                storeData.displayName = doc.data().companyProfile.companyName;
              })
              .catch((error) => console.error(error.message));
          }

          this.$store.commit("storeResellerData", storeData);

          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
        signOut(auth)
          .then(() => this.$router.replace("/"))
          .catch((error) => console.error(error.message));
      }
    },
    getSubuserDataFromCollection(uid, subuserId) {
      try {
        const docRef = doc(firestore, "resellers", uid, "users", subuserId);
        onSnapshot(docRef, (doc) => {
          this.$store.commit("storeResellerSubUserData", doc.data());
        });
      } catch (error) {
        console.error(error);
        signOut(auth)
          .then(() => this.$router.replace("/"))
          .catch((error) => console.error(error.message));
      }
    },
    getNotificationsFromCollection() {
      try {
        const q = query(collection(firestore, "notifications"), orderBy("order", "desc"));
        onSnapshot(q, (querySnapshot) => {
          const notifications = [];
          querySnapshot.forEach((doc) => {
            const notification = {
              docId: doc.id,
              docData: doc.data(),
            };
            notifications.push(notification);
          });
          this.$store.commit("storeNotifications", notifications);
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(320px);
}
</style>
