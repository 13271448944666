<template>
  <div v-if="isWorking === false">
    <div class="bg-light py-4 header-box" v-b-visible="visibleHandler">
      <b-container class="d-flex align-items-center justify-content-between">
        <!-- <div class="d-flex flex-column">
          <small class="text-muted">{{ $t("newOrderFor") }} </small> -->
          <h3 class="font-weight-bold mb-0">
            {{ order.customer.companyProfile.companyName }}
          </h3>
        <!-- </div> -->

        <shopping-cart-component :cotermDate="order.customer.cotermDate" :index="0"></shopping-cart-component>
      </b-container>
    </div>

    <div class="bg-light pb-4 header-box" v-b-visible="visibleHandler">
      <b-container class="d-flex align-items-center">
        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{ $t("creationTime") }}</small>
          <div>
            <fa-icon :icon="['fa-regular', 'fa-clock']" class="mr-1"></fa-icon>{{ order.creationDate | date }}
          </div>
        </div>

        <div class="d-flex flex-column">
          <small class="text-muted">{{ $t("anniversaryDate") }}</small>
          <div><fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon>{{ cotermDate }}</div>
        </div>
      </b-container>
    </div>

    <div id="fixed-header-box" class="bg-light shadow-sm py-4" :class="fixedHeaderBoxVisibility">
      <b-container class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <div class="d-flex flex-column mr-4">
            <small class="text-muted">{{ $t("newOrderFor") }} </small>
            <div class="font-weight-bold">
              {{ order.customer.companyProfile.companyName }}
            </div>
          </div>

          <div class="d-flex flex-column mr-4">
            <small class="text-muted">{{ $t("creationTime") }}</small>
            <div>
              <fa-icon :icon="['fa-regular', 'fa-clock']" class="mr-1"></fa-icon>{{ order.creationDate | date }}
            </div>
          </div>

          <div class="d-flex flex-column">
            <small class="text-muted">{{ $t("anniversaryDate") }}</small>
            <div><fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon>{{ cotermDate }}</div>
          </div>
        </div>

        <shopping-cart-component :cotermDate="order.customer.cotermDate" :index="1"></shopping-cart-component>
      </b-container>
    </div>

    <b-container class="pt-4 d-flex flex-row">
      <div  class="mr-4" style="min-width: 222px;">
        <b-card body-class="d-flex justify-content-between">
          <b-nav vertical pills>
            <b-nav-item v-on:click="changeFilters(['license', 'teams'])" :active="filtersContains(['license', 'teams'])"><span v-html="$t('teamsLicenses')"></span></b-nav-item>
            <b-nav-item v-on:click="changeFilters(['license', 'teams', 'pro'])" :active="filtersContains(['license', 'teams', 'pro'])"><span v-html="$t('teamsLicensesProEditions')"></span></b-nav-item>
            <b-nav-item v-on:click="changeFilters(['license', 'enterprise'])" :active="filtersContains(['license', 'enterprise'])"><span v-html="$t('enterpriseLicenses')"></span></b-nav-item>
            <b-nav-item v-on:click="changeFilters(['license', 'enterprise', 'pro'])" :active="filtersContains(['license', 'enterprise', 'pro'])"><span v-html="$t('enterpriseLicensesProEditions')"></span></b-nav-item>
            <b-nav-item v-on:click="changeFilters(['consumable', 'transaction'])" :active="filtersContains(['consumable', 'transaction'])">{{ $t("signTransactions") }}</b-nav-item>
            <b-nav-item v-on:click="changeFilters(['consumable', 'teams'])" :active="filtersContains(['consumable', 'teams'])">{{ $t("creditPacksTeams") }}</b-nav-item>
            <b-nav-item v-on:click="changeFilters(['consumable', 'enterprise'])" :active="filtersContains(['consumable', 'enterprise'])">{{ $t("creditPacksEnterprise") }}</b-nav-item>
          </b-nav>
        </b-card>
      </div>

      <div>
        <b-card body-class="d-flex justify-content-between" class="mb-4">
          <b-input-group class="w-25">
            <template #prepend>
              <b-input-group-text class="bg-white pr-0"
                ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
              ></b-input-group-text>
            </template>
            <b-input :placeholder="$t('browse')" v-model="search" class="border-left-0" debounce="600"></b-input>
          </b-input-group>

          <b-button-group class="flex-shrink-0">
            <b-button variant="secondary" v-on:click="gridView = 'single-col'" :disabled="gridView === 'single-col'">
              <fa-icon :icon="['fa-regular', 'fa-table-list']" class="mr-2"></fa-icon>{{ $t("list") }}
            </b-button>
            <b-button variant="secondary" v-on:click="gridView = ''" :disabled="gridView === ''">
              <fa-icon :icon="['fa-regular', 'fa-table-cells-large']" class="mr-2"></fa-icon>{{ $t("grid") }}
            </b-button>
          </b-button-group>
        </b-card>

        <grid-component
          :items="products"
          :cotermDate="order.customer.cotermDate"
          :gridView="gridView"
          :filters="filters"
          :search="search"
          :subscribedOfferIds="subscribedOfferIds"
          :threeYCCommited="order?.customer?.benefits[0]?.commitment?.status == 'COMMITTED' ? true : false"
          :threeYCAccepted="order?.customer?.benefits[0]?.commitment?.status == 'ACCEPTED' ? true : false"
          :threeYCStartDate="order?.customer?.benefits[0]?.commitment?.startDate || 'false'"
          :specialPriceSwitch="specialPriceSwitch"
        ></grid-component>

      </div>
    </b-container>
  </div>
  <div class="h-100 d-flex align-items-center justify-content-center py-4" v-else>
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
</template>

<script>
import moment from "moment-timezone";
import settings from "../settings"
import { orderBy as _orderBy } from "lodash-es";
import ShoppingCartComponent from "../components/ShoppingCartComponent.vue";
import GridComponent from "../components/GridComponent.vue";
import { functions, firestore } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, updateDoc, collection, orderBy, query, getDocs, where, serverTimestamp } from "firebase/firestore";

export default {
  components: { ShoppingCartComponent, GridComponent },
  name: "CreateOrderSelectProductsView",
  data() {
    return {
      specialPriceSwitch: [],
      search: "",
      products: [],
      customer: null,
      isWorking: true,
      order: {},
      gridView: "single-col",
      filters: ["license", "teams"],
      fixedHeaderBoxVisibility: "hide",
      subscribedOfferIds: []
    };
  },
  computed: {
    cotermDate() {
      if (this.order.customer.cotermDate === "") {
        return "wurde noch nicht festgelegt";
      }
      return this.$options.filters.date(this.order.customer.cotermDate);
    },
  },
  async mounted() {
    await this.getData();
    this.getCustomerSubscriptions()
  },
  methods: {
    visibleHandler(isVisible) {
      if (isVisible === false) {
        this.fixedHeaderBoxVisibility = "show";
      } else {
        this.fixedHeaderBoxVisibility = "hide";
      }
    },
    async getSpecialPriceSwitch(){
      const colRef = query(collection(firestore, "specialPriceSwitch"));
      const querySnapshot = await getDocs(colRef);
      const data = []
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          data.push(doc.data())
        });
      }
      this.specialPriceSwitch = data;
      return
    },
    async getData() {
      await this.getSpecialPriceSwitch();
      this.order = await this.getOrder();
      this.order.customer = await this.getCustomerAccount(this.order.customer.customerId);
      if (this.order.customer) {
        await this.saveCustomerToOrder(this.order.customer);
        await this.updateCustomerAccount(this.order.customer);
      }
      this.getProducts();
    },
    async getOrder() {
      try {
        const docRef = doc(firestore, "orders", this.$route.params.orderId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          return docSnapshot.data();
        } else {
          this.$store.dispatch("alertError", {
            message: "Der Auftrag konnte nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alertError", {
          message: "Der Auftrag konnte nicht abgerufen werden.",
          show: true,
          noFooter: false,
        });
      }
    },
    getCustomerAccount(customerId) {
      const getCustomerAccount = httpsCallable(functions, "customerAccount");
      return getCustomerAccount({
        customerId: customerId,
      })
        .then((customerAccount) => {
          const cotermDateTimestamp = moment(customerAccount.data.cotermDate)
            .tz("Europe/Berlin")
            .unix();
          const todayTimestamp = moment()
            .tz("Europe/Berlin")
            .unix();

          if (cotermDateTimestamp < todayTimestamp) {
            const thisYear = moment()
              .tz("Europe/Berlin")
              .format("YYYY");
            const cotermDateDayAndMonth = moment(customerAccount.data.cotermDate).format("MM-DD");
            const cotermDateYear = moment(customerAccount.data.cotermDate).format("YYYY");

            if (cotermDateYear === thisYear) {
              const nextYear = moment()
                .tz("Europe/Berlin")
                .add(1, "years")
                .format("YYYY");
              customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
            } else {
              const newCotermDateThisYear = `${thisYear}-${cotermDateDayAndMonth}`;
              const newCotermDateThisYearTimestamp = moment(newCotermDateThisYear)
                .tz("Europe/Berlin")
                .unix();

              if (newCotermDateThisYearTimestamp < todayTimestamp) {
                const nextYear = moment()
                  .tz("Europe/Berlin")
                  .add(1, "years")
                  .format("YYYY");
                customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
              } else {
                customerAccount.data.cotermDate = newCotermDateThisYear;
              }

// hier noch 3yc Datum in echtes DATE() umwandeln !!!!

            }
          }

          return customerAccount.data;
        })
        .catch(() => {
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });
          return;
        });
    },
    async updateCustomerAccount(customerAccount) {
      const colRef = query(collection(firestore, "customers"), where("customerId", "==", customerAccount.customerId));
      const querySnapshot = await getDocs(colRef);

      if (!querySnapshot.empty) {
        let docId = null;

        querySnapshot.forEach((doc) => {
          docId = doc.id;
        });

        if (docId !== null) {
          const docRef = doc(firestore, "customers", docId)
          updateDoc(docRef, {
            companyProfile: customerAccount.companyProfile,
            cotermDate: customerAccount.cotermDate,
            status: customerAccount.status,
            discounts: customerAccount.discounts,
            resellerId: customerAccount.resellerId,
            lastFetchFromAdobe: serverTimestamp(),
          });
        }
      }
    },
    saveCustomerToOrder(customer) {
      try {
        const docRef = doc(firestore, "orders", this.$route.params.orderId);
        updateDoc(docRef, {
          customer: customer,
        });
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alertError", {
          message: "Die Kundendaten konnten nicht gespeichert werden.",
          show: true,
          noFooter: false,
        });
      }
    },
    async getProducts() {
      const productsColRef = query(collection(firestore, "products"), orderBy("productName"));
      const productsPromise = getDocs(productsColRef);

      const pricesColRef = collection(firestore, "resellers", this.$store.state.resellerData.firestoreId, "prices");
      const pricesPromise = getDocs(pricesColRef);

      const results = await Promise.all([productsPromise, pricesPromise]).catch((error) => {
        console.error(error);
      });

      if (results[0].empty || results[1].empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });
        return;
      }

      const products = [];
      results[0].forEach((product) => {
        products.push(product.data());
      });

      const prices = [];
      results[1].forEach((price) => {
        prices.push(price.data());
      });

      const productsUnique = [];

      products.forEach((product) => {
        const itemNumberSections = this.clArtNoObject(product.itemNumber);

        if (itemNumberSections.volumeLevel === 4) {
          if (
            (itemNumberSections.product === "1300" && itemNumberSections.productType === "001") || // Creative Cloud New Order Promo herausfiltern
            //(itemNumberSections.product === "0300" && itemNumberSections.productType === "001") || // Creative Cloud Standard herausfiltern
            itemNumberSections.product === "0325" // Premiere Rush herausfiltern
            ) { // eslint-disable-next-line no-empty

            } else {
            const productObject = {
              productBaseItemNumber: itemNumberSections.product,
              languageNumber: itemNumberSections.language,
              productType: itemNumberSections.productType,
              filters: product.filters,
              imageUrl: product.imageUrl,
              productName: product.productName,
              quantity: 0,
              prices: [],
              manufacturerItemNumber: product.manufacturerItemNumber,
            };

            itemNumberSections?.specialPriceFunction != settings.newPricePostfix && productsUnique.push(productObject);
          }
        }
      });

      const allPrices = [];
      let missingPrices = false;
      products.forEach((product) => {
        const itemNumberSections = this.clArtNoObject(product.itemNumber);
        const matchedPrice = prices.filter((price) => product.itemNumber === price.itemNumber);

        if(matchedPrice[0]) {
        const priceObject = {
            price: matchedPrice[0]?.price,
            itemNumber: matchedPrice[0]?.itemNumber,
            itemNumberSections: itemNumberSections,
            manufacturerItemNumber: product.manufacturerItemNumber,
            endcustomerSalesPrice: product.endcustomerSalesPrice,
            imputedCost: product.imputedCost,
          };

          itemNumberSections.specialPriceFunction != settings.newPricePostfix && allPrices.push(priceObject);
        } else {
          missingPrices = true;
          console.error("Es Fehlen Preise für :", product)
        }
      });
      missingPrices && this.$store.dispatch("alertError", {
            message: "Some Prices are Missing maybe you can´t order. Please contact us.",
            show: true,
            noFooter: false,
          });
      productsUnique.forEach((product, index) => {
        const matchedPrices = allPrices.filter(
          (price) =>
            product.productBaseItemNumber === price.itemNumberSections.product &&
            product.languageNumber === price.itemNumberSections.language &&
            product.productType[0] === price.itemNumberSections.productType[0] &&
            product.productType[1] === price.itemNumberSections.productType[1] && // productType[2] === 3 = 3YC
            product.productType[2] !== '3' && 
            price.itemNumberSections.promoCode !== "1"
        );

        if (matchedPrices.length !== 0) {
          productsUnique[index]["showProduct"] = true;
          productsUnique[index].prices = _orderBy(
            matchedPrices,
            (price) => {
              return price.itemNumberSections.volumeLevel;
            },
            "asc"
          );
        } else {
          productsUnique[index]["showProduct"] = false;
        }
      });

      this.products = productsUnique;

      this.isWorking = false;
    },
    clArtNoObject(itemNumber) {
      const itemNumberChunks = itemNumber.split("-");
      const artNrSplittet = itemNumberChunks[3].split("");
      // console.log(itemNumberChunks?.[4] || false)
      return {
        product: artNrSplittet[0] + artNrSplittet[1] + artNrSplittet[2] + artNrSplittet[3],
        volumeLevel: parseInt(artNrSplittet[4]) + 1,
        language: artNrSplittet[5],
        productType: itemNumberChunks[2],
        promoCode: artNrSplittet[6],
        specialPriceFunction: itemNumberChunks?.[4] || false,
      };
    },
    changeFilters(values) {
      this.filters = values;
    },
    filtersContains(values) {
      const results = [];
      values.forEach((value) => results.push(this.filters.includes(value)));

      if (results.includes(false) || values.length !== this.filters.length) {
        return false;
      } else {
        return true;
      }
    },
    replaceInString(string, inject, find) {
      // ersetzt inject im sting an position find
      return (string.slice(0, find-1) + string.slice(find-1, find+1).replace(string[find], inject) + string.slice(find+1));
    },
    async getCustomerSubscriptions(){
      const getCustomerSubscriptions = httpsCallable(functions, "getCustomerSubscriptions");
      const subscriptionsRequest = await getCustomerSubscriptions({
        customerId: this.order.customer.customerId,
      });
      subscriptionsRequest.data.items.forEach(el => {
        if(el.status == 1000) {
          this.subscribedOfferIds.push(this.replaceInString(el.offerId, 0, 11))
        }
      })
    },
  },
};
</script>

<style scoped>
#fixed-header-box {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
#fixed-header-box.hide {
  transform: translateY(-100%);
}
#fixed-header-box.show {
  transform: translateY(0);
  transition: transform 0.3s ease;
}
</style>
