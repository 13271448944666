export default {
  imprint: `
  <h3>Impressum</h3>
  <p>
    <strong>Target Distribution GmbH</strong><br />
    Straßenhäuser 59<br />
    A – 6842 Koblach<br />
  </p>
  <p>
    Telefon: +43 5523 54871<br />
    Fax: +43 5523 54873<br />
    E-Mail: info@target-distribution.com
  </p>
  <p>
    Unternehmenswebsite: http://www.target-distribution.com
  </p>
  <p>
    Onlineshop: shop.Target.at
    Email: info@target-distribution.com
  </p>
  <p>
    Sitz: Koblach, Österreich
  </p>
  <p>
    Unternehmensgegenstand:
    Authorisierter Apple Distributor
  </p>
  <p>
    Rechtsform: GmbH
    Geschäftsführer: Gert Furxer
  </p>
  <p>
    Firmenbuchnummer: FN 73 42 8h
  </p>
  <p>
    Firmenbuchgericht: Feldkirch
    UID-Nummer: ATU 365 60 609
  </p>
  <p>
    Kammerangehörigkeit: Wirtschaftskammer Vorarlberg
  </p>
  <p><strong>Haftungsausschluss</strong></p>
  <p><strong>1. Inhalt des Onlineangebotes</strong></p>
  <p>Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.<br>
    Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
  </p>
  <p><strong>2. Verweise und Links</strong></p>
  <p>
    Bei direkten oder indirekten Verweisen auf fremde Webseiten (Hyperlinks), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.</br>
    Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
  </p>
  <p><strong>3. Urheber- und Kennzeichenrecht</strong></p>
    <p>Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen.<br>
    Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!</br>
    Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.
  </p>
  <p><strong>4. Datenschutz</strong></p>
  <p>Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist – soweit technisch möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten.</p>

  <p><strong>5. Rechtswirksamkeit dieses Haftungsausschlusses</strong></p>
  <p>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.</p>

  <p><strong>6. Google Analytics</strong></p>
  <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link (http://tools.google.com/dlpage/gaoptout?hl=de) verfügbare Browser-Plugin herunterladen und installieren.</p>

  <p>Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter http://www.google.com/analytics/terms/de.html bzw. unter https://www.google.de/intl/de/policies/. Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code „anonymizeIp“ erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.</p>
    
  `,
  adobeTc: `
  <h3>ADOBE VALUE INCENTIVE PLAN TERMS AND CONDITIONS</h3>
  <p><br>
    This Value Incentive Plan (“VIP” or “Program”) Commercial Membership Agreement (“Agreement”) sets forth the terms of your participation as a Member in the Program. The Agreement is effective as of the date of online enrollment. This Agreement is entered into by and between Adobe and the customer identified in on-line enrollment to become a Member. “Adobe” shall mean either ADOBE INC., a Delaware corporation having a place of business at 345 Park Avenue, San Jose, CA 95110-2704, if the Agreement is entered into with a Member’s organization that is located in the United States, Canada, or Mexico, ADOBE SYSTEMS SOFTWARE IRELAND LIMITED, a company incorporated in Ireland and having a place of business at 4‑6&nbsp;Riverwalk, Citywest Business Campus, Dublin 24, Ireland as authorized agent of Adobe Systems Pty Ltd (ABN 72 054 247 835), if the Agreement is entered into with a Member’s organization that is located in Australia, or otherwise with ADOBE SYSTEMS SOFTWARE IRELAND LIMITED, a company incorporated in Ireland and having a place of business at 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Ireland. Member shall license all software, services, and offerings available under the Program (collectively “Product”) to be installed and used within the United States (including United States territories and military bases wherever located), Canada, or Mexico from Adobe U.S. Member shall license all Products to be installed and used in Australia from Adobe Ireland, as authorized agent of Adobe Systems Pty Ltd (ABN 72 054 247 835). Member shall license all Products to be installed and used in all other countries and territories from Adobe Ireland.</p>
  <h4><br>
    1.&nbsp;Program Description.</h4>
  <p><br>
    <b>1.1&nbsp;<i>General and Program Term</i></b><i>.</i> The VIP Program is a flexible licensing program designed to allow qualified and eligible customers to manage and deploy Product licenses purchased through the Program. The VIP Program is a membership program with license Subscription Periods as further defined in Section&nbsp;3.4 of this Agreement. Once the organization enrolls in a membership through the VIP administration user interface and is accepted by Adobe into the Program, the organization will be a member of the Program (“Member”) until the earliest of when (a)&nbsp;Adobe terminates the Program, (b)&nbsp;this Agreement terminates. Member’s participation is further subject to the terms stated in the program guide for the Program, which may be updated from time to time and is available at <a href="https://www.adobe.com/go/vip_program_guide_en" target="_blank">www.adobe.com/go/vip_program_guide_en</a> (“Program Guide”). The Program Guide is hereby incorporated into the Agreement by reference. Adobe may change the terms of the Program at its sole discretion. If Program terms change Member may be required to reaccept the Program terms in the Console.
  </p>
  <p><br>
    <b>1.2&nbsp;<i>Termination.</i></b> Notwithstanding the foregoing, either party may terminate the Agreement with or without cause on thirty&nbsp;(30)&nbsp;days prior written notice. Termination of this Agreement shall not affect Member’s obligations with regard to any Products ordered prior to the termination date, including without limitation any subscription terms or payment obligations. In addition, Adobe may immediately terminate this Agreement, upon written notice, for a material breach (including but not limited to any misappropriation or infringement of Adobe’s intellectual property rights). If this Agreement terminates, then Member’s organization shall immediately cease use of the Products, delete the Products from all computer systems and IT equipment on which it resides, and return to Member’s Account Manager any media containing the Products as well as any related materials. Account Manager means either (i)&nbsp;Reseller, if a transaction is through a reseller, or (ii)&nbsp;Adobe representative, if a transaction is with Adobe.
  </p>
  <p>&nbsp;</p>
  <p><b>1.3&nbsp;<i>TOU.</i></b> The access and use of the Products is governed by the applicable Adobe terms of use (“TOU”) available at <a href="https://www.adobe.com/legal/terms.html">https://www.adobe.com/legal/terms.html</a>. The terms of the applicable TOUs are hereby incorporated by reference (including without limitations terms related to governing law and venue). In the event of inconsistency between the terms of this Agreement and the terms of the TOU, the terms of this Agreement shall control.</p>
  <p><br>
    <b>1.4&nbsp;<i>Program Products.</i> </b>For a complete list of Products available through the Program, Member shall contact its&nbsp;Account Manager or go to the Program web pages located on Adobe.com <a href="http://www.adobe.com/howtobuy/buying-programs/vip.html">http://www.adobe.com/howtobuy/buying-programs/vip.html</a>. All Products purchased through the Program are solely for use within Member’s own organization and all re-sale, sublicensing, and other distribution is prohibited except as set forth in Section 4.1 and/or the Program Guide, if applicable. Certain offerings may be available for license by purchasing Consumables, as described in the Program Guide.
  </p>
  <p>&nbsp;</p>
  <p><b>1.5&nbsp;<i>Affiliates.</i></b> Adobe agrees that customer's Affiliates may place orders under this Agreement for the Products provided hereunder. Customer retains ultimate liability for any acts or omissions of such Affiliate. Affiliate means, for Member, any other entity that controls, is controlled by, or under common control with, Member. For the purposes of this Section 1.5, the term “control” means the direct or indirect power to direct the affairs of the other entity through at least 50% of the shares, voting rights, participation, or economic interest in this entity.</p>
  <h4><br>
    2.&nbsp;Participation.</h4>
  <p><br>
    <b>2.1&nbsp;<i>Adobe ID and VIP ID.</i></b> An Adobe ID will be required in order to enroll in the Program. Each Member will be assigned a VIP ID which must be referenced on all orders. Member is responsible for acts or omissions of anyone who obtains access to and use of the Products through Member.
  </p>
  <p><br>
    <b>2.2&nbsp;<i>Console.</i></b> The Program administration user interface is the “Admin Console.” Once the terms of this agreement are accepted, the individual accepting on behalf of the organization will be assigned as the Contract Owner. The Contract Owner can add system administrator(s) (each an “Administrator”). The Contract Owner and any Administrator will be provided access to the Admin Console where they will have the ability to access the Product, manage their subscriptions, and view their account information. The Admin Console will allow the Administrator to invite additional users within their organization to gain access to the Admin Console. Member authorizes any Administrator or Contract Owner to act on Member’s behalf.
  </p>
  <p><br>
    <b>2.3&nbsp;<i>Confidentiality</i>.</b> Member shall treat VIP ID as confidential and not share or disclose such information.
  </p>
  <p><br>
    <b>2.4&nbsp;<i>Memberships</i></b>. The terms of Exhibit B apply to Education, Government, and Non-Profit Memberships. Member must use a separate VIP Agreement for any Products made available and ordered for use in the People’s Republic of China.
  </p>
  <h4><br>
    3.&nbsp;Ordering, Pricing, and Fulfillment.</h4>
  <p><br>
    <b>3.1<i>&nbsp;Ordering and Pricing</i>.</b> Member shall place Product orders with their Account Manager. All fees are determined by Member’s Account Manager. Matters such as price, delivery, and payment terms must be agreed between Member and Member’s Account Manager. Adobe cannot guarantee any particular discount, unless Adobe is Member’s Account Manager.
  </p>
  <p><br>
    <b>3.2&nbsp;<i>Access, Admin Console Deployment, and Fulfillment</i></b><i>.</i> Upon becoming a Member, Member’s Administrator will be provided access to the available Products, through the Admin Console. Member may add many Products from the Admin Console and obtain immediate access to such Products. Adobe must receive an order for any such Products within the Grace Period.&nbsp;
  </p>
  <p><br>
    <b>3.3 <i>Grace Period for Products Added in Admin Console</i></b><i>.</i> The Grace Period is 14 days after adding such Products. If Adobe does not receive an order for such Products within the Grace Period, then Member will no longer be allowed to add additional Products until payment is made for all added Products. Member may manage the number of Products deployed in the Console.
  </p>
  <p><br>
    <b>3.4&nbsp;<i>Agreement Anniversary Date, Subscription Period, and Renewals.</i></b>
  </p>
  <p>&nbsp;</p>
  <p><b>3.4.1&nbsp;<i>Anniversary Date</i>.</b>&nbsp;Unless otherwise communicated by Adobe, Member’s Anniversary Date is the day twelve months after Adobe accepts Member’s initial order (“Anniversary Date”).</p>
  <p>&nbsp;</p>
  <p><b>3.4.2&nbsp;<i>Subscription Period.</i> </b>The Subscription Period means the period that a Member may use Products and includes the initial Subscription Period and any renewal Subscription Period. The initial Subscription Period means the period that begins on Member’s initial order date and ends the day prior to the Anniversary Date. With Product subscription renewal, a renewal Subscription Period will begin on the Anniversary Date and continue until the day prior to the next Anniversary Date. Use of subscription Products and any related services co-terminates on the last day of the Subscription Period. Most Consumables must be used within a single Subscription Period, and any unused Consumables will expire on the last day of the Subscription Period. Additional information may be found in the Program Guide.</p>
  <p>&nbsp;</p>
  <p><b>3.4.3&nbsp;<i>Subscription Renewals</i>.</b>&nbsp;Adobe or Account Manager will use reasonable efforts to notify Member prior to any Subscription Period end date. Subscriptions must be renewed prior to the Anniversary Date in order to ensure uninterrupted use of Product.&nbsp;</p>
  <p><br>
    <b>3.5&nbsp;<i>Upgrade Protection</i>.</b> Purchase of the subscription Product(s) includes upgrade entitlement, meaning Member will be entitled to receive the latest generally available version of a subscription Product purchased under the program as long as the Product subscription is paid and active at the time Adobe makes the new version of the Product commercially available.
  </p>
  <p><br>
    <b>3.6&nbsp;<i>Returns</i>.</b> Without prejudice to any rights that a Member may have under any consumer laws in the jurisdiction that Member is located, once a Product is installed or accessed, Member cannot return it. If Member requests a return prior to installation, Member must return the entire order. Member must request the return of Products purchased under the Agreement through Member’s Account Manager. Subject to any applicable warranty rights, return requests must be made to Member’s Account Manager within fourteen (14) days after Member’s original Product order date. Adobe must approve all return requests before any return is valid, as further described in the Program Guide.
  </p>
  <p><br>
    <b>3.7&nbsp;<i>Orders Direct From Adobe</i>. </b>If Member orders direct from Adobe, then this section will apply. Members can add Products at any time via the Admin Console or via an Adobe representative, but you must place an order for all Products with an Adobe representative. Products are priced at the rates current at the time ordered and prorated based on the days remaining in your Subscription Period. Notwithstanding the language in 3.2, 3.3, and 4.2, if you add Products via the Admin Console, then Adobe my invoice you directly for those products at the then current price. Your order may be subject to credit approval. Member will be charged the Product price, plus applicable taxes. For any ordered Products, Member must pay in full within 30 days of the invoice date in accordance with the invoice. Any amounts not paid when due will bear interest at a rate which is the lesser of 1.0% per month or the maximum rate permitted by applicable law on any overdue fees, from the date due until the date the full amount plus interest is paid in-full.
  </p>
  <p>&nbsp;</p>
  <h4>4. VIP Marketplace.</h4>
  <p>&nbsp;</p>
  <p><b>4.1</b>.&nbsp; Section 4 “VIP Marketplace” will apply to Products licensed through VIP Marketplaces.&nbsp; Unless otherwise communicated by Adobe, Subscriptions offered through VIP Marketplaces auto-renew on Member’s Anniversary Date despite language in 3.4.3 above.&nbsp; Member may work with their Account Manager to adjust Product subscriptions.</p>
  <p>&nbsp;</p>
  <p><b>4.2 <i>VIP Marketplace Grace Period</i>.</b>&nbsp; Notwithstanding Section 3.2 and 3.3, the Grace Period for VIP Marketplace Products is seven days after adding Products, not 14 days.&nbsp; If Adobe does not receive an order for Products added through VIP Marketplace within the Grace Period, then such Products will be removed.&nbsp;&nbsp;</p>
  <h4>&nbsp;</h4>
  <h4>5.&nbsp;Miscellaneous.</h4>
  <p>&nbsp;</p>
  <p><b>5.1&nbsp;<i>Transfer of License</i>.</b> Product TOUs do not govern restrictions regarding the transfer of Products licensed under or in connection with this Agreement. In limited circumstances, Adobe may permit the transfer of Product licenses under this Agreement in its sole and exclusive discretion. Such requests should be directed to Adobe Customer Service including a description of the reason for the proposed transfer and the contact information of the transferee. Additional information may be found in the Program Guide. For clarity, all offerings are licensed, not sold.</p>
  <p><br>
    <b>5.2&nbsp;<i>License Compliance</i>.</b> Member must maintain systems and/or procedures sufficient to ensure an accurate record of the number of copies of the Products that have been installed and/or deployed and retain records of Product installation and/or deployment for two&nbsp;(2) years after the termination of the Agreement. Adobe and/or its representatives may conduct an audit of Member’s Product installation/deployment not more than once per year on thirty&nbsp;(30) days written notice. Such audit will require Member to provide an unedited, accurate report of all Products installed/deployed and accessed by Member and all valid purchase documentation for all Products within thirty (30) days after request. If the audit findings demonstrate non-conformity with the Product licenses, Member shall purchase the necessary licenses within thirty&nbsp;(30) days after being so notified. Notwithstanding the foregoing, Adobe reserves the right to conduct an onsite audit of Member license installation and deployment after ten&nbsp;(10) business days’ prior written notice during regular business hours. This Section&nbsp;4.2 shall survive termination of the Agreement for a period of two&nbsp;(2)&nbsp;years.
  </p>
  <p><br>
    <b>5.3&nbsp;<i>Use of Information</i>.</b> Adobe may use information about Member or Affiliate, including name and contact information, for fulfilling obligations under the Agreement. For more information, please see the Adobe Privacy Center (<a href="https://www.adobe.com/privacy.html">adobe.com/privacy</a>).
  </p>
  <p><br>
    <b>5.4&nbsp;<i>Standing</i>.</b> The parties agree that the United Nations Convention on Contracts for the International Sale of Goods is specifically excluded from application to this Agreement. If Member is an entity of the United States Federal Government, Member agrees that Adobe shall have standing and the right to assert any breach of contract claim arising out of this Agreement under the Contracts Disputes Act of 1978 (“Disputes Act”).
  </p>
  <p><br>
    <b>5.5&nbsp;<i>General</i>. </b>The parties are independent contractors, and this Agreement will not be construed to imply that either party is the agent or venturer of the other. Member may not assign this Agreement (by operation of law or otherwise) without the prior written consent of Adobe and any prohibited assignment is null and void. Adobe may, at its sole discretion, assign or novate this Agreement, without the prior written consent of Member. This Agreement will be binding upon and will inure to the benefit of any permitted successors or assignees. No modification will be valid or binding unless in writing. This Agreement (including the Program Guide, applicable TOUs, and any Exhibits or on-line enrollment information, if applicable) represents the entire agreement between the parties on the subject matter of this Agreement. If any provision of this Agreement is held unenforceable, the remainder of the Agreement will continue in full force and effect.
  </p>
  <p><br>
    This Agreement is prepared and is executed in the English language only. The English language version shall be controlling in all respects, and any version of this Agreement in any other language, shall not be binding and shall have no effect. Further, each party agrees that signature by Member or Adobe on any non-English language version, even if there is no signature(s) on the English language version shall be deemed execution of the English language version and binding on the parties. Without limiting the foregoing, if there is any conflict or inconsistency between the English language version of this Agreement and any other translated version of this Agreement, the English language version and interpretation shall prevail. All communications or notices to be made or given pursuant to this Agreement and any disputes arising under this Agreement shall be conducted or resolved in the English language.</p>
  <p><br>
    <b>5.6&nbsp;<i>Services.</i></b> The Product may integrate with a variety of services, operated either by Adobe or by third&nbsp;parties, which may contain user-generated content that may be (a)&nbsp;inappropriate for minors, (b)&nbsp;illegal in some countries; or (c)&nbsp;inappropriate for viewing at work. A complete list of integrated services is available here: <a href="https://www.adobe.com/go/integratedservices">www.adobe.com/go/integratedservices</a>. If Member wants to prevent viewing of or access to user-generated content services it may (i)&nbsp;disable service access in the Creative Cloud Packager, where that functionality is made available, or (ii)&nbsp;block access to the services via its network firewall. Services are not, under any circumstances, available to users under the age of thirteen (13). Adobe disclaims liability for all user-generated content available via the services. Member is responsible for determining whether use of the services complies with applicable laws in Member’s jurisdiction. Member is not entitled to a refund and Adobe is not liable (i)&nbsp;if access to the services is slowed or blocked as a result of government or service provider action, or (ii)&nbsp;if Adobe reasonably deems it necessary to block access to some or all of the services.
  </p>
  <p>&nbsp;</p>
  <h3>EXHIBIT B<br>
    EDUCATION, GOVERNMENT, AND NON-PROFIT MEMBERSHIPS</h3>
  <p>&nbsp;</p>
  <h4><b>A. EDUCATION MEMBERS</b></h4>
  <p><br>
    For the Member who is an Education Entity (defined below), the following additional terms apply. Adobe reserves the right to terminate Education memberships if Member is not an Educational Entity.</p>
  <h4><br>
    1. Definitions Applicable to Education Members.</h4>
  <p><br>
    <b>1.1&nbsp;<i>Education Entity.</i></b> The following is a non-exhaustive list of qualified educational institutions: (a)&nbsp;Accredited (by official accrediting entities) public or private primary or secondary school providing full-time instruction; (b)&nbsp;Accredited public or private university or college (including community, junior, or vocational college) that grants degrees requiring not less than the equivalent of two years of full-time study; (c)&nbsp;Named educational institutions approved by Adobe, only if individual named entities are approved by Adobe in writing; (d)&nbsp;Hospitals that are wholly owned and operated by an otherwise qualified educational institution, where “wholly owned and operated” means the educational institution is sole owner of the hospital and the only entity exercising control over day to day operations; and (e)&nbsp;Higher education research laboratories that are a public institution and recognized by a national or state educational authority.
  </p>
  <p><br>
    The following is a non-exhaustive list of entities that are not qualified educational institutions: (a)&nbsp;Non-accredited schools; (b)&nbsp;Museums or libraries; (c)&nbsp;Hospitals not wholly owned and operated by an otherwise qualified educational institution; (d)&nbsp;Churches or religious organizations that are not accredited schools; (e)&nbsp;Vocational training centers or schools granting certificates for courses such as computer software training or job training that are not accredited schools or which grant degrees requiring less than the equivalent of two years of full-time study; (f)&nbsp;Military schools that do not grant academic degrees; and (g)&nbsp;Research laboratories not recognized by a national or state ministry overseeing education. For example, institutions recognized by other government branches are not eligible.</p>
  <p><br>
    The above lists do not apply to the countries as listed in Section 1.2 (Regional-Specific Definition) below.</p>
  <p><br>
    <b>1.2&nbsp;<i>Regional-Specific Definition of Education Entity.</i></b>
  </p>
  <p>(a)&nbsp;<i>Asia Pacific Countries excluding Southeast Asia Countries</i> as defined in sub-paragraph (b) below. If Education Member is resident in Australia, New Zealand, India, Sri Lanka, mainland China, Hong Kong SAR of China., Taiwan region, the Republic of Korea, the People’s Republic of Bangladesh, the Federal Democratic of Nepal, the Republic of the Union of Myanmar, Pakistan or Mongolia or any country designated by Adobe from time to time, “Education Entity” shall mean the entities that satisfy the meaning of “Qualified Educational Users” (except for the sections entitled “Full and Part Time Faculty and Staff” and “Students”) designated by Adobe on <a href="http://www.adobe.com/ap/education/purchasing/qualify.html">http://www.adobe.com/ap/education/purchasing/qualify.html</a> (or its successor web site thereto), as updated by Adobe from time to time.</p>
  <p><br>
    (b)&nbsp;<i>Southeast Asia Countries.</i> If Education Member is resident in Indonesia, Malaysia, Philippines, Singapore, Thailand and Vietnam, “Education Entity” or “Education Institution” shall have the respective meanings designated by Adobe on <a disablelinktracking="false" href="https://www.adobe.com/go/edu_entity_sea" target="_blank">https://www.adobe.com/go/edu_entity_sea</a>&nbsp;(or its successor web site thereto), as updated by Adobe from time to time.</p>
  <p><br>
    (c)&nbsp;<i>Japan</i>. If Education Member is resident in Japan, “Education Entity” or “Education Institution” shall have the respective meanings designated by Adobe on <a href="https://helpx.adobe.com/jp/x-productkb/policy-pricing/cq081918191.html">https://helpx.adobe.com/jp/x-productkb/policy-pricing/cq081918191.html</a> (or its successor web site thereto), as updated by Adobe from time to time.</p>
  <p>&nbsp;</p>
  <p><b>1.3&nbsp;<i>Primary and Secondary Schools.</i></b> Primary and Secondary Schools are defined in the Program Guide. Adobe may have offers available to members that qualify as Primary and Secondary Schools. Adobe reserves the right to terminate Primary and Secondary School licenses and memberships if Member is not a Primary and Secondary School as defined in the Program Guide. See VIP Education Program Guide for additional details.</p>
  <h4><br>
    <b>B.&nbsp;GOVERNMENT MEMBERS</b>
  </h4>
  <p><br>
    For the Member who is a Government Entity (defined below), the following additional terms apply. Adobe reserves the right to terminate Government memberships if Member is not a Government Entity.</p>
  <h4><br>
    1.&nbsp;Definitions Applicable to Government Members.</h4>
  <p><b><i>Government Entity</i>.</b>&nbsp;Participation is contingent upon Member (and each Affiliate) being a “government entity”, which means: (a)&nbsp;a federal, central, or national agency, department, commission, board, office, council, or authority (executive, legislative, or judicial); (b)&nbsp;a municipality, special district, city, county, or state governmental agency, department, commission, board, office, council, entity, or authority, or any other agency in the executive, legislative, or judicial branch of state or local government that is created by the constitution or a statute of the governing state, including the district, regional, and state administrative offices; or (c)&nbsp;a public agency or organization created and/or funded by federal, state, or local governments and authorized to conduct the business of governing or supporting citizens, businesses, or other governmental entities. For the avoidance of doubt, the following entities are not Government Entities: private “for profit” companies, non-profit organizations, trade or industry associations, higher education institutions, and labor unions, even those conducting work on behalf of or with government agencies, unless such entity has a specific letter of authorization from a U.S Government Entity pursuant to FAR Part 51. Member represents to Adobe that it and its Affiliates are government entities. A&nbsp;list of qualified “Government Entities” for&nbsp;Japan is available at:&nbsp;<a href="http://www.adobe.com/jp/aboutadobe/volumelicensing/pdfs/cl5_government_license_table.pdf" target="_blank">http://www.adobe.com/jp/aboutadobe/volumelicensing/pdfs/cl5_government_license_table.pdf</a>.</p>
  <p><br>
    <b>1.1 For France: </b>a Government Entity is either an agency; a minister; a commission, board, office, or council (national, regional, or local); a city; a region; or any entity subject to the French Public Law and under the administration of a government entity.
  </p>
  <h4><br>
    2.&nbsp;Terms Applicable to Government Members.</h4>
  <p><br>
    <b>2.1&nbsp;<i>Additional Restrictions</i>.</b> For United States Federal Government Members, it is understood that any orders are subject to FAR&nbsp;52.232-18 (Availability of Funds) and FAR 52.232-19 (Availability of Funds for the Next Fiscal Year) and therefore United States Federal Government Members shall not deploy any Product unless funds are available to pay for such orders. To the extent any state or local government entity is subject to similar requirements, such entities shall not deploy any Product unless funds are available to pay for such orders.
  </p>
  <p><br>
    <b>2.2&nbsp;<i>Termination</i>.</b> This Agreement may be terminated by a Member who is a United States federal government customer pursuant to FAR 52.249-1 (Termination for Convenience of the Government). Adobe may change the Terms at its sole discretion.
  </p>
  <p><br>
    <b>2.3&nbsp;<i>Federal Government Members</i>.</b> Notice to U.S. Federal Government End Users (Commercial Items): The Products, provided under this Agreement are “Commercial Item(s),” as that term is defined at 48 C.F.R. §2.101, consisting of “Commercial Computer Software” and “Commercial Computer Software Documentation,” and services related thereto, as such terms are used in 48&nbsp;C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48&nbsp;C.F.R. §227.7202-1 through §227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Federal Government End Users (a) only as Commercial Items and (b)&nbsp;with only those rights as are granted to all other end users pursuant to the terms and conditions of this Agreement and the TOUs. Unpublished rights are reserved under the laws of the United States- Adobe Inc., 345 Park Avenue, San Jose, CA 95110-2704, USA.
  </p>
  <h4><br>
    C.&nbsp;NON-PROFIT MEMBERS</h4>
  <p><br>
    Additional terms applicable to Non-Profit Members are contained in the Program Guide. Adobe reserves the right to terminate memberships, if Member is not an eligible non-profit organization, as described on <a href="https://helpx.adobe.com/buying-programs/non-profit.html">https://helpx.adobe.com/buying-programs/non-profit.html</a>.<br>
  </p>
  `,
  comlineTc:`
  <ol class="agb">
    <li>
      <strong>Allgemeines</strong>
      <ol>
        <li>
          Die nachfolgenden Allgemeinen Geschäftsbedingungen sind Bestandteil jedes von uns abgeschlossenen Vertrages.
        </li>
        <li>
          Etwaige Einkaufsbedingungen des Käufers gelten nicht, es sei denn, sie werden von uns ausdrücklich schriftlich anerkannt.
        </li>
        <li>
          Für die gesamten Rechtsbeziehungen mit dem Käufer gilt ausschließlich deutsches Recht. Die Bestimmungen des UN–Kaufrechts (CISG) gelten im Verhältnis zwischen uns und dem Käufer nicht.
        </li>
        <li>
          Diese Bedingungen gelten für den Rechtsverkehr mit Unternehmern. Wir beliefern ausschließlich Kunden, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbstständigen beruflichen Tätigkeit handeln sowie juristische Personen des öffentlichen Rechts und öffentlich–rechtliche Sondervermögen (beidseitige Handelsgeschäfte).
        </li>
        <li>
          Als Erfüllungsort für Lieferung und Zahlung sowie als Gerichtsstand wird Flensburg vereinbart, mit der Maßgabe, dass wir berechtigt sind, auch am Ort des Sitzes oder einer Niederlassung des Käufers zu klagen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Angebote, Vertragsabschluß, Preise</strong>
      <ol>
        <li>
          Unsere Angebote sind nach Menge, Preis und Lieferzeit frei bleibend. Mit seiner Bestellung erklärt der Käufer verbindlich, die von ihm bestellte Ware erwerben zu wollen. Die zum Vertragsabschluß führende Annahme kann durch die Auslieferung der Ware oder durch die Erbringung der Dienstleistung oder dadurch erklärt werden, dass wir dem Käufer in sonstiger Weise die Annahme seiner Beststellung bestätigen. Mit der Annahme durch uns ist der Vertrag zustande gekommen.
        </li>
        <li>
          Ein Widerrufsrecht steht Unternehmern und folglich dem Käufer nicht zu.
        </li>
        <li>
          Technische und gestalterische Abweichungen von Beschreibungen und Angaben in Unterlagen, sowie Modell–, Konstruktions– und Materialänderungen im Zuge des technischen Fortschritts bleiben vorbehalten, ohne dass hieraus Rechte gegen uns hergeleitet werden können.
        </li>
        <li>
          Unsere Preise sind Nettopreise. Sofern sich aus unserer Auftragsbestätigung nichts anderes ergibt, gelten sie  ab Lager Flensburg oder bei Direktversand ab deutscher Grenze bzw. deutschem Einfuhrhafen zuzüglich der am Liefertage geltenden Mehrwertsteuer, ausschließlich Verpackung, Versicherung, Fracht und ggf. Mindermengenzuschlag. Diese Positionen werden gesondert in Rechnung gestellt. Die Entsorgung der Verpackung übernimmt der Kunde auf eigene Kosten.
        </li>
      </ol>
    </li>
    <li>
      <strong>Versand</strong>
      <ol>
        <li>
          Die  Abholung  des  Liefergegenstandes hat grundsätzlich durch  den  Käufer und unverzüglich  nach  Anzeige der Bereitstellung im Werk oder Lager zu erfolgen.
        </li>
        <li>
          Soweit die Lieferung durch uns gewünscht wird, erfolgt sie grundsätzlich ab Lager und – soweit nicht im Einzelfall anders vereinbart – auf Kosten des Käufers. Wird der Liefergegenstand auf Wunsch des Käufers an diesen versandt, so geht die Gefahr des zufälligen Unterganges und der zufälligen Verschlechterung des Liefergegenstandes mit der Übergabe des Liefergegenstandes an den Spediteur, Frachtführer, Versandbeauftragten oder Abholer auf den Käufer über. Das gilt auch dann, wenn die Frachtkosten aufgrund individueller Vereinbarung von uns getragen werden oder wir den Versand durch eine Transportperson selbst durchführen.
        </li>
        <li>
          Kommt  der  Käufer  in  Annahmeverzug,  so sind wir berechtigt,  Ersatz  der uns entstehenden Aufwendungen   zu   verlangen. Mit   Eintritt   des   Annahmeverzuges   geht   die   Gefahr   der   zufälligen Verschlechterung und des zufälligen Untergangs auf den Käufer über. 
        </li> 
        <li> 
          Verzögert sich der Versand aus Gründen, die wir nicht zu vertreten haben, geht die Gefahr mit der Anzeige der Versandbereitschaft auf den Käufer über.
        </li>
      </ol>
    </li>
    <li>
      <strong>Lieferung, Rücktritt</strong>
      <ol>
        <li>
          Angaben zum Liefertermin sind unsererseits unverbindlich und stellen lediglich eine unverbindliche Schätzung dar. Die Vereinbarung fester Liefertermine bedarf der Schriftform.
        </li>
        <li>
          Der Vertragsabschluß erfolgt unter dem Vorbehalt richtiger und rechtzeitiger Selbstbelieferung. Wir sind daher zum Rücktritt berechtigt, wenn unser Lieferant den mit uns vor Abschluss des jeweiligen Verkaufsvertrages geschlossenen Einkaufsvertrag aus von uns nicht vertretenden Gründen nicht erfüllt.
        </li>
        <li>
          Darüber hinaus sind wir berechtigt, von geschlossenen Verträgen zurückzutreten, wenn sich infolge von Katastrophen, Kriegsereignissen oder ähnlichen Umständen die Warenbeschaffung gegenüber dem Zeitpunkt des Vertragsabschlusses wesentliche erschwert. Als eine wesentliche Erschwerung gilt es in jedem Falle, wenn der Marktpreis des Kaufgegenstandes zwischen dem Abschluss des jeweiligen Verkaufsvertrages und dem vorgesehenen Liefertermin um 25% oder mehr gestiegen ist.
        </li>
        <li>
          Bei von uns nicht zu vertretenden Störungen in unserem Betrieb oder Lager sowie bei behindernden behördlichen Maßnahmen wird die Lieferfrist um die Dauer der Störung verlängert. Darüber hinaus sind wir berechtigt, von abgeschlossenen Verträgen zurückzutreten, wenn die Störung ohne unser Verschulden über eine Zeitraum von mehr als vier Wochen andauert. Störung im vorgenannten Sinne schließt auch solche Betriebsunterbrechungen oder –einschränkungen ein, die durch Personalausfall größeren Umfangs infolge von Krankheiten, Arbeitskampfmaßnahmen oder ähnlichem verursacht werden.
        </li>
      </ol>
    </li>
    <li>
      <strong>Abnahme und Übernahme, Untersuchung, Mängelrüge</strong>
      <ol>
        <li>
          Der Käufer ist verpflichtet, auch Teillieferungen entgegenzunehmen. Teillieferungen können sofort in Rechnung gestellt werden.
        </li>
        <li>
          Bei Lieferungen auf Abruf stellt der Abruf innerhalb der vereinbarten Frist eine Hauptpflicht dar.
        </li>
        <li>
          Liefergegenstände   sind,   auch   wenn   sie   unwesentliche   Mängel   aufweisen,   vom   Kunden unbeschadet des Bestehens etwaiger Gewährleistungsansprüche entgegenzunehmen. 
        </li>
        <li>
          Der Käufer ist verpflichtet, den Liefergegenstand bei Übergabe auf etwaige Mängel zu untersuchen und uns solche  unverzüglich  schriftlich  anzuzeigen.  Die  einschlägigen  Regelungen  und Rechtsfolgen des HGB gelten entsprechend.  
        </li>
      </ol>
    </li>
    <li>
      <strong>Eigentumsvorbehalt</strong>
      <ol>
        <li>
          Bis zur vollständigen Bezahlung unserer Kaufpreisforderung sowie aller anderen uns gegen den Käufer zustehenden Forderungen bleibt die gelieferte Ware unser Eigentum. Der Eigentumsvorbehalt bleibt auch dann bestehen, wenn einzelne Forderungen in eine laufende Rechnung aufgenommen werden und der Saldo gezogen und anerkannt ist.
        </li>
        <li>
          Wird die von uns gelieferte Vorbehaltsware von dem Käufer be– oder verarbeitet, so erfolgt die Be– und Verarbeitung für uns als "Hersteller" im Sinne des § 950 BGB.
        </li>
        <li>
          Wird unsere Vorbehaltsware mit eigener Ware des Käufers oder mit fremder Vorbehaltsware verbunden, vermischt oder zusammen mit solcher Ware verarbeitet, so erwerben wir das Miteigentum ab der neuen Sache oder an dem vermischten Bestand im Verhältnis des Wertes unserer Vorbehaltsware zu der anderen Ware zu der Zeit der Verbindung, Vermischung oder Verarbeitung. Auf die durch Verbindung, Vermischung oder Verarbeitung herbeigeführte Wertsteigerung erheben wir keinen Anspruch.
        </li>
        <li>
          <ol>
            <li>
              Der Käufer tritt seine Forderung mit allen Nebenrechten aus dem Weiterverkauf unserer Vorbehaltsware sowie gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehenden Ware zur Sicherheit für alle uns im Zeitpunkt der Weiterveräußerung gegen den Käufer zustehenden Ansprüche bereits jetzt an uns ab.
            </li>
            <li>
              Im Falle der Weiterveräußerung der Ware, die gemäß Ziffer 6.3 in unserem Miteigentum steht, gilt als abgetreten jedoch nur der Teil der Forderung, der dem Wert unseres Miteigentumsanteils entspricht.
            </li>
            <li>
              Hat der Käufer die Forderung aus dem Weiterverkauf im Rahmen des echten Factoring verkauft, so tritt er die an ihre Stelle tretende Forderung gegen den Factor an uns ab.
            </li>
            <li>
              Die vorstehenden Abtretungen nehmen wir hiermit ausdrücklich an.
            </li>
            <li>
              Übersteigt der Wert der uns zur Sicherheit abgetretenen Forderungen unsere Ansprüche gegen den Käufer um mehr als 20%, so sind wir auf Verlangen des Käufers verpflichtet, darüber hinaus bestehende Sicherheiten freizugeben.
            </li>
          </ol>
        </li>
        <li>
          Der Käufer ist zur Weiterveräußerung unserer Vorbehaltsware sowie der gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehende Waren nur im Rahmen seines gewöhnlichen Geschäftsverkehrs und nur unter der Voraussetzung berechtigt, dass die Kaufpreisforderung aus dem Weiterverkauf gemäß Ziffer 6.4 auf uns übergeht.
        </li>
        <li>
          Der Käufer ist verpflichtet, unsere Vorbehaltsware sowie die gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehende Ware gegen Verlust und Beschädigung aufgrund Feuer, Diebstahl, Wasser und ähnlicher Gefahren ausreichend zu versichern und uns auf Verlangen den Versicherungsschutz nachzuweisen. Der Käufer tritt hiermit seine Entschädigungsansprüche, die ihm gegen Versicherungsgesellschaften oder sonstige Ersatzverpflichtete zustehen – gegebenenfalls anteilig – an uns ab. Auch die vorstehende Abtretung wird hiermit angenommen.
        </li>
        <li>
          Irgendeine Beeinträchtigung unserer Vorbehaltsware sowie der gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehende Ware ist uns ebenso unverzüglich bekanntzugeben, wie Zugriffe dritter darauf.
        </li>
        <li>
          Nehmen wir aufgrund des Eigentumsvorbehalts den Kaufgegenstand zurück, so gilt das nicht als Rücktritt vom Vertrag. Wir können uns aus der zurückgenommenen Vorbehaltsware durch freihändigen Verkauf befriedigen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Zahlung</strong>
      <ol>
        <li>
          Die Forderungen aus unseren Rechnungen sind nach unserer Wahl, sofern nichts anderes vereinbart ist, per Vorauskasse, per Bargeldnachnahme oder per SEPA–Firmenlastschrift zahlbar. Unbeschadet einer einzelvertraglichen Ware sofort fällig bei Abholung bzw. Lieferung.
        </li>
        <li>
          Wir sind berechtigt, trotz anderslautender Zahlungsbestimmung des Käufers, Zahlungen zunächst auf dessen älteste Schuld anzurechnen. Sind bereits Kosten und Zinsen entstanden, so sind wir berechtigt, die Zahlung zunächst auf die Kosten, dann auf die Zinsen und zuletzt auf die Hauptforderung anzurechnen.
        </li>
        <li> 
          Der Käufer kommt auch ohne Mahnung in Verzug, wenn er nach Ablauf von 30 Tagen nach Zugang der Rechnung nicht gezahlt hat. Mit Zugang einer Mahnung tritt Verzug gegebenenfalls auch vor Ablauf von 30 Tagen nach Zugang der Rechnung ein. Verzugszinsen werden in Höhe von 8 Prozentpunkten über dem jeweiligen Basiszinssatz berechnet. Die Geltendmachung eines höheren Zinsschadens bleibt vorbehalten.
        </li>
        <li>
          Kommt der Käufer seinen Zahlungsverpflichtungen nicht vertragsgemäß nach, oder stellt er seine Zahlungen ein oder werden uns andere Umstände bekannt, die die Kreditwürdigkeit des Käufers in Frage stellen, so sind wir berechtigt, die gesamte Restschuld fällig zu stellen. § 321 BGB findet mit der Maßgabe Anwendung, dass uns die dort vorgesehene Einrede auch dann zusteht, wenn die Vermögenslage des Käufers bereits bei Vertragsschluß schlecht war, dies uns jedoch nicht bekannt gewesen ist.
        </li>
        <li>
          Der Käufer kann Zurückbehaltungsrechte gegenüber unseren Forderungen nicht geltend machen. Ferner ist die Aufrechnung gegenüber unseren Forderungen ausgeschlossen, es sei denn, die Gegenforderung ist unbestritten oder rechtskräftig festgestellt. 
        </li>
      </ol>
    </li>
    <li>
      <strong>Gewährleistung</strong>
      <ol>
        <li>
          Angaben zu unseren Waren sind generell reine Beschaffenheitsangaben, es sei denn, sie werden ausdrücklich als zugesicherte Eigenschaften bezeichnet.
        </li>
        <li>
          Übernommene oder gelieferte Waren sind unverzüglich vom Käufer auf vorhandene Mängel zu überprüfen. Sind solche vorhanden, sind sie unverzüglich schriftlich uns gegenüber anzuzeigen. Im Übrigen gelten die § 377 ff. HGB. Daneben sind Gewährleistungsansprüche generell ausgeschlossen, wenn infolge von Weiterversand oder Be– bzw. Verarbeitung der von uns gelieferten Ware oder anderer Umstände unsererseits nicht mehr einwandfrei geprüft und festgestellt werden kann, ob ein Mangel der Ware tatsächlich vorliegt.
        </li>
        <li>
          Gewährleistungsansprüche verjähren binnen 12 Monaten, beginnend mit Übergabe der Sache.
        </li>
        <li>
          Die Gewährleistung beim Verkauf gebrauchter Gegenstände ist generell ausgeschlossen.
        </li>
        <li>
          Nimmt uns der Käufer auf Gewährleistung in Anspruch, und stellt sich heraus, dass ein Gewährleistungsanspruch nicht besteht (zum Beispiel Anwenderfehler, unsachgemäße Behandlung des Kaufgegenstandes, Nichtbestehen eines Mangels), so hat uns der Käufer alle im Zusammenhang mit der Überprüfung des Kaufgegenstandes entstehenden Kosten zu ersetzen.
        </li>
        <li>
          Soweit der Hersteller für die gelieferte Ware eine freiwillige Garantie gegenüber dem Käufer gewährt, richten sich Art und Umfang der Garantieleistungen ausschließlich nach dem Inhalt der Herstellergarantie. Aus dieser Garantie kann – soweit diese Garantie über die gesetzlichen Gewährleistungsrechte hinaus geht – ausschließlich der Hersteller in Anspruch genommen werden.
        </li>
      </ol>
    </li>
    <li>
      <strong>Haftung</strong>
      <ol>
        <li>
          <ol>
            <li>
              In allen Fällen, in denen wir im Geschäftsverkehr aufgrund vertraglicher oder gesetzlicher Anspruchsgrundlagen zum Schadens– oder Aufwendungsersatz verpflichtet sind, haften wir nur, soweit uns, unseren Organen, gesetzlichen Vertretern oder Erfüllungsgehilfen Vorsatz oder grobe Fahrlässigkeit zur Last fällt. 
            </li>
            <li>
              Unberührt hiervon bleibt die Haftung für die schuldhafte Verletzung wesentlicher Vertragspflichten. Wesentliche Vertragspflichten sind solche, die den Vertragsparteien die Rechte zubilligen, die der Vertrag nach seinem Inhalt und Zweck gerade zu  gewähren  hat,  insbesondere  die  Pflichten,  deren  Erfüllung  die  ordnungsgemäße  Durchführung  des Vertrags überhaupt erst ermöglichen und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und vertrauen darf. 
            </li>
            <li>
              Soweit eine zurechenbare Pflichtverletzung auf einfacher Fahrlässigkeit beruht und eine wesentliche Vertragspflicht schuldhaft verletzt ist, ist unsere  Schadensersatzhaftung jedoch auf den vorhersehbaren Schaden beschränkt, der typischerweise in vergleichbaren Fällen eintritt. 
            </li>
            <li>
              Die vorstehenden Haftungsausschlüsse gelten nicht für   den   Fall   der   Tötung,   der   Verletzung   der   Gesundheit   oder   des   Körpers,   für garantierte Beschaffenheitsmerkmale und   im   Fall   einer etwaigen   Haftung   nach   dem   Produkthaftungsgesetz. In diesen Fällen haften wir nach den gesetzlichen Bestimmungen. 
            </li>
          </ol>
        </li>
        <li>
          Im Übrigen ist jegliche Haftung unsererseits ausgeschlossen.
        </li>
        <li>
          Die vorstehenden Haftungsausschlüsse und –beschränkungen gelten in gleichem Umfang zugunsten unserer Organe, gesetzlichen Vertreter, Angestellten und sonstigen Erfüllungsgehilfen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Schutz– oder Urheberrechte</strong>
      <ol>
        <li>
          Der Käufer wird uns unverzüglich und schriftlich unterrichten, falls er auf die Verletzung von Schutz– oder Urheberrechten durch ein von uns geliefertes Produkt hingewiesen wird. Wir sind allein berechtigt und verpflichtet, den Käufer gegen die Ansprüche des Inhabers derartiger Rechte zu verteidigen und diese Ansprüche auf eigene Kosten zu regeln, soweit sie auf die unmittelbare Verletzung durch ein von uns geliefertes Produkt gestützt sind. Grundsätzlich werden wir uns bemühen, dem Käufer das Recht zur Benutzung zu verschaffen. Falls uns dies zu wirtschaftlich angemessenen Bedingungen nicht möglich ist, werden wir nach eigener Wahl dieses Produkt derart abändern oder ersetzen, dass das Schutzrecht nicht verletzt wird oder das Produkt zurücknehmen und den Kaufpreis abzüglich eines etwaigen Betrages für die gewährte Nutzungsmöglichkeit erstatten.
        </li>
        <li>
          Umgekehrt wird der Käufer uns gegenüber allen Ansprüchen des Inhabers derartiger Rechte verteidigen bzw. freistellen, welche gegen uns dadurch entstehen, dass wir Instruktionen des Käufers befolgt haben oder der Käufer das Produkt ändert oder in das System integriert.
        </li>
        <li>
          Von uns zur Verfügung gestellte Programme und dazugehörige Dokumentationen sind nur für den eigenen Gebrauch des Käufers im Rahmen einer einfachen, nicht übertragbaren Lizenz bestimmt, und zwar ausschließlich auf von uns gelieferten Produkten. Der Käufer darf diese Programme und Dokumentationen ohne unsere schriftliche Einwilligung Dritten nicht zugänglich machen, auch nicht bei Weiterveräußerung unserer Hardware. Kopien dürfen – ohne Übernahme von Kosten und Haftung durch uns – lediglich für Archivzwecke, als Ersatz oder zur Fehlersuche angefertigt werden. Sofern Originale einen auf Urheberschutz hinweisenden Vermerk tragen, ist dieser vom Kunden auch auf Kopien anzubringen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Export</strong>
      <ol>
        <li>
          Der Export unserer Waren in Nicht–EU–Länder bedarf unserer schriftlichen Einwilligung, unabhängig davon, dass der Käufer für das Einholen jeglicher behördlicher Ein– und Ausfuhrgenehmigungen selbst zu sorgen hat.
        </li>
      </ol>
    </li>
  </ol>
  `,
  privacyProtection: `
  <p><b>Verantwortlicher</b> für die Datenverarbeitung ist</p>
<p><b>Target Distribution GmbH (FN 73428 h – LG Feldkirch)<br></b>z.H. Datenschutz<br>Straßenhäuser 59 | 6842
	Koblach<br>Telefon +43 5523 54871-0 | Telefax: +43 5523 54873</p>
<p>E-Mail: <a href="mailto:datenschutz@target-distribution.com"><span
				class="s2">datenschutz@target-distribution.com</a> | Web: <a
			href="http://www.target-distribution.com">www.target-distribution.com</a></p>
<p>Wir haben <b>keinen Datenschutzbeauftragten</b> bestellt, da dieser gesetzlich für uns nicht
	erforderlich ist.</p>
<p><b>I. Verarbeitung personenbezogener Daten<br></b>Wir verarbeiten Ihre personenbezogenen Daten, die unter folgende
	Datenkategorien fallen:</p>
<p>Kontaktdaten, Vertragsdaten, Verrechnungsdaten, Bonitätsdaten, Bestelldaten, Zahlungsverkehrsdaten
	(Bankdaten, Kreditkartendaten), Versand- und Lieferdaten</p>
<p><b>I.1 Unsere Rechtsgrundlagen sind</b></p>
<ul>
	<li>Geschäftspartner: Vertragserfüllung, rechtliche Verpflichtung, berechtigtes Interesse</li>
	<li>Kundendaten: Vertragserfüllung, rechtliche Verpflichtung, berechtigtes Interesse</li>
	<li>Interessenten: Vertragserfüllung, berechtigtes Interesse, Einwilligung</li>
	<li>Videoüberwachung: Vertragserfüllung, berechtigtes Interesse (Schutz)</li>
</ul>
<p><b>I.2 Einwilligung<br></b>Sie haben uns Daten über sich freiwillig zur Verfügung
	gestellt und wir verarbeiten diese Daten auf Grundlage Ihrer Einwilligung zu folgenden Zwecken:</p>
<p>Information über unsere Produkte, Werbung, Newsletter, Veranstaltungen, Firmenbeiträge auf Social Media
</p>
<p>Sie können diese Einwilligung jederzeit widerrufen. Ein Widerruf hat zur Folge, dass wir Ihre Daten ab
	diesem Zeitpunkt zu oben genannten Zwecken nicht mehr verarbeiten.</p>
<p><b>I.3 Vertrag<br></b>Die von Ihnen bereitgestellten Daten sind zur Vertragserfüllung bzw. Zur Durchführung
	vorvertraglicher Maßnahmen erforderlich. Das sind:</p>
<p>Die Erstellung von Offerten, die Abwicklung von Aufträgen und Bestellungen, die Zustellung der Waren,
	die Bearbeitung von Reklamationen, die Abwicklung von Gewährleistung oder Garantie, die Schadenabwicklung, die An-
	und Abmeldung von KFZ, die Hinterlegung von Kennzeichen, die Wahrung Ihrer rechtlichen Interessen gegenüber Dritten,
	die Abwicklung von Zahlungen mittels Bank oder Kreditkarten</p>
<p>Ohne diese Daten können den Vertrag mit Ihnen nicht erfüllen</p>
<p><b>I.4 Gesetzliche Verpflichtung<br></b>Wir müssen Ihre Daten, die wir von Ihnen erhalten haben, aufgrund einer
	gesetzlichen Verpflichtung verarbeiten.</p>
<p>Das sind steuer- und abgabenrechtliche Vorschriften, arbeits- und sozialrechtliche Vorschriften,
	Zollvorschriften (U34), etc., um die gesetzlich erforderlichen Nachweise zu erbringen.</p>
<p><b>I.5 Berechtige Interessen<br></b>Wir verarbeiten Daten über Sie aufgrund unserer berechtigten Interessen oder
	denen eines Dritten. Diese Interessen bestehen in:</p>
<p>Der Anbahnung von Geschäftsabschlüssen, der Dokumentation der Geschäftsfälle, der Information über von
	uns angebotene Produkte und Dienstleistungen, Veranstaltungen, Aktionen, Newsletter. Zu diesem Zweck können auch
	Daten an Dritte übermittelt werden, falls dies für die Durchführung der erwähnten oder anderer Marketingmaßnahmen,
	statistische Auswertungen etc. erforderlich ist bzw. für die interne Verwaltung in der Unternehmensgruppe</p>
<p><b>II. Speicherdauer / Löschfrist<br></b>Wir speichern Ihre Daten für die Dauer der Geschäftsbeziehung und darüber
	hinaus im Rahmen der jeweils zur Anwendung gelangenden gesetzlichen Aufbewahrungs- und Dokumentationspflichten.</p>
<p><b>III. Auftragsverarbeiter<br></b>Zur Erfüllung unserer Verpflichtungen arbeiten wir auch mit Auftragsverarbeitern
	zusammen. Mit diesen wurden Auftragsverarbeitungsverträge gemäß Art. 28 DSGVO abgeschlossen. Nähere Informationen zu
	den von uns beauftragten Auftragsverarbeitern können Sie unter der E-Mail-Adresse <a
		href="mailto:datenschutz@target-distribution.com">datenschutz@target-distribution.com</a>
	anfragen. Im Wesentlichen geben wir Ihre Daten an folgende Empfänger weiter:</p>
<p>Steuerberater und Wirtschaftsprüfer, Banken, Lieferanten und Hersteller, Unternehmensgruppe und
	Versicherungen</p>
<p><b>IV. Datenübermittlung<br></b>Wir geben Ihre Daten an folgende Empfänger bzw. Empfängerkategorien weiter</p>
<ul>
	<li>Auftragsverarbeiter wie Steuerberater und Wirtschaftsprüfer, Provider und Hoster,
		Logistikdienstleister, Beratungsunternehmen, Agenturen</li>
	<li>Sonstige Empfänger wie Bank-, Versicherungs- und Factoringinstitute, Auskunftsbüros,
		Geschäftspartner, Gesellschafter, Steuer- und Finanzbehörden, Hersteller und Lieferanten</li>
</ul>
<p>Unsere Daten werden üblicherweise nicht an Drittländer übermittelt.</p>
<p><b>V. Rechtsbehelfsbelehrung<br></b>Da wir die Daten in unseren berechtigten Interessen verarbeiten, haben Sie
	grundsätzlich ein Widerspruchsrecht, wenn bei Ihnen Gründe vorliegen, die sich aus Ihrer besonderen Situation
	ergeben, die gegen diese Verarbeitung sprechen.</p>
<p>Da wir die Daten (auch) für Direktwerbung verarbeiten, können Sie gegen diese Verarbeitung für Zwecke
	der Direktwerbung jederzeit Widerspruch erheben</p>
<p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung,
	Datenübertragbarkeit und Widerspruch zu. Dafür wenden Sie sich an unseren Verantwortlichen.</p>
<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
	datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der
	Aufsichtsbehörde beschweren. In Österreich ist die <b>Datenschutzbehörde</b> zuständig, die Sie wie folgt
	erreichen können</p>
<p><b>Österreichische Datenschutzbehörde<br></b>Barichgasse 40-42 | 1030 Wien<br>Telefon: +43 1 52 152<br><span
		>E-Mail: <a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a> | Web: <a
			href="http://www.dsb.gv.at/" target="_blank" rel="noopener"><span
				class="s2">http://www.dsb.gv.at/</a></p>
<p><b>Kontakt<br></b>Sie erreichen uns unter folgenden Kontaktdaten<br><b>Target Distribution GmbH (FN 73428 h – LG
		Feldkirch)<br></b>z.H. Datenschutz<br>Straßenhäuser 59 | 6842 Koblach<br>Telefon +43 5523 54871-0 | Telefax: +43
	5523 54873<br>E-Mail: <a href="%22mailto:datenschutz@target-distributi"><span
				class="s2">datenschutz@target-distribution.com</a> | Web: <a
			href="http://www.target-distribution.com">www.target-distribution.com</a> </p>
  `,
  faqQuestions: [
    {
      question: "Can all licenses be transferred directly or only as soon as they are due?",
      answer: `A migration can be carried out at any time. <br />
      If the customer is already in the renewal window and has not yet or only partially renewed the licenses, migration is not possible. In this case, the full extension must first be carried out in the traditional way. <br />
      If there are still open purchase authorizations for the customer, these must also first be ordered in the traditional way. <br />
      No transfer can be made within 14 days of an order (whether it is an extension or an add-on).`,
    },
    {
      question:
        "Can I transfer customers with a 3YC or Enterprise license, or Government or Education customers, to the Adobe Cloud Store?",
      answer: `No, in this case the Adobe Cloud Store issues an error that the transfer of the specified VIP account is not possible. <br />
      These license models and segments will be implemented by Adobe in the coming months.`,
    },
    {
      question: "Does the customer see the Customer ID in his admin console?",
      answer:
        "No, the customer keeps his known VIP number in the event of a transfer. A new VIP number is generated for new customers.",
    },
    {
      question: "Is it possible to book licenses on a monthly or daily basis?",
      answer:
        "No, this function is not yet available. Adobe will however introduce the possibility of monthly booking in the medium term.",
    },
    {
      question: "Does the customer's deadline persist after I transfer them to the Adobe Cloud Store?",
      answer: "Yes, the „Anniversary Date“ is adopted and remains.",
    },
    {
      question: "Has auto-renewal already been activated for transferred customers?",
      answer:
        "Yes, auto-renewal is activated by default for new customers and transferred customers and can be deactivated if desired.",
    },
    {
      question: "Does the customer disappear from the reseller console after the transfer?",
      answer: "Yes, after the transfer the customer is no longer available in the reseller console.",
    },
    {
      question: "How can I determine the prices for new customers who have not yet been created in the Cloud Store?",
      answer:
        "It is currently possible to create offers for existing customers via the shopping cart. A general offer function for the cloud store is planned.",
    },
    {
      question: "How can customers be taken over by other resellers? („Change of Reseller“)",
      answer:
        `If you would like to take over an existing VIP Marketplace customer, please send an email with the customer's VIP number or customer ID to acs@target-distribution.com and we will take care of the switch.<br />
        If the customer is not yet in the VIP Marketplace, you can transfer them directly to the Adobe Cloud Store after their approval. A previous, "classic" change of reseller in the reseller console is not necessary.`
    },
    {
      question: "Can orders be canceled after they have been placed in the cloud store?",
      answer: "Yes, orders can be canceled directly in the cloud store in the order history within 14 days.",
    },
    {
      question: "Can extensions be booked in advance or later?",
      answer:
        `No, the renewal will be done automatically on the Anniversary Date. If the auto-renewal was not activated, you can initiate a subsequent order using the "Forgot renewal?" function in the customer overview. This is not a classic renewal, but technically a new booking of the licenses, which then have to be reassigned in the admin console.<br />
        If possible, make sure that the auto-renewal is active on the day before the anniversary date.`,
    },
    {
      question:
        "Will there be reminder emails in good time for customers in the cloud store who are waiting for renewal so that they can prepare offers and invoices in advance?",
      answer:
        "Yes, as a reseller you will receive a total of four reminder emails before each renewal (45, 30, 15 and one day before the anniversary date) to inform you punctually about upcoming renewals.",
    },
    {
      question:
        "Will the previous process for the VIP licensing program continue or will something change in the course of the new platform?",
      answer: "There are no changes for the „classic VIP“, here everything stays the same for the time being.",
    },
    {
      question: "What happens if my customer adds a new license via their admin console? Will I be notified?",
      Answer:
        "Yes, if a customer creates a purchase authorization via the admin console, you will receive a notification by e-mail, just like in the classic VIP. This license must then be ordered via the Adobe Cloud Store within seven (7) days.",
    },
    {
      question:
        "Can I create an offer for my customer?",
      answer:
        "Yes, you have the option of creating an offer via \"Create order\" before completing an order. The shopping cart is saved when you exit the window so that you can access it again at a later time and order the license(s). In addition, you have the option of creating an \"offer for renewal\" via the detailed view of a \"customer\".",
    },
  ],
  contactPersons: [
    {
      name: "Michelle Furxer",
      title: "Order Management Adobe",
      phoneNumber: "+43 5523 54871 117",
      email: "michelle.furxer@target-distribution.com",
      image: "michelle-furxer.jpg",
    },
    {
      name: "Susanne Hörburger",
      title: "Product Manager Adobe",
      phoneNumber: "+43 5523 54871 126",
      email: "susanne.hoerburger@target-distribution.com",
      image: "susanne-hoerburger.jpg",
    },
  ]
};
