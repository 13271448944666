<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">
          {{ $t("notifications") }}
        </h3>
      </b-container>
    </div>
    <b-container class="pt-4">
      <div class="mb-4 text-muted" v-html="$t('notificationsText')"></div>

      <b-form v-on:submit.prevent="updateResellerDoc">
        <b-card footer-class="border-0 bg-light">
          <b-row class="pb-4">

            <b-col class="d-flex flex-column justify-content-center">
              <label class="mb-0">{{ $t("emailLabel") }}</label>
            </b-col>
            <b-col>
              <b-form-input v-model="email" v-if="isLoading === false"></b-form-input>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>

          </b-row>

          <b-row class="pb-5">
            <b-col class="d-flex flex-column justify-content-center">
              <label class="mb-0">{{ $t("furtherMailAddresses") }}</label>
            </b-col>
            <b-col>
              <b-form-tags v-model="furtherMailAddresses" :placeholder="$t('furtherMailAddressesPlaceholder')" :tag-validator="tagValidator" :invalidTagText="$t('enterValidEMail')" v-if="isLoading === false"></b-form-tags>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>
          </b-row>

          <h5>{{$t("notofocationSettings")}}</h5>

          <hr />

          <b-row>
            <b-col class="d-flex flex-column ">
              <label>{{ $t("threeYCNotification") }}</label>
              <small class="text-muted">{{$t("threeYCNotificationAditionalInformation")}}</small>
            </b-col>
            <b-col class="">
              <div class="pb-2"><small v-html="$t('multiselectText')"></small></div>
              <b-form-select v-model="threeYC.selected" :options="threeYC.options" multiple :select-size="4" v-if="isLoading === false"></b-form-select>
              <b-skeleton type="select" v-else></b-skeleton>
              <small class="text-muted">{{$t("selectedDays")}}:
                <span v-for="(x, index ) in threeYC.selected" :key="index" >{{x}}<span v-show="index !== threeYC.selected.length-1">, </span></span>
              </small>
              <div class="mt-2" v-if="isLoading === false && threeYC.selected.length > 0">
                <small class="text-muted">{{$t("furtherMailAddresses")}}</small>
                <b-form-tags v-model="furtherMailAddressesthreeYC" :placeholder="$t('furtherMailAddressesPlaceholder')" :tag-validator="tagValidator" :invalidTagText="$t('enterValidEMail')" ></b-form-tags>
              </div>
            </b-col>
          </b-row>

          <hr>

          <b-row class="mt-3">
            <b-col class="d-flex flex-column ">
              <label>{{ $t("customerStatusChangeNotification") }}</label>
              <small class="text-muted">{{$t("customerStatusChangeNotificationInformation")}}</small>
            </b-col>
            <b-col  class="d-flex align-items-center"> 
              <div style="width: 100%">
                <b-form-checkbox switch v-model="states.customerStatusChange" v-b-tooltip.hover.top="states.customerStatusChange ? $t('notificationStatusOn') : $t('notificationStatusOff')">
                </b-form-checkbox>
                <span v-if="isLoading === false && states.customerStatusChange">
                  <small class="text-muted">{{$t("furtherMailAddresses")}}</small>
                  <b-form-tags v-model="furtherMailAddressesCustomerState" :placeholder="$t('furtherMailAddressesPlaceholder')" :tag-validator="tagValidator" :invalidTagText="$t('enterValidEMail')"></b-form-tags>
                </span>
              </div>
            </b-col>
          </b-row>
  
          <hr>

          <b-row class="mt-3">
            <b-col class="d-flex flex-column ">
              <label>{{ $t("orderSuccessNotification") }}</label>
              <small class="text-muted">{{$t("orderSuccessNotificationInformation")}}</small>
            </b-col>
            <b-col  class="d-flex align-items-center"> 
              <div style="width: 100%">
                <b-form-checkbox switch v-model="states.orderSuccessNotification" v-b-tooltip.hover.top="states.orderSuccessNotification ? $t('notificationStatusOn') : $t('notificationStatusOff')">
                </b-form-checkbox>
                <div v-if="isLoading === false && states.orderSuccessNotification">
                  <small class="text-muted">{{$t("furtherMailAddresses")}}</small>
                  <b-form-tags v-model="furtherMailAddressesOrderSuccessNotification" :placeholder="$t('furtherMailAddressesPlaceholder')" :tag-validator="tagValidator" :invalidTagText="$t('enterValidEMail')" ></b-form-tags>
                </div>
              </div>
            </b-col>
          </b-row>
        
          <hr>

          <b-row class="mt-3">
            <b-col class="d-flex flex-column ">
              <label>{{ $t("renewalSuccessNotification") }}</label>
              <small class="text-muted">{{$t("renewalSuccessNotificationInformation")}}</small>
            </b-col>
            <b-col  class="d-flex align-items-center"> 
              <div style="width: 100%">
                <b-form-checkbox switch v-model="states.renewalSuccessNotification" v-b-tooltip.hover.top="states.renewalSuccessNotification ? $t('notificationStatusOn') : $t('notificationStatusOff')">
                </b-form-checkbox>
                <div v-if="isLoading === false && states.renewalSuccessNotification">
                  <small class="text-muted">{{$t("furtherMailAddresses")}}</small>
                  <b-form-tags v-model="furtherMailAddressesRenewalSuccessNotification" :placeholder="$t('furtherMailAddressesPlaceholder')" :tag-validator="tagValidator" :invalidTagText="$t('enterValidEMail')" ></b-form-tags>
                </div>
              </div>
            </b-col>
          </b-row>

          <hr>

          <b-row class="mt-3">
            <b-col class="d-flex flex-column ">
              <label>{{ $t("renewalReminderNotification") }}</label>
              <small class="text-muted">{{$t("renewalReminderNotificationInformation")}}</small>
            </b-col>
            <b-col class=""> 
              <div class="pb-2"><small v-html="$t('multiselectText')"></small></div>
              <b-form-select v-model="renewalReminder.selected" :options="renewalReminder.options" multiple :select-size="4" v-if="isLoading === false"></b-form-select>
              <b-skeleton type="select" v-else></b-skeleton>
              <small class="text-muted">{{$t("selectedDays")}}:
                <span v-for="(x, index ) in renewalReminder.selected" :key="index" >{{x}}<span v-show="index !== renewalReminder.selected.length-1">, </span></span>
              </small>
              <div class="mt-2" v-if="isLoading === false && renewalReminder.selected.length > 0">
                <small class="text-muted">{{$t("furtherMailAddresses")}}</small>
                <b-form-tags v-model="furtherMailAddressesRenewalReminderNotification" :placeholder="$t('furtherMailAddressesPlaceholder')" :tag-validator="tagValidator" :invalidTagText="$t('enterValidEMail')" ></b-form-tags>
              </div>
            </b-col>
          </b-row>

          <hr>

          <b-row class="mt-3">
            <b-col class="d-flex flex-column ">
              <label>{{ $t("deactivatedRenewalReminderNotification") }}</label>
              <small class="text-muted">{{$t("deactivatedRenewalReminderNotificationInformation")}}</small>
            </b-col>
            <b-col class=""> 
              <div class="pb-2"><small v-html="$t('multiselectText')"></small></div>
              <b-form-select v-model="deactivatedRenewalReminder.selected" :options="deactivatedRenewalReminder.options" multiple :select-size="4" v-if="isLoading === false"></b-form-select>
              <b-skeleton type="select" v-else></b-skeleton>
              <small class="text-muted">{{$t("selectedDays")}}:
                <span v-for="(x, index ) in deactivatedRenewalReminder.selected" :key="index" >{{x}}<span v-show="index !== deactivatedRenewalReminder.selected.length-1">, </span></span>
              </small>
              <div class="mt-2" v-if="isLoading === false && deactivatedRenewalReminder.selected.length > 0">
                <small class="text-muted">{{$t("furtherMailAddresses")}}</small>
                <b-form-tags v-model="furtherMailAddressesDeactivatedRenewalReminderNotification" :placeholder="$t('furtherMailAddressesPlaceholder')" :tag-validator="tagValidator" :invalidTagText="$t('enterValidEMail')"></b-form-tags>
              </div>
            </b-col>
          </b-row>

          <template #footer>
            <div class="d-flex justify-content-end">
              <submit-button-component
                :state="submitButtonState"
                :label="$t('save')"
                :block="false"
                class="my-2"
              ></submit-button-component>
            </div>
          </template>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { firestore } from "../plugins/firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import isEmail from "validator/es/lib/isEmail";

export default {
  name: "ResellerNotificationsView",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      isWorking: false,
      isLoading: true,
      missingFields: true,
      email: "",
      furtherMailAddresses: [],
      furtherMailAddressesCustomerState: [],
      furtherMailAddressesOrderSuccessNotification: [],
      furtherMailAddressesRenewalSuccessNotification: [],
      furtherMailAddressesRenewalReminderNotification: [],
      furtherMailAddressesDeactivatedRenewalReminderNotification: [],
      states: {
        customerStatusChange: true,
        orderSuccessNotification: true,
        renewalSuccessNotification: true,
        renewalReminderNotification: true,
        deactivatedRenewalReminderNotification: true
      },
      threeYC: {
        selected: [30, 7, 1], // Array reference
        options: [
          { value: 30, text: '30' },
          { value: 29, text: '29' },
          { value: 28, text: '28' },
          { value: 27, text: '27' },
          { value: 26, text: '26' },
          { value: 25, text: '25' },
          { value: 24, text: '24' },
          { value: 23, text: '23' },
          { value: 22, text: '22' },
          { value: 21, text: '21' },
          { value: 20, text: '20' },
          { value: 19, text: '19' },
          { value: 18, text: '18' },
          { value: 17, text: '17' },
          { value: 16, text: '16' },
          { value: 15, text: '15' },
          { value: 14, text: '14' },
          { value: 13, text: '13' },
          { value: 12, text: '12' },
          { value: 11, text: '11' },
          { value: 10, text: '10' },
          { value: 9, text: '9' },
          { value: 8, text: '8' },
          { value: 7, text: '7' },
          { value: 6, text: '6' },
          { value: 5, text: '5' },
          { value: 4, text: '4' },
          { value: 3, text: '3' },
          { value: 2, text: '2' },
          { value: 1, text: '1' },
        ]
      },
      renewalReminder: {
        selected: [45, 30, 7, 1], // Array reference
        options: [
          { value: 45, text: '45' },
          { value: 44, text: '44' },
          { value: 43, text: '43' },
          { value: 42, text: '42' },
          { value: 41, text: '41' },
          { value: 40, text: '40' },
          { value: 39, text: '39' },
          { value: 38, text: '38' },
          { value: 37, text: '37' },
          { value: 36, text: '36' },
          { value: 35, text: '35' },
          { value: 34, text: '34' },
          { value: 33, text: '33' },
          { value: 32, text: '32' },
          { value: 31, text: '31' },
          { value: 30, text: '30' },
          { value: 29, text: '29' },
          { value: 28, text: '28' },
          { value: 27, text: '27' },
          { value: 26, text: '26' },
          { value: 25, text: '25' },
          { value: 24, text: '24' },
          { value: 23, text: '23' },
          { value: 22, text: '22' },
          { value: 21, text: '21' },
          { value: 20, text: '20' },
          { value: 19, text: '19' },
          { value: 18, text: '18' },
          { value: 17, text: '17' },
          { value: 16, text: '16' },
          { value: 15, text: '15' },
          { value: 14, text: '14' },
          { value: 13, text: '13' },
          { value: 12, text: '12' },
          { value: 11, text: '11' },
          { value: 10, text: '10' },
          { value: 9, text: '9' },
          { value: 8, text: '8' },
          { value: 7, text: '7' },
          { value: 6, text: '6' },
          { value: 5, text: '5' },
          { value: 4, text: '4' },
          { value: 3, text: '3' },
          { value: 2, text: '2' },
          { value: 1, text: '1' },
        ]
      },
      deactivatedRenewalReminder: {
        selected: [45, 30, 7, 1], // Array reference
        options: [
          { value: 45, text: '45' },
          { value: 44, text: '44' },
          { value: 43, text: '43' },
          { value: 42, text: '42' },
          { value: 41, text: '41' },
          { value: 40, text: '40' },
          { value: 39, text: '39' },
          { value: 38, text: '38' },
          { value: 37, text: '37' },
          { value: 36, text: '36' },
          { value: 35, text: '35' },
          { value: 34, text: '34' },
          { value: 33, text: '33' },
          { value: 32, text: '32' },
          { value: 31, text: '31' },
          { value: 30, text: '30' },
          { value: 29, text: '29' },
          { value: 28, text: '28' },
          { value: 27, text: '27' },
          { value: 26, text: '26' },
          { value: 25, text: '25' },
          { value: 24, text: '24' },
          { value: 23, text: '23' },
          { value: 22, text: '22' },
          { value: 21, text: '21' },
          { value: 20, text: '20' },
          { value: 19, text: '19' },
          { value: 18, text: '18' },
          { value: 17, text: '17' },
          { value: 16, text: '16' },
          { value: 15, text: '15' },
          { value: 14, text: '14' },
          { value: 13, text: '13' },
          { value: 12, text: '12' },
          { value: 11, text: '11' },
          { value: 10, text: '10' },
          { value: 9, text: '9' },
          { value: 8, text: '8' },
          { value: 7, text: '7' },
          { value: 6, text: '6' },
          { value: 5, text: '5' },
          { value: 4, text: '4' },
          { value: 3, text: '3' },
          { value: 2, text: '2' },
          { value: 1, text: '1' },
        ]
      }
    };
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      if (this.email === "") {
        return "missingFields";
      }

      return "";
    },
  },
  created() {
    this.getResellerDoc();
  },
  methods: {
    tagValidator(tag) {
      return isEmail(tag)
    },
    async getResellerDoc() {
      const docRef = doc(firestore, "resellers", this.$store.state.resellerData.customernumber);
      const ccDocRef = doc(firestore, `resellers/${this.$store.state.resellerData.customernumber}/notifications/cc`);
      const daysBeforeDocRef = doc(firestore, `resellers/${this.$store.state.resellerData.customernumber}/notifications/daysBefore`);
      const docSnapshot = await getDoc(docRef);
      const ccSnapshot = await getDoc(ccDocRef);
      const daysBeforeSnapshot = await getDoc(daysBeforeDocRef);
      const statesDocRef = doc(firestore, `resellers/${this.$store.state.resellerData.customernumber}/notifications/states`);
      const statesSnapshot = await getDoc(statesDocRef);

      if (ccSnapshot.exists()) {
        this.furtherMailAddresses = ccSnapshot.data()?.allNotifications || [];
        this.furtherMailAddressesCustomerState = ccSnapshot.data()?.customerState || [];
        this.furtherMailAddressesOrderSuccessNotification = ccSnapshot.data()?.orderSuccessNotification || [];
        this.furtherMailAddressesRenewalSuccessNotification = ccSnapshot.data()?.renewalSuccessNotification || [];
        this.furtherMailAddressesRenewalReminderNotification = ccSnapshot.data()?.renewalReminderNotification || [];
        this.furtherMailAddressesDeactivatedRenewalReminderNotification = ccSnapshot.data()?.deactivatedRenewalReminderNotification || [];
        this.furtherMailAddressesthreeYC = ccSnapshot.data()?.furtherMailAddressesthreeYC || [];
      } 

      if (daysBeforeSnapshot.exists()) {
        this.threeYC.selected = daysBeforeSnapshot.data()?.threeYearsCommit || [];
        this.renewalReminder.selected = daysBeforeSnapshot.data()?.renewalReminder || [];
        this.deactivatedRenewalReminder.selected = daysBeforeSnapshot.data()?.deactivatedRenewalReminder || [];
      }

      if (statesSnapshot.exists()) {
        this.states = statesSnapshot.data();
      } 
      
      if (docSnapshot.exists()) {
        if (!docSnapshot.data().notificationEmail || docSnapshot.data().notificationEmail === "") {
          this.email = docSnapshot.data().companyProfile.contacts[0].email;
        } else {
          this.email = docSnapshot.data().notificationEmail;
        }
        
        this.isLoading = false;
      } else {
        this.$store.dispatch("alertError", {
          message: "Die Daten konnten nicht abgerufen werden.",
          show: true,
          noFooter: false,
        });
      }
    },
    async updateResellerDoc() {
      this.isWorking = true;
      const emailAddress = this.email

      const furtherMailAddressesWithoutResellerEMail = this.furtherMailAddresses.filter(function (email) {
        return email != emailAddress;
      });

      const furtherMailAddressesCustomerStateWithoutResellerEMail = this.furtherMailAddressesCustomerState.filter(function (email) {
        return email != emailAddress && !furtherMailAddressesWithoutResellerEMail.includes(email);
      });

      const furtherMailAddressesOrderSuccessNotificationWithoutResellerEMail = this.furtherMailAddressesOrderSuccessNotification.filter(function (email) {
        return email != emailAddress && !furtherMailAddressesWithoutResellerEMail.includes(email);
      });

      const furtherMailAddressesRenewalSuccessNotificationWithoutResellerEMail = this.furtherMailAddressesRenewalSuccessNotification.filter(function (email) {
        return email != emailAddress && !furtherMailAddressesWithoutResellerEMail.includes(email);
      });

      const furtherMailAddressesRenewalReminderNotificationWithoutResellerEMail = this.furtherMailAddressesRenewalReminderNotification.filter(function (email) {
        return email != emailAddress && !furtherMailAddressesWithoutResellerEMail.includes(email);
      });

      const furtherMailAddressesDeactivatedRenewalReminderNotificationWithoutResellerEMail = this.furtherMailAddressesDeactivatedRenewalReminderNotification.filter(function (email) {
        return email != emailAddress && !furtherMailAddressesWithoutResellerEMail.includes(email);
      });

      const furtherMailAddressesthreeYC = (this.furtherMailAddressesthreeYC || []).filter(function (email) {
        return email != emailAddress && !furtherMailAddressesWithoutResellerEMail.includes(email);
      });

      try {
        const docRef = doc(firestore, "resellers", this.$store.state.resellerData.customernumber);
        await updateDoc(docRef, {notificationEmail: this.email});

        const ccDocRef = doc(firestore, `resellers/${this.$store.state.resellerData.customernumber}/notifications/cc`);
        await setDoc(ccDocRef, {
          allNotifications: furtherMailAddressesWithoutResellerEMail, 
          customerState: furtherMailAddressesCustomerStateWithoutResellerEMail,
          orderSuccessNotification: furtherMailAddressesOrderSuccessNotificationWithoutResellerEMail,
          renewalSuccessNotification: furtherMailAddressesRenewalSuccessNotificationWithoutResellerEMail,
          renewalReminderNotification: furtherMailAddressesRenewalReminderNotificationWithoutResellerEMail,
          deactivatedRenewalReminderNotification: furtherMailAddressesDeactivatedRenewalReminderNotificationWithoutResellerEMail,
          furtherMailAddressesthreeYC: furtherMailAddressesthreeYC
        });

        const statesRef = doc(firestore, `resellers/${this.$store.state.resellerData.customernumber}/notifications/states`);
        await setDoc(statesRef, this.states);

        const daysBeforeDocRef = doc(firestore, `resellers/${this.$store.state.resellerData.customernumber}/notifications/daysBefore`);
        await setDoc(daysBeforeDocRef, {
          threeYearsCommit: this.threeYC.selected,
          renewalReminder: this.renewalReminder.selected,
          deactivatedRenewalReminder: this.deactivatedRenewalReminder.selected,
          });

      } catch(error) {
        this.$store.dispatch("alertError", {
          message: error,
          show: true,
          noFooter: false,
        });
      }

      this.isWorking = false;
    },
  },
};
</script>
